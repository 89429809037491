import React from 'react';
import useApiRequest from 'hooks/useApiRequest';
import { AsyncPaginate } from 'react-select-async-paginate';
import PropTypes from 'prop-types';
import { selectStyles } from 'constants/selectStyles';

const SalesRepPaginatedField = ({
  onChange,
  isMulti,
  defaultValue,
  isInitialLabel,
  key,
  error,
  ...props
}) => {
  const apiRequest = useApiRequest();

  const fetchSalesRep = ({ page = 1 }) => {
    return apiRequest({
      url: `/users/?page=${page}&role_is=sales_rep`,
    });
  };

  const loadSalesRep = async (search, loadedOptions, { page }) => {
    try {
      const response = await fetchSalesRep({ page });
      const options = response?.results.map(item => ({
        value: item.id,
        label: `${item.first_name} ${item.last_name}`,
      }));
      return {
        options:
          isInitialLabel && page === 1
            ? [{ value: undefined, label: 'All sales reps' }, ...options]
            : options,
        hasMore: response?.pagination?.next_page,
        additional: { page: page + 1 },
      };
    } catch (err) {
      return {
        options: [],
        hasMore: true,
        additional: { page },
      };
    }
  };

  return (
    <>
      <AsyncPaginate
        {...props}
        name="sales_rep"
        additional={{
          page: 1,
        }}
        defaultValue={defaultValue}
        placeholder="Select sales rep"
        loadOptions={loadSalesRep}
        onChange={onChange}
        isMulti={isMulti}
        styles={selectStyles}
      />
      {!!error && <small className="text-danger">{error}</small>}
    </>
  );
};

SalesRepPaginatedField.propTypes = {
  onChange: PropTypes.func,
  isInitialLabel: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isMulti: PropTypes.bool,
  key: PropTypes.string,
  withShortOptions: PropTypes.bool,
  error: PropTypes.string,
};

SalesRepPaginatedField.defaultProps = {
  defaultValue: [],
  isMulti: false,
};

export default SalesRepPaginatedField;
