import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Row, Col, FormGroup, Label, Button } from 'reactstrap';
import { COMPANY_TYPES, HUMAN_LEADS_STATUSES } from 'constants';
import LocationSearchInput from 'components/LocationSearchInput';
import styles from './Main.scss';
import TextField from 'components/Fields/TextField';
import EmailField from 'components/Fields/EmailField';
import PhoneField from 'components/Fields/PhoneField';
import WarehousesPaginatedField from 'components/PaginatedFields/WarehousesPaginatedField';
import DateField from 'components/Fields/DateField';
import SalesRepPaginatedField from 'components/PaginatedFields/SalesRepPaginatedField';

const getAddressFieldError = (key, value) =>
  value ? `${key}: ${value[0]}` : undefined;

const Main = ({
  model,
  disabled,
  currentType,
  currentLeadStatus,
  setCurrentType,
  setCurrentLeadStatus,
  defaultWarehouse,
  handleDefaultWarehouseChange,
  country,
  state,
  postalCode,
  city,
  line1,
  line2,
  setCountry,
  setState,
  setPostalCode,
  setCity,
  setLine1,
  setLine2,
  submitTitle,
  handleConfirm,
  fieldErrors,
  isSalesRep,
  defaultWarehouseDisabled,
  isLead,
  followUpDate,
  setFollowUpDate,
  salesRep,
  setSalesRep,
  setLicenseNumber,
  isEditing,
  contactFirstName,
  contactLastName,
  contactEmail,
  contactPhone,
  setContactFirstName,
  setContactLastName,
  setContactEmail,
  setContactPhone,
}) => {
  const hasAddressFieldError =
    getAddressFieldError('Country', fieldErrors?.country) ||
    getAddressFieldError('City', fieldErrors?.city) ||
    getAddressFieldError('State', fieldErrors?.state) ||
    getAddressFieldError('Line 1', fieldErrors?.line1) ||
    getAddressFieldError('Line 2', fieldErrors?.line2) ||
    getAddressFieldError('Postal code', fieldErrors?.postalCode);

  // eslint-disable-next-line no-nested-ternary
  const isButtonDisabled = isEditing
    ? true
    : isLead
    ? currentLeadStatus && currentType
    : defaultWarehouse && currentType;

  const getAvailableStatuses = () => {
    if (!isEditing) {
      const { CONNECTED, ...statuses } = HUMAN_LEADS_STATUSES;
      return statuses;
    }
    return HUMAN_LEADS_STATUSES;
  };

  return (
    <>
      <Row>
        <Col md="4">
          <TextField
            label="Business Name"
            name="title"
            placeholder="Business Name"
            isRequired
            disabled={disabled}
            errorMessage="Enter Business Name"
          />
        </Col>
        {isLead && (
          <Col md={4}>
            <FormGroup>
              <Label required htmlFor="lead_status">
                Lead Status
              </Label>
              <Select
                name="lead_status"
                options={Object.entries(getAvailableStatuses()).map(
                  ([key, label]) => ({
                    label,
                    value: key,
                  })
                )}
                isDisabled={disabled}
                placeholder="Select status"
                onChange={setCurrentLeadStatus}
                value={model || currentLeadStatus ? currentLeadStatus : ''}
              />
            </FormGroup>
          </Col>
        )}
        <Col md="4">
          <TextField
            placeholder="License Number"
            isRequired={!isLead}
            label="License Number"
            name="license_number"
            onChange={event => setLicenseNumber(event.target.value)}
          />
        </Col>
        {isLead && (
          <Col md="4">
            <Label htmlFor="sales_rep">Sales rep</Label>
            <SalesRepPaginatedField
              onChange={({ value }) =>
                setSalesRep(prevState => ({
                  ...prevState,
                  value,
                }))
              }
              defaultValue={salesRep}
            />
          </Col>
        )}
        <Col md="4">
          <FormGroup>
            <Label required htmlFor="company_type">
              Company Type
            </Label>
            <Select
              name="company_type"
              options={COMPANY_TYPES.map(type => ({
                label: type,
                value: type,
              }))}
              placeholder="Select Type"
              onChange={setCurrentType}
              value={model || currentType ? currentType : ''}
              isRequired={!isLead}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label required={!isLead} htmlFor="default_warehouse">
              Default Warehouse
            </Label>
            <WarehousesPaginatedField
              value={defaultWarehouse}
              onChange={handleDefaultWarehouseChange}
              name="default_warehouse"
              placeholder="Select Warehouse"
              isMulti={false}
              isDisabled={!isLead && defaultWarehouseDisabled}
            />
          </FormGroup>
        </Col>
        {isLead && (
          <Col md="4">
            <DateField
              name="followUpDate"
              label="Follow-up Date"
              errorMessage="Enter Follow up Date"
              value={followUpDate}
              onChange={event => setFollowUpDate(event.target.value)}
            />
          </Col>
        )}
        <Col md="8">
          <LocationSearchInput
            model={model?.address || {}}
            country={country}
            state={state}
            postalCode={postalCode}
            city={city}
            line1={line1}
            line2={line2}
            setCountry={setCountry}
            setState={setState}
            setPostalCode={setPostalCode}
            setCity={setCity}
            setLine1={setLine1}
            setLine2={setLine2}
            error={hasAddressFieldError}
            isRequired
          />
        </Col>
      </Row>
      <Row className={styles.contactPerson}>
        <Col md={12}>
          <div className="font-size-18 mb-2 font-weight-bold">
            Contact Person
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <TextField
            name="contact_person_first_name"
            label="First Name"
            placeholder="First Name"
            errorMessage="Enter First Name"
            value={contactFirstName}
            onChange={({ target }) => setContactFirstName(target.value)}
          />
        </Col>
        <Col md="3">
          <TextField
            errorMessage="Enter Last Name"
            label="Last name"
            name="contact_person_last_name"
            placeholder="Last Name"
            value={contactLastName}
            onChange={({ target }) => setContactLastName(target.value)}
          />
        </Col>
        <Col md="3">
          <EmailField
            name="contact_person_email"
            label="Email"
            placeholder="Email"
            errorMessage="Enter Email"
            isRequired={isSalesRep}
            value={contactEmail}
            onChange={event =>
              setContactEmail(event.target.value.toLowerCase())
            }
          />
        </Col>
        <Col md="3">
          <PhoneField
            name="contact_person_phone"
            placeholder="Phone"
            errorMessage="Enter Phone"
            label="Phone"
            value={contactPhone}
            onChange={setContactPhone}
          />
        </Col>
      </Row>
      <Button
        disabled={!isButtonDisabled}
        color="primary"
        type="submit"
        onClick={handleConfirm}
      >
        {submitTitle}
      </Button>
    </>
  );
};

Main.propTypes = {
  model: PropTypes.object,
  disabled: PropTypes.bool,
  currentType: PropTypes.string,
  currentLeadStatus: PropTypes.string,
  setCurrentType: PropTypes.func,
  setCurrentLeadStatus: PropTypes.func,
  defaultWarehouse: PropTypes.object,
  handleDefaultWarehouseChange: PropTypes.func,
  defaultWarehouseDisabled: PropTypes.bool,
  state: PropTypes.string,
  country: PropTypes.string,
  postalCode: PropTypes.string,
  city: PropTypes.string,
  line1: PropTypes.string,
  line2: PropTypes.string,
  setCountry: PropTypes.func,
  setState: PropTypes.func,
  setPostalCode: PropTypes.func,
  setCity: PropTypes.func,
  setLine1: PropTypes.func,
  setLine2: PropTypes.func,
  submitTitle: PropTypes.string,
  handleConfirm: PropTypes.func,
  fieldErrors: PropTypes.object,
  isSalesRep: PropTypes.bool,
  isLead: PropTypes.bool,
  followUpDate: PropTypes.string,
  setFollowUpDate: PropTypes.func,
  salesRep: PropTypes.string,
  setSalesRep: PropTypes.func,
  setLicenseNumber: PropTypes.func,
  isEditing: PropTypes.bool,
  contactFirstName: PropTypes.string,
  contactLastName: PropTypes.string,
  contactEmail: PropTypes.string,
  contactPhone: PropTypes.string,
  setContactFirstName: PropTypes.func,
  setContactLastName: PropTypes.func,
  setContactEmail: PropTypes.func,
  setContactPhone: PropTypes.func,
};

export default Main;
