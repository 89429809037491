import { takeLatest, all, call, put } from 'redux-saga/effects';
import * as queryString from 'query-string';
import { saveAs } from 'file-saver';
import { showErrorMessage } from 'utils/notification';
import api from 'api';
import { actions } from './slice';
import { push } from 'connected-react-router';
import { generateURL } from '../../utils/generateURL';
import { formatDate } from 'utils/formatDate';

const downloadURI = uri =>
  new Promise(resolve => {
    if (!uri) {
      return;
    }
    setTimeout(() => {
      const link = document.createElement('a');
      const filename = uri.split('/').pop();
      link.download = filename;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      resolve();
    }, 1000);
  });

const constructSalesUrl = (path, parameters) => {
  const stringified = queryString.stringify(
    {
      report_sections: [
        'orders_summary',
        'sales_by_brand',
        'sales_by_department',
        'sales_by_sku',
        'sales_overview',
        'sales_by_order_type',
        'sales_by_payment_type',
        'tips_summary',
      ],
      ...parameters,
    },
    { arrayFormat: 'comma' }
  );
  return `${path}/?${stringified}`;
};

const constructUrl = (path, parameters) => {
  const stringified = queryString.stringify(parameters, {
    arrayFormat: 'comma',
  });
  return `${path}/?${stringified}`;
};

function* fetchSalesXlsxSaga(action) {
  const { payload } = action;
  yield api({
    action,
    method: 'get',
    headers: {
      Accept: 'application/xlsx',
    },
    responseType: 'blob',
    url: constructSalesUrl('/reports/sales', payload),
  });
}

function* fetchWineSalesSaga(action) {
  const { payload } = action;
  yield api({
    action,
    method: 'get',
    url: constructUrl('/reports/taster_tracking', {
      page: payload.page,
      date_range_start: payload.startDate,
      date_range_end: payload.endDate,
      warehouse_ids: payload.warehouseIds,
      order_types: payload.orderTypes,
    }),
  });
}

function* fetchWineSalesXlsxSaga(action) {
  const { payload } = action;
  yield api({
    action,
    method: 'get',
    headers: {
      Accept: 'application/xlsx',
    },
    responseType: 'blob',
    url: constructUrl('/reports/taster_tracking', {
      date_range_start: payload.startDate,
      date_range_end: payload.endDate,
      warehouse_ids: payload.warehouseIds,
      order_types: payload.orderTypes,
    }),
  });
}

function* fetchInventoryTransfersXlsxSaga(action) {
  const { payload } = action;
  if (!payload.warehouseId) {
    showErrorMessage('Error!', 'Please select a warehouse');
    return;
  }
  yield api({
    action,
    method: 'get',
    headers: {
      Accept: 'application/xlsx',
    },
    responseType: 'blob',
    url: constructUrl('/reports/inventory_transfer', {
      date_range_start: payload.startDate,
      date_range_end: payload.endDate,
      warehouse_id: payload.warehouseId,
      brand_id: payload.brandId,
      order_types: payload.orderTypes,
    }),
  });
}

function* fetchInventoryTransfersXlsxSuccessSaga({ payload }) {
  try {
    const {
      data,
      inResponseTo: { endDate, startDate },
    } = payload;
    const filename = `Product Movement ${startDate} - ${endDate}.xlsx`;
    yield call(saveAs, data, filename);
  } catch (err) {
    showErrorMessage(
      'Error!',
      'Something went wrong while downloading XLSX file'
    );
  }
}

function* fetchWineSalesXlsxSuccessSaga({ payload }) {
  try {
    const {
      data,
      inResponseTo: { endDate, startDate },
    } = payload;
    const filename = `Taster Tracking ${startDate} - ${endDate}.xlsx`;
    yield call(saveAs, data, filename);
  } catch (err) {
    showErrorMessage(
      'Error!',
      'Something went wrong while downloading XLSX file'
    );
  }
}

function* fetchInventoriesXlsxSuccessSaga({ payload }) {
  try {
    const {
      data,
      inResponseTo: { endDate, startDate },
    } = payload;
    const filename = `Inventories ${startDate} - ${endDate}.xlsx`;
    yield call(saveAs, data, filename);
  } catch (err) {
    showErrorMessage(
      'Error!',
      'Something went wrong while downloading XLSX file'
    );
  }
}

function* fetchSalesSaga(action) {
  const { payload } = action;
  const url = constructSalesUrl('/reports/sales', {
    date_range_start: payload.startDate,
    date_range_end: payload.endDate,
    brand_ids: payload.brandIDs,
    order_types: payload.orderTypes,
    product_ids: payload.products,
  });
  yield put(push(url));
  yield api({
    action,
    method: 'get',
    url,
  });
}

function* fetchBatchXlsxSaga(action) {
  const { payload } = action;
  if (!payload.brandID) {
    showErrorMessage('Error!', 'Please select a brand');
    return;
  }
  yield api({
    action,
    method: 'get',
    headers: {
      Accept: 'text/csv',
    },
    responseType: 'blob',
    url: `/reports/batch_report/?${queryString.stringify({
      date_range_start: payload.startDate,
      date_range_end: payload.endDate,
      brand_id: payload.brandID,
    })}`,
  });
}

function* fetchBatchXlsxSuccessSaga({ payload }) {
  try {
    const {
      data,
      inResponseTo: { endDate, startDate },
    } = payload;
    const filename = `Batch ${formatDate(
      startDate,
      'yyyy-MM-dd'
    )} - ${formatDate(endDate, 'yyyy-MM-dd')}.csv`;
    yield call(saveAs, data, filename);
  } catch (err) {
    showErrorMessage(
      'Error!',
      'Something went wrong while downloading CSV file'
    );
  }
}

function* fetchOrdersSaga(action) {
  const { payload } = action;

  const { url } = yield generateURL({
    payload,
    url: '/reports/orders/',
    options: {
      arrayFormat: 'comma',
    },
    setQuery: true,
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}

function* fetchXlsxTransfersSaga(action) {
  const { payload } = action;
  if (!payload.date_range_start || !payload.date_range_end) {
    showErrorMessage('Error!', 'Please select a date range');
    return;
  }
  const { url } = yield generateURL({
    payload,
    url: '/transfers/',
    options: {
      arrayFormat: 'comma',
    },
  });

  yield api({
    action,
    method: 'get',
    headers: {
      Accept: 'application/xlsx',
    },
    responseType: 'blob',
    url,
  });
}

function* fetchXlsxTransfersSuccessSaga({ payload }) {
  try {
    const {
      data,
      inResponseTo: {
        date_range_start,
        date_range_end,
        brand_title,
        receiver_title,
        sender_title,
        product_titles,
      },
    } = payload;
    const information = [];
    if (date_range_start && date_range_end) {
      information.push(
        `${formatDate(date_range_start, 'yyyy-MM-dd')} - ${formatDate(
          date_range_end,
          'yyyy-MM-dd'
        )}`
      );
    }
    if (brand_title !== 'All brands') {
      information.push(`brand: ${brand_title}`);
    }
    if (sender_title !== 'All senders') {
      information.push(`sender: ${sender_title}`);
    }
    if (receiver_title !== 'All receivers') {
      information.push(`receiver: ${receiver_title}`);
    }
    if (product_titles) {
      information.push(product_titles);
    }
    const filename = `Transfers (${information.join(',')}).xlsx`;
    yield call(saveAs, data, filename);
  } catch (err) {
    showErrorMessage(
      'Error!',
      'Something went wrong while downloading XLSX file'
    );
  }
}

function* fetchWineAssociatesXslxSaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: '/reports/sales_by_wine_associate/',
    options: {
      arrayFormat: 'comma',
    },
  });

  yield api({
    action,
    method: 'get',
    headers: {
      Accept: 'application/xlsx',
    },
    responseType: 'blob',
    url,
  });
}

function* fetchWineAssociatesXslxSuccessSaga({ payload }) {
  try {
    const {
      data,
      inResponseTo: { date_range_start, date_range_end, sales_associate_name },
    } = payload;
    const information = [];
    if (date_range_start && date_range_end) {
      information.push(
        `${formatDate(date_range_start, 'yyyy-MM-dd')} - ${formatDate(
          date_range_end,
          'yyyy-MM-dd'
        )}`
      );
    }
    if (sales_associate_name) {
      information.push(sales_associate_name);
    }
    const filename = `Sales by Associates (${information.join(',')}).csv`;
    yield call(saveAs, data, filename);
  } catch (err) {
    showErrorMessage(
      'Error!',
      'Something went wrong while downloading CSV file'
    );
  }
}

function* fetchWineAssociatesSaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: '/reports/sales_by_wine_associate/',
    options: {
      arrayFormat: 'comma',
    },
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}

function* exportReportsFileSaga(action) {
  const { payload } = action;
  yield api({
    action,
    method: 'post',
    url: '/orders/exports/',
    data: payload,
  });
}

function* exportReportsFileSuccessSaga({ payload }) {
  yield call(downloadReportsFilesSaga, { payload });
  yield put(push('/reports/export-history'));
}

function* downloadReportsFilesSaga({ payload }) {
  const { data } = payload;

  const commercialFile = data?.commercial_orders_file_url ?? null;
  const onlineFile = data?.online_orders_file_url ?? null;
  const wineShopFile = data?.wineshop_sales_file_url ?? null;

  if (commercialFile) {
    yield downloadURI(commercialFile);
  }
  if (onlineFile) {
    yield downloadURI(onlineFile);
  }
  if (wineShopFile) {
    yield downloadURI(wineShopFile);
  }
}

function* fetchDashboardUrlSaga(action) {
  yield api({
    action,
    method: 'get',
    url: action.payload?.id
      ? `/reports/metabase/?brand_id=${action.payload.id}`
      : '/reports/metabase/',
  });
}

function* fetchInventoriesSaga(action) {
  const { payload } = action;
  const url = constructUrl('/reports/inventories', payload);
  yield api({
    action,
    method: 'get',
    url,
  });
}

function* fetchInventoriesXlsxSaga(action) {
  const { payload } = action;
  yield api({
    action,
    method: 'get',
    headers: {
      Accept: 'application/xlsx',
    },
    responseType: 'blob',
    url: constructUrl('/reports/inventories', payload),
  });
}

export default function*() {
  yield all([
    takeLatest(actions.fetchSales, fetchSalesSaga),
    takeLatest(actions.fetchSalesXlsx, fetchSalesXlsxSaga),
    takeLatest(actions.fetchWineSales, fetchWineSalesSaga),
    takeLatest(actions.fetchWineSalesXlsx, fetchWineSalesXlsxSaga),
    takeLatest(
      actions.fetchWineSalesXlsxSuccess,
      fetchWineSalesXlsxSuccessSaga
    ),
    takeLatest(
      actions.fetchInventoryTransfersXlsx,
      fetchInventoryTransfersXlsxSaga
    ),
    takeLatest(
      actions.fetchInventoryTransfersXlsxSuccess,
      fetchInventoryTransfersXlsxSuccessSaga
    ),
    takeLatest(actions.fetchBatchXlsx, fetchBatchXlsxSaga),
    takeLatest(actions.fetchBatchXlsxSuccess, fetchBatchXlsxSuccessSaga),
    takeLatest(actions.fetchXlsxTransfers, fetchXlsxTransfersSaga),
    takeLatest(
      actions.fetchXlsxTransfersSuccess,
      fetchXlsxTransfersSuccessSaga
    ),
    takeLatest(actions.fetchOrders, fetchOrdersSaga),
    takeLatest(actions.fetchWineAssociatesXslx, fetchWineAssociatesXslxSaga),
    takeLatest(
      actions.fetchWineAssociatesXslxSuccess,
      fetchWineAssociatesXslxSuccessSaga
    ),
    takeLatest(actions.fetchWineAssociates, fetchWineAssociatesSaga),
    takeLatest(actions.exportReportsFile, exportReportsFileSaga),
    takeLatest(actions.exportReportsFileSuccess, exportReportsFileSuccessSaga),
    takeLatest(actions.downloadReportsFiles, downloadReportsFilesSaga),
    takeLatest(actions.fetchDashboardUrl, fetchDashboardUrlSaga),
    takeLatest(actions.fetchInventories, fetchInventoriesSaga),
    takeLatest(actions.fetchInventoriesXlsx, fetchInventoriesXlsxSaga),
    takeLatest(
      actions.fetchInventoriesXlsxSuccess,
      fetchInventoriesXlsxSuccessSaga
    ),
  ]);
}
