import React, { useEffect } from 'react';
import styles from './SidebarAlert.scss';
import { Link } from 'react-router-dom';
import { failedWineDirectSyncsSelector } from 'models/wineDirectSync/selectors';
import { useSelector } from 'react-redux';
import { actions as wineDirectSyncActions } from 'models/wineDirectSync/slice';
import { isOnlySingleRole } from 'utils/isOnlySingleRole';
import { isOnlyHostOrStaff } from 'utils/isOnlyHostOrStaff';
import { rolesSelector, isOnlyBookkeeperSelector } from 'models/user/selectors';
import useAction from 'hooks/useAction';

import { format } from 'date-fns';

const formatDate = (date, template = 'yyyy-MM-dd, h:mm aaa') => {
  if (!date) {
    return '';
  }
  return format(Date.parse(date), template);
};

const SidebarAlert = () => {
  const roles = useSelector(rolesSelector);
  const isSalesRep = isOnlySingleRole(roles, 'sales_rep');
  const isHost = isOnlySingleRole(roles, 'host');
  const isStaff = isOnlySingleRole(roles, 'staff');
  const isHostOrStaff = isOnlyHostOrStaff(roles);
  const isBookkeeper = useSelector(isOnlyBookkeeperSelector);

  const fetchFailedWineDirectSyncs = useAction(
    wineDirectSyncActions.fetchFailedWineDirectSyncs
  );

  useEffect(() => {
    if (
      roles.length > 0 &&
      !isSalesRep &&
      !isHost &&
      !isBookkeeper &&
      !isStaff &&
      !isHostOrStaff
    ) {
      fetchFailedWineDirectSyncs();
    }
  }, [fetchFailedWineDirectSyncs, roles]);

  const failedWineDirectSyncs = useSelector(failedWineDirectSyncsSelector);

  const failedSyncExists = !!failedWineDirectSyncs?.failed_sync?.created_at;

  const failedBrandId = failedWineDirectSyncs?.brands_with_failed_syncs?.[0];
  const wineDirectTab = 3;

  return (
    <>
      {failedSyncExists && (
        <Link to={`/brands/${failedBrandId}/edit/${wineDirectTab}`}>
          <div className={styles.sidebarAlert}>
            <p className={styles.title}>WineDirect sync failure</p>
            <p className={styles.subtitle}>Failed at</p>
            <p className={styles.date}>
              {formatDate(failedWineDirectSyncs.failed_sync.created_at)}
            </p>
            <p className={styles.subtitle}>Last successful sync</p>
            <p className={styles.date}>
              {formatDate(
                failedWineDirectSyncs?.last_successful_sync?.created_at
              )}
            </p>
          </div>
        </Link>
      )}
    </>
  );
};

SidebarAlert.propTypes = {};

export default SidebarAlert;
