import React from 'react';
import { Card, Table } from 'reactstrap';
import { ORDER_TYPES_ITEMS, FEE_TYPES_PRICE_SHOW } from 'constants';
import formatPrice from 'utils/formatPrice';
import PropTypes from 'prop-types';

const FeeTable = ({ data }) => {
  if (!data) {
    return 'There is no any fees.';
  }

  return (
    <Card>
      <div className="table-rep-plugin">
        <div className="table-responsive mb-0" data-pattern="priority-columns">
          <Table id="tech-companies-1" bordered responsive>
            <thead>
              <tr>
                <th data-priority="1">Title</th>
                <th data-priority="1">Alias</th>
                <th data-priority="3">Amount</th>
                <th data-priority="3">Unit</th>
                <th data-priority="1">Order Type</th>
                <th data-priority="3">Order Item Type</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => (
                <tr key={index?.toString()}>
                  <td>{item?.title}</td>
                  <td>{item?.alias ?? '-'}</td>
                  <td>{formatPrice(Number(item?.amount))}</td>
                  <td>{FEE_TYPES_PRICE_SHOW[item?.units]}</td>
                  <td>{ORDER_TYPES_ITEMS[item?.order_class]}</td>
                  <td>{ORDER_TYPES_ITEMS[item?.order_item_class]}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </Card>
  );
};

FeeTable.propTypes = {
  data: PropTypes.array.isRequired,
};
export default FeeTable;
