import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';

import { isPendingSelector } from 'models/brands/selectors';
import useSelector from 'hooks/useSelector';

import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';

import useAction from 'hooks/useAction';

import { actions as organizationsActions } from 'models/organizations/slice';
import OrganizationForm from 'components/OrganizationForm';

import Preloader from 'components/Preloader';
import { tabs } from 'constants/organizations';

const CreateLead = ({ title }) => {
  const createLead = useAction(organizationsActions.createOrganization);
  const setCreatedOrganization = useAction(
    organizationsActions.setCreatedOrganization
  );
  const setCurrentTab = useAction(organizationsActions.setCurrentTab);
  const pending = useSelector(isPendingSelector);

  useEffect(() => {
    setCreatedOrganization(null);
    setCurrentTab(tabs[0]);
  }, []);

  if (pending) return <Preloader />;

  return (
    <PageWrapper title={title}>
      <Breadcrumbs
        title="Create new Lead"
        breadcrumbItems={[
          {
            title: 'Back to Leads',
            link: '/leads/',
            withSearch: true,
          },
        ]}
      />

      <Col md="12">
        <OrganizationForm onSubmit={createLead} submitTitle="Save" isLead />
      </Col>
    </PageWrapper>
  );
};

CreateLead.propTypes = {
  title: PropTypes.string,
};

export default CreateLead;
