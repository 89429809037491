/* eslint-disable dot-notation */
import React from 'react';
import PropTypes from 'prop-types';

import {
  Table,
  Card,
  // Badge,
} from 'reactstrap';

import getOrderTypeHumanName from 'utils/getOrderTypeHumanName';
import { getProductTitleWithVintage } from 'utils/preparedProductTitle';
import formatPrice from 'utils/formatPrice';

const Main = ({ orderItems }) => {
  return orderItems?.length === 0 ? (
    <h3>The list of order items is empty </h3>
  ) : (
    <>
      <Card>
        <div className="table-rep-plugin">
          <div
            className="table-responsive mb-0"
            data-pattern="priority-columns"
          >
            <Table id="tech-companies-1" bordered responsive>
              <thead>
                <tr>
                  <th data-priority="1">ID</th>
                  <th data-priority="1">SKU</th>
                  <th data-priority="1">Title</th>
                  <th data-priority="1">Amount</th>
                  <th data-priority="1">Price</th>
                  <th data-priority="3">Discount</th>
                  <th data-priority="3">Total Price</th>
                  <th data-priority="3">Type</th>
                </tr>
              </thead>
              <tbody>
                {orderItems?.map(item => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.sku}</td>

                    <td>
                      {getProductTitleWithVintage(
                        item?.title,
                        item?.product?.vintage
                      )}
                    </td>
                    <td>{item.number} </td>
                    <td>
                      {formatPrice(
                        item.discounted_product_price || item.product_price
                      )}
                      &nbsp;
                      {item.product_price_currency}{' '}
                    </td>
                    <td>
                      {formatPrice(item.total_order_item_discount)}&nbsp;
                      {item.total_price_currency}
                    </td>
                    <td>
                      {formatPrice(item.total_price)}&nbsp;
                      {item.total_price_currency}{' '}
                    </td>
                    <td>{getOrderTypeHumanName(item.resourcetype)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </Card>
    </>
  );
};

Main.propTypes = {
  orderItems: PropTypes.array,
};

export default Main;
