import React, { useState } from 'react';
import { showSuccessMessage, showErrorMessage } from 'utils/notification';

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavLink,
} from 'reactstrap';
import useSelector from 'hooks/useSelector';
import useAction from 'hooks/useAction';
import {
  workspacesSelector,
  emailSelector,
  settingsSelector,
} from 'models/user/selectors';
import { actions as commonActions } from 'models/common/slice';
import avatar from 'images/users/avatar.png';
import config from 'config';

const ProfileMenu = () => {
  const { dashboardUrl } = config;
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const toggle = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  const email = useSelector(emailSelector);
  const settings = useSelector(settingsSelector);
  const workspaces = useSelector(workspacesSelector);
  const username = email || 'no user';
  const resetDemoWorkspace = useAction(commonActions.resetDemoWorkspace);

  const redirectToPOS = () => {
    window.open(dashboardUrl, '_blank');
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isOpenMenu}
        toggle={toggle}
        className="d-inline-block user-dropdown"
      >
        <DropdownToggle
          tag="button"
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
        >
          <img
            className="rounded-circle header-profile-user mr-1"
            src={avatar}
            alt="Header Avatar"
          />
          <span className=" d-xl-inline-block ml-1 text-transform">
            {username}
          </span>
          <i className="mdi mdi-chevron-down ml-1 d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={redirectToPOS}>Go to POS</DropdownItem>
          {settings?.is_demo_site && (
            <DropdownItem
              onClick={() => {
                if (workspaces?.length > 1) {
                  showErrorMessage(
                    'Error!',
                    'You belong to more than one workspace. Please contact Cellar to reset your demo workspace.'
                  );
                } else {
                  resetDemoWorkspace({ workspaceId: workspaces[0].id });
                  showSuccessMessage(
                    'Success!',
                    'Demo workspace is being reset. This will take a few moments, please do not use Cellar until your workspace refreshes automatically.',
                    60000
                  );
                }
              }}
            >
              Reset Demo Workspace
            </DropdownItem>
          )}
          <NavLink href="/logout" className="text-danger p-0">
            <DropdownItem className="text-danger align-items-center">
              <i className="ri-shut-down-line mr-2 text-danger" />
              <span>Logout</span>
            </DropdownItem>
          </NavLink>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileMenu;
