import React from 'react';
import useApiRequest from 'hooks/useApiRequest';
import { AsyncPaginate } from 'react-select-async-paginate';
import PropTypes from 'prop-types';
import { selectStyles } from 'constants/selectStyles';
import * as queryString from 'query-string';

const getWarehouseTitle = warehouse => {
  const brandTitleValue = warehouse?.brand_title
    ? ` (${warehouse.brand_title})`
    : '';
  return `${warehouse.title}${brandTitleValue}`;
};

const WarehousesPaginatedField = ({
  onChange,
  isMulti,
  parameters,
  defaultValue,
  onBlur,
  error,
  classNames,
  isInitialLabel,
  initialLabel,
  isTitle,
  ...props
}) => {
  const apiRequest = useApiRequest();

  const fetchWarehouses = ({ page = 1 }) => {
    const queryParameters = queryString.stringify(
      { page, ...parameters },
      {
        arrayFormat: 'comma',
      }
    );

    return apiRequest({
      url: `/warehouses/?${queryParameters}`,
    });
  };

  const initialOption = {
    label: initialLabel,
    value: null,
  };

  const loadWarehouses = async (search, loadedOptions, { page }) => {
    try {
      const response = await fetchWarehouses({ page });

      const options = response?.results.map(item => ({
        value: item.id,
        label: isTitle ? getWarehouseTitle(item) : item.title,
        ...item,
      }));

      return {
        options:
          isInitialLabel && page === 1 ? [initialOption, ...options] : options,
        hasMore: response?.pagination?.next_page,
        additional: { page: page + 1 },
      };
    } catch (err) {
      return {
        options: [],
        hasMore: false,
        additional: { page },
      };
    }
  };

  const getValue = val => {
    if (val) return val;

    if (isInitialLabel)
      return {
        label: 'All warehouses',
        value: '',
      };

    return null;
  };

  return (
    <>
      <AsyncPaginate
        {...props}
        name="warehouses"
        cacheUniqs={[parameters]}
        isSearchable={false}
        additional={{
          page: 1,
        }}
        defaultValue={getValue(defaultValue)}
        loadOptions={loadWarehouses}
        onChange={onChange}
        isMulti={isMulti}
        onBlur={onBlur}
        styles={selectStyles}
        className={classNames}
        isClearable
      />
      {!!error && <small className="text-danger">{error}</small>}
    </>
  );
};

WarehousesPaginatedField.defaultProps = {
  initialLabel: 'All warehouses',
};

WarehousesPaginatedField.propTypes = {
  onChange: PropTypes.func,
  isMulti: PropTypes.bool,
  defaultValue: PropTypes.object,
  parameters: PropTypes.object,
  onBlur: PropTypes.func,
  error: PropTypes.string,
  classNames: PropTypes.string,
  isInitialLabel: PropTypes.bool,
  initialLabel: PropTypes.string,
  isTitle: PropTypes.bool,
};

export default WarehousesPaginatedField;
