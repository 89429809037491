import { all } from 'redux-saga/effects';
import { connectRouter } from 'connected-react-router';

import userReducer from './user/slice';
import hostsReducer from './hosts/slice';
import tiersReducer from './tiers/slice';
import tablesReducer from './tables/slice';
import commonReducer from './common/slice';
import awardsReducer from './awards/slice';
import brandsReducer from './brands/slice';
import ordersReducer from './orders/slice';
import devicesReducer from './devices/slice';
import reportsReducer from './reports/slice';
import productsReducer from './products/slice';
import transfersReducer from './transfers/slice';
import terminalsReducer from './terminals/slice';
import acquirersReducer from './acquirers/slice';
import wineclubsReducer from './wineclubs/slice';
import wineDirectSyncReducer from './wineDirectSync/slice';
import workspacesReducer from './workspaces/slice';
import warehousesReducer from './warehouses/slice';
import inventoriesReducer from './inventories/slice';
import tastingRoomsReducer from './tastingRooms/slice';
import brandsDataSourcesReducer from './brandsDataSources/slice';
import brandsCompaniesReducer from './brandsCompanies/slice';
import wineclubMembersReducer from './wineclubMembers/slice';
import productsPresetsReducer from './productsPresets/slice';
import importDataSourcesReducer from './importDataSources/slice';
import subscriptionOrdersReducer from './subscriptionOrders/slice';
import subscriptionsReducer from './subscriptions/slice';
import orderShipmentRatesReducer from './orderShipmentRates/slice';
import customersReducer from './customers/slice';
import mailingListsReducer from './mailingLists/slice';
import organizationsReducer from './organizations/slice';
import tagsReducer from './tags/slice';
import emailTemplatesReducer from './emailTemplates/slice';
import footTrafficReducer from './footTraffic/slice';
import discountCodesReducer from './discounts/slice';
import importCustomersReducer from './importCustomers/slice';
import imagesReducer from './images/slice';
import commissionsReducer from './commissions/slice';
import timeEntryReducer from './timeEntry/slice';
import exciseReducer from './excise/slice';

import commonSagas from './common/sagas';
import userSagas from './user/sagas';
import hostsSagas from './hosts/sagas';
import tiersSagas from './tiers/sagas';
import tablesSagas from './tables/sagas';
import ordersSagas from './orders/sagas';
import awardsSagas from './awards/sagas';
import brandsSagas from './brands/sagas';
import devicesSagas from './devices/sagas';
import reportsSagas from './reports/sagas';
import productsSagas from './products/sagas';
import transfersSagas from './transfers/sagas';
import acquirersSagas from './acquirers/sagas';
import wineclubsSagas from './wineclubs/sagas';
import wineDirectSyncSagas from './wineDirectSync/sagas';
import terminalsSagas from './terminals/sagas';
import warehousesSagas from './warehouses/sagas';
import inventoriesSagas from './inventories/sagas';
import tastingRoomsSagas from './tastingRooms/sagas';
import brandsCompaniesSagas from './brandsCompanies/sagas';
import wineclubMembersSagas from './wineclubMembers/sagas';
import productsPresetsSagas from './productsPresets/sagas';
import brandsDataSourcesSagas from './brandsDataSources/sagas';
import importDataSourcesSagas from './importDataSources/sagas';
import orderShipmentRatesSagas from './orderShipmentRates/sagas';
import subscriptionOrdersSagas from './subscriptionOrders/sagas';
import subscriptionsSagas from './subscriptions/sagas';
import customersSagas from './customers/sagas';
import mailingListsSagas from './mailingLists/sagas';
import organizationsSagas from './organizations/sagas';
import tagsSagas from './tags/sagas';
import emailTemplatesSagas from './emailTemplates/sagas';
import footTrafficSagas from './footTraffic/sagas';
import discountCodesSagas from './discounts/sagas';
import importCustomersSagas from './importCustomers/sagas';
import imagesSagas from './images/sagas';
import commissionsSagas from './commissions/sagas';
import timeEntrySagas from './timeEntry/sagas';
import exciseSagas from './excise/sagas';

export const createRootReducer = history => ({
  router: connectRouter(history),
  user: userReducer,
  hosts: hostsReducer,
  tiers: tiersReducer,
  tables: tablesReducer,
  customers: customersReducer,
  common: commonReducer,
  awards: awardsReducer,
  orders: ordersReducer,
  brands: brandsReducer,
  devices: devicesReducer,
  reports: reportsReducer,
  products: productsReducer,
  transfers: transfersReducer,
  acquirers: acquirersReducer,
  terminals: terminalsReducer,
  wineclubs: wineclubsReducer,
  wineDirectSync: wineDirectSyncReducer,
  workspaces: workspacesReducer,
  warehouses: warehousesReducer,
  inventories: inventoriesReducer,
  tastingRooms: tastingRoomsReducer,
  brandsSources: brandsDataSourcesReducer,
  brandsCompanies: brandsCompaniesReducer,
  wineclubMembers: wineclubMembersReducer,
  productsPresets: productsPresetsReducer,
  importDataSources: importDataSourcesReducer,
  subscriptionOrders: subscriptionOrdersReducer,
  subscriptions: subscriptionsReducer,
  orderShipmentRates: orderShipmentRatesReducer,
  mailingLists: mailingListsReducer,
  organizations: organizationsReducer,
  tags: tagsReducer,
  emailTemplates: emailTemplatesReducer,
  footTraffic: footTrafficReducer,
  discountCodes: discountCodesReducer,
  importCustomers: importCustomersReducer,
  images: imagesReducer,
  commissions: commissionsReducer,
  timeEntry: timeEntryReducer,
  excise: exciseReducer,
});

export const rootSaga = function*() {
  yield all([
    commonSagas(),
    userSagas(),
    tiersSagas(),
    hostsSagas(),
    tablesSagas(),
    brandsSagas(),
    ordersSagas(),
    awardsSagas(),
    reportsSagas(),
    devicesSagas(),
    productsSagas(),
    transfersSagas(),
    wineclubsSagas(),
    wineDirectSyncSagas(),
    acquirersSagas(),
    terminalsSagas(),
    warehousesSagas(),
    inventoriesSagas(),
    tastingRoomsSagas(),
    wineclubMembersSagas(),
    productsPresetsSagas(),
    brandsCompaniesSagas(),
    brandsDataSourcesSagas(),
    importDataSourcesSagas(),
    subscriptionOrdersSagas(),
    orderShipmentRatesSagas(),
    subscriptionsSagas(),
    customersSagas(),
    mailingListsSagas(),
    organizationsSagas(),
    tagsSagas(),
    emailTemplatesSagas(),
    footTrafficSagas(),
    discountCodesSagas(),
    importCustomersSagas(),
    imagesSagas(),
    timeEntrySagas(),
    exciseSagas(),
    commissionsSagas(),
  ]);
};
