import React from 'react';
import PropTypes from 'prop-types';
import ActionDropdown from 'components/ActionDropdown';
import { useHistory } from 'react-router-dom';

const CreatOrderItemId = 'create_order';

const OrganizationActionDropdown = ({ customer }) => {
  const router = useHistory();

  const onItemClickHandler = item => {
    if (item.id === CreatOrderItemId) {
      router.push('/commercial-orders/create', {
        customer,
      });
    }
  };

  const dropdownItem = [
    {
      id: 'create_order',
      icon: <i className="ri-add-line align-middle font-size-18 mr-1" />,
      label: 'Create Order',
    },
    {
      icon: <i className="mdi mdi-pencil font-size-18 mr-2" />,
      label: 'Edit',
      href: `/commercial-customers/${customer.id}/edit`,
    },
  ];

  return (
    <ActionDropdown onItemClick={onItemClickHandler} items={dropdownItem} />
  );
};

OrganizationActionDropdown.propTypes = {
  customer: PropTypes.object,
};

export default OrganizationActionDropdown;
