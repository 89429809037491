import React, { useMemo } from 'react';
import formatPrice from 'utils/formatPrice';
import formatMonth from 'utils/formatMonth';
import useAction from 'hooks/useAction';
import { actions as orderActions } from 'models/orders/slice';
import PropTypes from 'prop-types';
import { Row, Col, Button, Card, CardBody, Table } from 'reactstrap';
import CardItem from 'components/CardItem';
import { ORDER_PAYMENT_STATUSES } from 'constants';
import useSelector from 'hooks/useSelector';
import {
  isPendingSelector,
  isPendingChangeStatusSelector,
} from 'models/orders/selectors';
import OrderInfoBlock from 'components/OrderInfoBlock';

const OrderInfo = ({
  order,
  handlePay,
  createdShipment,
  currentCard,
  paymentType,
  payLater,
}) => {
  const changeOrderStatus = useAction(orderActions.changeOrderStatus);
  const patchOrder = useAction(orderActions.patchOrder);
  const isPendingOrder = useSelector(isPendingSelector);
  const isPendingChangeStatus = useSelector(isPendingChangeStatusSelector);

  const isPending = isPendingOrder || isPendingChangeStatus;

  const shipmentPrice = useMemo(() => {
    return Number(order.shipping_info?.shipments[0]?.price || 0);
  }, [order]);

  const handleOrderApprove = () => {
    patchOrder({
      id: order?.id,
      expected_payment_type: paymentType,
    });
    changeOrderStatus({
      id: order?.id,
      email: order?.shipping_info.email,
      status: 'SHIPPED',
      orderType: order?.resourcetype,
    });
  };

  const handlePayButtonClick = () => {
    if (paymentType === 'CARD' && !payLater) {
      handlePay();
    } else if (paymentType !== 'CARD' && !payLater) {
      patchOrder({
        id: order?.id,
        expected_payment_type: paymentType,
      });
      changeOrderStatus({
        id: order?.id,
        email: order?.shipping_info.email,
        status: ORDER_PAYMENT_STATUSES[paymentType],
        orderType: order?.resourcetype,
      });
    }
  };

  return (
    <>
      <Row>
        <Col lg="3">
          <CardItem
            title="Customer"
            value={`${order?.customer?.first_name} ${order?.customer?.last_name} ${order?.customer?.email}`}
          />
        </Col>
        <Col lg="3">
          <CardItem title="Brand" value={order?.brand?.title} />
        </Col>
        <Col lg="3">
          <CardItem
            title="Shipment Rate"
            value={
              // eslint-disable-next-line no-nested-ternary
              createdShipment
                ? `${
                    createdShipment?.brand_shipping_company_title
                      ? createdShipment?.brand_shipping_company_title
                      : 'Unknown company'
                  } - $${createdShipment?.price} ${
                    createdShipment?.free_shipping ? '(Free shipping)' : ''
                  }`
                : 'Pickup'
            }
          />
        </Col>
        {currentCard && (
          <Col lg="3">
            <CardItem
              title="Card"
              value={`**** **** **** ${currentCard?.card_last_digits} ${
                currentCard?.card_exp_month
              }/${formatMonth(
                currentCard?.card_exp_year.toString()
              )} ${currentCard.card_brand.toUpperCase()}`}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col lg="12">
          <Card>
            <Table bordered responsive>
              <thead>
                <tr>
                  <th data-priority="1">ID</th>
                  <th data-priority="1">Title</th>
                  <th data-priority="1">Quantity</th>
                  <th data-priority="1">Discount</th>
                  <th data-priority="1">Price (Including Discount)</th>
                  <th data-priority="1">Tax</th>
                  <th data-priority="3">Total Price</th>
                </tr>
              </thead>
              <tbody>
                {order.order_items?.map(item => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.title} </td>
                    <td>{item.number} </td>
                    <td>
                      {item.total_order_item_discount > 0
                        ? `(-${formatPrice(item?.total_order_item_discount)}) ${
                            item?.product_price_currency
                          }`
                        : '-'}
                    </td>
                    <td>
                      {formatPrice(item.discounted_price_amount)}&nbsp;
                      {item.product_price_currency}{' '}
                    </td>
                    <td>
                      {formatPrice(item.fee)}&nbsp;{item.product_price_currency}{' '}
                    </td>
                    <td>
                      {formatPrice(item.total_price)}&nbsp;
                      {item.total_price_currency}{' '}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="6">
          <Card>
            <CardBody>
              <OrderInfoBlock
                title="Product Subtotal"
                value={`${formatPrice(
                  order?.order_items?.reduce(
                    (acc, value) => acc + value.number * value.product_price,
                    0
                  )
                )} ${order?.total_price_currency}`}
              />
              {order?.total_order_discount > 0 && (
                <OrderInfoBlock
                  title="Discounts"
                  value={`-${formatPrice(order?.total_order_discount)} ${
                    order?.total_price_currency
                  }`}
                />
              )}
              {Object.keys(order.applied_fees).map(fee => (
                <OrderInfoBlock
                  title={fee}
                  value={`${formatPrice(order?.applied_fees[fee])} ${
                    order?.total_price_currency
                  }`}
                />
              ))}
              <OrderInfoBlock
                title="Shipping"
                value={
                  createdShipment
                    ? `${
                        createdShipment.free_shipping ? '0.00' : shipmentPrice
                      } ${order?.total_price_currency}`
                    : 'Pickup'
                }
              />
              <hr />
              <OrderInfoBlock
                title="Total"
                value={`${formatPrice(order?.total_price)} ${
                  order?.total_price_currency
                }`}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      {payLater && (
        <Button
          disabled={isPending}
          className="mr-2"
          color="primary"
          onClick={handleOrderApprove}
        >
          Approve order
        </Button>
      )}
      {!payLater && (
        <Button
          disabled={isPending}
          className="mr-2"
          color="primary"
          onClick={handlePayButtonClick}
        >
          Pay
        </Button>
      )}
    </>
  );
};

OrderInfo.propTypes = {
  order: PropTypes.object,
  handlePay: PropTypes.func,
  createdShipment: PropTypes.object,
  currentCard: PropTypes.object,
  paymentType: PropTypes.string,
  payLater: PropTypes.bool,
};

export default OrderInfo;
