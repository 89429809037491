import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';
import { actions as productsActions } from 'models/products/slice';
import { actions as shipmentActions } from '../orderShipmentRates/slice';
import { actions as subscriptionOrdersActions } from '../subscriptionOrders/slice';
import { getPreparedRefundData } from 'utils/getPreparedRefundData';

function updateOrder(state, { payload }) {
  state.orders = state.orders.map(item => {
    if (item.id !== payload.data.id) {
      return item;
    }
    return {
      ...item,
      ...payload.data,
    };
  });
  state.isPending = false;
}

const ordersSlice = createSlice({
  name: 'orders',
  initialState: {
    isPending: false,
    isDownloadingPending: false,
    isSendBillPending: false,
    isSendRefundBillPending: false,
    isPendingChangeStatus: false,
    orderSendRefundPending: false,
    isReportPending: false,
    createOrderTab: 1,
    paymentStatusModalOpen: false,
    orders: [],
    orders_ids: [],
    orderStatuses: [],
    order: {},
    ordersRefund: {},
    ordersRefundPending: false,
    createdOrder: null,
    pagination: {},
    pendingFulfillmentPagination: {},
    productsInOrder: {},
    confirmPending: false,
    exportHistory: [],
    isExportHistoryPending: false,
    pendingFulfillmentOrders: [],
    pendingFulfillmentAllOrders: [],
    pendingFulfillmentAllOrdersPagination: {},
    pendingFulfillmentCommercialOrders: [],
    pendingFulfillmentCommercialOrdersPagination: {},
    orderImportSync: {},
  },
  reducers: {
    setCreateOrderTab(state, { payload }) {
      state.createOrderTab = payload;
    },
    fetchOrders: state => {
      state.isPending = true;
    },
    fetchOrdersSuccess(state, { payload }) {
      state.isPending = false;
      state.orders = payload.data.results;
      state.orders_ids = payload.data.orders_ids;
      state.pagination = payload.data.pagination;
    },
    fetchOrdersFailure: state => {
      state.isPending = false;
    },
    fetchCommercialOrders: state => {
      state.isPending = true;
    },
    fetchCommercialOrdersSuccess(state, { payload }) {
      state.isPending = false;
      state.orders = payload.data.results;
      state.orders_ids = payload.data.orders_ids;
      state.pagination = payload.data.pagination;
    },
    fetchCommercialOrdersFailure: state => {
      state.isPending = false;
    },
    fetchPendingFulfillmentOrders(state) {
      state.isPending = false;
    },
    fetchPendingFulfillmentOrdersSuccess(state, { payload }) {
      state.isPending = false;
      state.pendingFulfillmentOrders = payload.data.results;
      state.pendingFulfillmentPagination = payload.data.pagination;
    },
    fetchPendingFulfillmentOrdersFailure(state) {
      state.isPending = false;
    },
    fetchOrder: state => {
      state.isPending = true;
    },
    fetchOrderSuccess(state, { payload }) {
      state.isPending = false;
      state.order = payload.data;
      state.createdOrder = payload.data;
      state.productsInOrder = payload.data.order_items.reduce(
        (prev, current) => ({
          ...prev,
          [current.id]: {
            product_id: current?.product?.id,
            price: current.product_price,
            number: current?.number,
          },
        }),
        {}
      );
    },
    fetchOrderFailure: state => {
      state.isPending = false;
    },

    refundOrder: state => {
      state.orderSendRefundPending = true;
    },

    refundOrderSuccess: state => {
      state.orderSendRefundPending = false;
    },

    refundOrderFailure: state => {
      state.orderSendRefundPending = false;
    },

    cancelOrder: state => {
      state.isPending = true;
    },
    cancelOrderSuccess(state) {
      state.isPending = false;
    },
    cancelOrderFailure: state => {
      state.isPending = false;
    },
    setProductsInOrder(state, { payload }) {
      const { id } = payload;
      state.productsInOrder[id] = { ...state.productsInOrder[id], ...payload };
    },
    deleteProductsInOrder(state, { payload }) {
      delete state.productsInOrder[payload];
    },
    resetProductsInOrder(state) {
      state.productsInOrder = {};
    },
    fetchOrderShipmentLabel(state) {
      state.isPending = true;
    },
    fetchOrderShipmentLabelSuccess(state) {
      state.isPending = false;
    },
    fetchOrderShipmentLabelFailure(state) {
      state.isPending = false;
    },
    fetchOrderShipmentPublicUrl(state) {
      state.isPending = true;
    },
    fetchOrderShipmentPublicUrlSuccess(state) {
      state.isPending = false;
    },
    fetchOrderShipmentPublicUrlFailure(state) {
      state.isPending = false;
    },
    createOrder: state => {
      state.isPending = true;
    },
    createOrderSuccess(state, { payload }) {
      state.isPending = false;
      state.orders.push(payload);
      state.createdOrder = payload.data;
      if (payload.inResponseTo.changeNextStep === true) {
        state.createOrderTab = 2;
      }
    },
    createOrderFailure: state => {
      state.isPending = false;
    },
    updateOrder: state => {
      state.isPending = true;
    },
    updateOrderSuccess(state, { payload }) {
      state.isPending = false;
      state.createdOrder = payload.data;
      if (payload.inResponseTo.changeNextStep === true) {
        state.createOrderTab = 2;
      }
    },
    updateOrderFailure: state => {
      state.isPending = false;
    },
    patchOrder: state => {
      state.isPending = true;
    },
    patchOrderSuccess(state, { payload }) {
      state.isPending = false;
      state.createdOrder = payload.data;
    },
    patchOrderFailure: state => {
      state.isPending = false;
    },
    createCommercialOrder: state => {
      state.isPendingAction = true;
    },
    createCommercialOrderSuccess(state, { payload }) {
      state.isPendingAction = false;
      state.orders.push(payload);
      state.createdOrder = payload.data;
    },
    createCommercialOrderFailure: state => {
      state.isPendingAction = false;
    },
    updateCommercialOrder: state => {
      state.isPendingAction = true;
    },
    updateCommercialOrderSuccess(state, { payload }) {
      state.isPendingAction = false;
      state.createdOrder = payload.data;
    },
    updateCommercialOrderFailure: state => {
      state.isPendingAction = false;
    },
    patchCommercialOrder: state => {
      state.isPending = true;
    },
    patchCommercialOrderSuccess(state, { payload }) {
      state.isPending = false;
      state.createdOrder = payload.data;
    },
    patchCommercialOrderFailure: state => {
      state.isPending = false;
    },

    confirmCommercialOrder: state => {
      state.confirmPending = true;
    },
    confirmCommercialOrderSuccess: state => {
      state.confirmPending = false;
    },
    confirmCommercialOrderFailure: state => {
      state.confirmPending = false;
    },

    capturePayment: state => {
      state.isPending = true;
    },
    capturePaymentSuccess: state => {
      state.isPending = false;
      state.createdOrder = null;
    },
    capturePendingFailure: state => {
      state.isPending = false;
    },
    sendOrderBill: state => {
      state.isSendBillPending = true;
    },
    sendOrderBillFailure: state => {
      state.isSendBillPending = false;
    },
    sendOrderBillSuccess: state => {
      state.isSendBillPending = false;
    },
    sendRefundOrderBill: state => {
      state.isSendRefundBillPending = true;
    },
    sendRefundOrderBillFailure: state => {
      state.isSendRefundBillPending = false;
    },
    sendRefundOrderBillSuccess: state => {
      state.isSendRefundBillPending = false;
    },
    captureCommercialPayment: state => {
      state.isPending = true;
    },
    captureCommercialPaymentSuccess(state, { payload }) {
      const orderIdx = state.orders.findIndex(
        el => el.id === payload.inResponseTo.id
      );
      if (orderIdx >= 0) {
        state.orders[orderIdx].status = payload?.data?.status;
      }
      state.isPending = false;
      state.createdOrder = null;
    },
    captureCommercialPaymentFailure: state => {
      state.isPending = false;
    },
    clearCreatedOrder: state => {
      state.createdOrder = null;
    },
    fetchCommercialReport: state => {
      state.isReportPending = true;
    },
    fetchCommercialReportSuccess: state => {
      state.isReportPending = false;
    },
    fetchCommercialReportFailure: state => {
      state.isReportPending = false;
    },
    fetchOrderStatuses: state => {
      state.isPending = true;
    },
    fetchOrderStatusesSuccess(state, { payload }) {
      state.isPending = false;
      state.orderStatuses = payload.data;
    },
    fetchOrderStatusesFailure: state => {
      state.isPending = false;
    },
    changeOrderStatus: state => {
      state.isPendingChangeStatus = true;
    },
    changeOrderStatusSuccess(state, { payload }) {
      const orderId = payload.inResponseTo.id;
      const orderIdx = state.orders.findIndex(el => el.id === orderId);
      if (orderIdx >= 0) {
        state.orders[orderIdx].status = payload?.data?.status;
      }

      state.isPendingChangeStatus = false;
      state.paymentStatusModalOpen = false;
    },
    changeOrderStatusFailure(state) {
      state.isPendingChangeStatus = false;
    },
    setPaymentStatusModalOpen(state, { payload }) {
      state.paymentStatusModalOpen = payload;
    },

    fetchOrderRefund(state) {
      state.ordersRefundPending = true;
    },

    fetchOrderRefundSuccess(state, { payload }) {
      const refunds = payload.data.results;
      if (refunds.length > 0) {
        const { order_id } = refunds[0];
        state.ordersRefund[order_id] = getPreparedRefundData(refunds);
      }
      state.ordersRefundPending = false;
    },
    fetchOrderRefundFailure(state) {
      state.ordersRefundPending = false;
    },

    printFile: state => {
      state.isDownloadingPending = true;
    },
    printFileSuccess(state) {
      state.isDownloadingPending = false;
    },
    printFileFailure: state => {
      state.isDownloadingPending = false;
    },
    fetchExportHistory: state => {
      state.isExportHistoryPending = true;
    },
    fetchExportHistorySuccess(state, { payload }) {
      state.exportHistory = payload.data.results;
      state.exportHistoryPagination = payload.data.pagination;
      state.isExportHistoryPending = false;
    },
    fetchPendingFulfillmentAllOrders(state) {
      state.isPending = false;
    },
    fetchPendingFulfillmentAllOrdersSuccess(state, { payload }) {
      state.isPending = false;
      state.pendingFulfillmentAllOrders = payload.data.results;
      state.pendingFulfillmentAllOrdersPagination = payload.data.pagination;
    },
    fetchPendingFulfillmentAllOrdersFailure(state) {
      state.isPending = false;
    },
    fetchPendingFulfillmentCommercialOrders: state => {
      state.isPending = true;
    },
    fetchPendingFulfillmentCommercialOrdersSuccess(state, { payload }) {
      state.isPending = false;
      state.pendingFulfillmentCommercialOrders = payload.data.results;
      state.pendingFulfillmentCommercialOrdersPagination =
        payload.data.pagination;
    },
    fetchPendingFulfillmentCommercialOrdersFailure: state => {
      state.isPending = false;
    },
    completeOrder(state) {
      state.isPending = true;
    },
    completeOrderSuccess(state) {
      state.isPending = false;
    },
    completeOrderFailure(state) {
      state.isPending = false;
    },
    clearOrderImport(state) {
      state.orderImportSync = {};
    },
    createOrderImport(state) {
      state.orderImportSync = {};
      state.isPending = true;
    },
    createOrderImportSuccess(state, { payload }) {
      state.orderImportSync = payload.data;
      state.isPending = false;
    },
    createOrderImportFailure(state) {
      state.isPending = false;
    },
  },
  extraReducers: {
    [subscriptionOrdersActions.updateSubscriptionOrderSuccess]: updateOrder,
    [subscriptionOrdersActions.skipSubscriptionOrderSuccess]: updateOrder,
    [subscriptionOrdersActions.captureSubscriptionOrderSuccess]: updateOrder,
    [subscriptionOrdersActions.sendSubscriptionOrderSuccess]: updateOrder,
    [productsActions.fetchProductSuccess]: (state, { payload }) => {
      const { orderId, productInOrder } = payload.inResponseTo;
      if (productInOrder) {
        state.productsInOrder[orderId] = {
          ...state.productsInOrder[orderId],
          product_id: payload.data.id,
          price: payload.data.price,
        };
      }
    },
    [shipmentActions.createShipmentSuccess]: (state, { payload }) => {
      const { data } = payload;
      if (state.order?.shipping_info) {
        state.order.shipping_info.shipments[0] = data;
      }
    },
    [shipmentActions.updateShipmentSuccess]: (state, { payload }) => {
      const { data } = payload;
      if (state.order?.shipping_info) {
        state.order.shipping_info.shipments[0] = data;
      }
    },
    [shipmentActions.deleteShipmentSuccess]: state => {
      state.order.shipping_info.shipments = [];
      state.createdOrder.shipping_info.shipments = [];
    },
  },
});

export const actions = actionTypes(ordersSlice.actions);

export default ordersSlice.reducer;
