import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';
import { BRAND_FORM_STEPS } from 'constants/brands';

const brandsSlice = createSlice({
  name: 'brands',
  initialState: {
    isPending: false,
    brands: [],
    brand: {},
    pagination: {},
    step: BRAND_FORM_STEPS.GENERAL,
  },
  reducers: {
    fetchBrands: state => {
      state.isPending = true;
    },
    fetchBrandsSuccess(state, { payload }) {
      state.isPending = false;
      state.brands = payload.data.results;
      state.pagination = payload.data.pagination;
    },
    fetchBrandsFailure: state => {
      state.isPending = false;
    },
    fetchBrand: state => {
      state.isPending = true;
      state.brand = {};
    },
    fetchBrandSuccess(state, { payload }) {
      state.isPending = false;
      state.brand = payload.data;
    },
    fetchBrandFailure: state => {
      state.isPending = false;
    },
    setStep: (state, { payload }) => {
      state.step = payload;
    },
    resetBrand: state => {
      state.brand = {};
    },
    createBrand: state => {
      state.isPending = true;
    },
    createBrandSuccess(state, { payload }) {
      const { data } = payload;
      state.brands = state.brands.concat(data);
      state.brand = data;
      state.isPending = false;
      state.step = BRAND_FORM_STEPS.CONTAINER_WORLD;
    },
    createBrandFailure: state => {
      state.isPending = false;
    },
    deleteBrand: state => {
      state.isPending = true;
    },
    deleteBrandSuccess(state, { payload }) {
      state.brands = state.brands.filter(
        item => item.id !== payload.inResponseTo
      );
      state.isPending = false;
    },
    deleteBrandFailure: state => {
      state.isPending = false;
    },
    updateBrand: state => {
      state.isPending = true;
    },
    updateBrandSuccess(state, { payload }) {
      state.brands = state.brands.map(item => {
        if (item.id === payload.inResponseTo.id) {
          return payload.data;
        }
        return item;
      });
      state.brand = payload.data;
      state.isPending = false;
    },
    updateBrandFailure: state => {
      state.isPending = false;
    },
  },
});

export const actions = actionTypes(brandsSlice.actions);

export default brandsSlice.reducer;
