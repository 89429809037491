import React from 'react';
import ActionDropdown from 'components/ActionDropdown';
import PropTypes from 'prop-types';

const DropdownItemId = Object.freeze({
  PRINT: 'print',
  DOWNLOAD: 'download',
  EDIT: 'edit',
  FULFILL: 'fulfill',
  SENDORDER: 'sendorder',
  SHIP: 'ship',
});

const OrderActionDropdown = ({
  order,
  isSubscriptionOrder,
  onPrintPackageSlip,
  isDisabled,
  onFulfill,
  onSendOrder,
  onDownload,
  onShip,
}) => {
  const isCommercialOrder = order.resourcetype === 'CommercialOrder';
  const isRetailOrder = order.resourcetype === 'RetailOrder';
  const isFulfilled = order.status === 'FULFILLED';
  const isPaidByCard = order.status === 'PAID_BY_CARD';
  const isPendingFulfilled = order.status === 'PENDING_FULFILLMENT';

  const dropdownItem = [
    {
      id: DropdownItemId.EDIT,
      icon: <i className="mdi mdi-pencil font-size-18 mr-2" />,
      label: 'Edit',
      href: `/orders/${order.id}/edit`,
      isHidden: !(isFulfilled && isRetailOrder) || isPendingFulfilled,
    },
    {
      id: DropdownItemId.SHIP,
      icon: <i className="mdi mdi-send mr-2 font-size-18" />,
      label: 'Ship Order',
      isHidden: !isPendingFulfilled,
    },
    {
      id: DropdownItemId.PRINT,
      icon: <i className="mdi mdi-package-variant mr-2 font-size-18" />,
      label: 'Print Packing Slip',
    },
    {
      id: DropdownItemId.FULFILL,
      icon: <i className="mdi mdi-truck-check mr-2 font-size-18" />,
      label: 'Fulfill Order',
      isHidden: !isPendingFulfilled,
    },
    {
      id: DropdownItemId.DOWNLOAD,
      icon: <i className="mdi mdi-cloud-download-outline mr-2 font-size-18" />,
      label: 'Download',
      isHidden: !isCommercialOrder || isPendingFulfilled,
    },
    {
      id: DropdownItemId.SENDORDER,
      label: 'Send Order',
      isHidden: !(isSubscriptionOrder && isPaidByCard) || isPendingFulfilled,
    },
  ];

  const onItemClickHandler = item => {
    const itemHandlers = {
      [DropdownItemId.PRINT]: onPrintPackageSlip,
      [DropdownItemId.FULFILL]: onFulfill,
      [DropdownItemId.SENDORDER]: onSendOrder,
      [DropdownItemId.DOWNLOAD]: onDownload,
      [DropdownItemId.SHIP]: onShip,
    };

    if (item.id in itemHandlers) {
      const handler = itemHandlers[item.id];
      handler?.();
    }
  };

  return (
    <ActionDropdown
      items={dropdownItem}
      onItemClick={onItemClickHandler}
      isDisabled={isDisabled}
    />
  );
};

OrderActionDropdown.propTypes = {
  order: PropTypes.object.isRequired,
  isSubscriptionOrder: PropTypes.bool,
  onPrintPackageSlip: PropTypes.func,
  onSendOrder: PropTypes.func,
  onDownload: PropTypes.func,
  isDisabled: PropTypes.bool,
  onFulfill: PropTypes.func,
  onShip: PropTypes.func,
};

export default OrderActionDropdown;
