/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import { Button, Col, Label, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { AvForm } from 'availity-reactstrap-validation';
import { Calendar } from 'react-date-range';
import LocationSearchInput from '../../LocationSearchInput';
import WineclubsPaginatedField from '../../PaginatedFields/WineclubsPaginatedField';

import styles from './MainForm.scss';
import TextField from '../../Fields/TextField';
import PhoneField from '../../Fields/PhoneField';

const getWineclubsIds = selectedValues =>
  selectedValues.map(({ value }) => value);

const MainForm = ({ onSubmit, model, buttonTitle }) => {
  const [wineclubTouched, setWineclubTouched] = useState(false);
  const [selectedValues, setSelectedValues] = useState(
    (model?.wine_club_members ?? []).map(({ wineclub_id, wineclub_title }) => ({
      value: wineclub_id,
      label: wineclub_title,
    }))
  );
  const [country, setCountry] = useState(model?.customer_profile?.country);
  const [state, setState] = useState(model?.customer_profile?.state);
  const [postalCode, setPostalCode] = useState(
    model?.customer_profile?.postal_code
  );
  const [city, setCity] = useState(model?.customer_profile?.city);
  const [line1, setLine1] = useState(model?.customer_profile?.line1);
  const [line2, setLine2] = useState(model?.customer_profile?.line2);

  const [billingCountry, setBillingCountry] = useState(
    model?.customer_billing_data?.country
  );
  const [billingState, setBillingState] = useState(
    model?.customer_billing_data?.state
  );
  const [billingPostalCode, setBillingPostalCode] = useState(
    model?.customer_billing_data?.postal_code
  );
  const [billingCity, setBillingCity] = useState(
    model?.customer_billing_data?.city
  );
  const [billingLine1, setBillingLine1] = useState(
    model?.customer_billing_data?.line1
  );
  const [billingLine2, setBillingLine2] = useState(
    model?.customer_billing_data?.line2
  );

  const [birthday, setBirthday] = useState(
    model?.birthday ? new Date(model?.birthday) : null
  );
  const [isCalendarOpened, setIsCalendarOpened] = useState(false);

  const [selectError, setSelectError] = useState(false);
  const [email, setEmail] = useState(model?.email || '');

  const handleEmailChange = event => {
    setEmail(event.target.value.toLowerCase());
  };

  const handleSubmit = (event, data) => {
    if (selectedValues.length === 0) {
      setSelectError(true);
      return;
    }
    setSelectError(false);
    onSubmit({
      ...data,
      birthday,
      phone: data.phone || null,
      billing_phone: data.billing_phone || null,
      wine_clubs_ids: getWineclubsIds(selectedValues),
      country,
      state,
      city,
      line1,
      line2,
      postal_code: postalCode,
      billing_country: billingCountry,
      billing_state: billingState,
      billing_city: billingCity,
      billing_line1: billingLine1,
      billing_line2: billingLine2,
      billing_postal_code: billingPostalCode,
    });
  };

  const showSelectValidity = () => {
    setSelectError(selectedValues.length === 0);
  };

  const handleSelectChange = data => {
    setSelectedValues(
      data.map(val => ({
        id: val.value,
        value: val.value,
        label: val.label,
        title: val.label,
      }))
    );
    if (data.length) {
      setSelectError(false);
    } else {
      setSelectError(true);
    }
  };

  return (
    <AvForm
      className="needs-validation"
      onValidSubmit={handleSubmit}
      onInvalidSubmit={showSelectValidity}
      model={model}
    >
      <Row>
        <Col md={3}>
          <Label className={selectError && 'text-danger'}>Wineclub*</Label>
          <WineclubsPaginatedField
            value={selectedValues}
            onChange={handleSelectChange}
            isMulti
            name="wineclub"
            isSearchable
            debounceTimeout={1000}
            onBlur={() => setWineclubTouched(true)}
            classNames={classNames(
              wineclubTouched && selectError && styles.error
            )}
            error={wineclubTouched && selectError && 'Select Wineclub'}
          />
        </Col>
        <Col md={3}>
          <TextField
            isRequired
            name="first_name"
            label="First Name"
            placeholder="First Name"
            errorMessage="Enter First Name"
          />
        </Col>
        <Col md={3}>
          <TextField
            isRequired
            name="last_name"
            label="Last Name"
            placeholder="Last Name"
            errorMessage="Enter Last Name"
          />
        </Col>
        <Col md={3}>
          <TextField
            isRequired
            name="email"
            label="Email"
            placeholder="Email"
            validate={{
              required: { value: true, errorMessage: 'Enter Email' },
              email: { value: true, errorMessage: 'Invalid Email' },
            }}
            value={email}
            onChange={handleEmailChange}
          />
        </Col>
      </Row>
      <Row className={styles.calendarContainer}>
        <Col className="col-md-3">
          <label>Birthday</label>
          <div
            className="form-control"
            onClick={() => setIsCalendarOpened(!isCalendarOpened)}
          >
            {birthday || ''}
          </div>
        </Col>
        {isCalendarOpened && (
          <Calendar
            className={styles.calendar}
            date={birthday ?? new Date()}
            maxDate={new Date()}
            onChange={e => {
              setBirthday(e);
              setIsCalendarOpened(false);
            }}
          />
        )}
      </Row>
      <Row>
        <Col md={9}>
          <LocationSearchInput
            title="Shipping Address"
            model={model?.customer_profile}
            country={country}
            state={state}
            city={city}
            postalCode={postalCode}
            line1={line1}
            line2={line2}
            setCountry={setCountry}
            setState={setState}
            setPostalCode={setPostalCode}
            setCity={setCity}
            setLine1={setLine1}
            setLine2={setLine2}
          />
        </Col>
        <Col md={3}>
          <PhoneField name="phone" label="Shipping Phone" placeholder="Phone" />
        </Col>
      </Row>
      <Row>
        <Col md={9}>
          <LocationSearchInput
            title="Billing Address"
            model={model?.customer_billing_data}
            country={billingCountry}
            state={billingState}
            city={billingCity}
            postalCode={billingPostalCode}
            line1={billingLine1}
            line2={billingLine2}
            setCountry={setBillingCountry}
            setState={setBillingState}
            setPostalCode={setBillingPostalCode}
            setCity={setBillingCity}
            setLine1={setBillingLine1}
            setLine2={setBillingLine2}
          />
        </Col>
        <Col md={3}>
          <PhoneField
            name="billing_phone"
            label="Billing Phone"
            placeholder="Billing Phone"
          />
        </Col>
      </Row>
      <Button color="primary" type="submit">
        {buttonTitle}
      </Button>
    </AvForm>
  );
};

MainForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  model: PropTypes.object,
  buttonTitle: PropTypes.string.isRequired,
};
export default MainForm;
