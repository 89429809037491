import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { actions as customersActions } from 'models/customers/slice';

import { Elements } from '@stripe/react-stripe-js';

import Form from './Form';
import { loadStripe } from '@stripe/stripe-js';
import {
  intentSelector,
  paymentMethodsSelector,
} from 'models/customers/selectors';
import isEmpty from 'lodash/isEmpty';
import PaymentMethodsTable from 'components/PaymentMethodsTable';

const Payment = ({
  stripeToken,
  stripeAccount,
  currentCustomerId,
  currentBrand,
  setCurrentCard,
  currentCard,
  cardOptions,
  setCardOptions,
  withCardChoice,
  withPaymentMethodsTable,
  onSuccess,
  actionNode,
  isFormHidden,
}) => {
  const stripePromise = stripeAccount
    ? loadStripe(stripeToken, { stripeAccount })
    : loadStripe(stripeToken);
  const fetchPaymentMethods = useAction(customersActions.fetchPaymentMethods);
  const removePaymentMethod = useAction(customersActions.removePaymentMethod);
  const setupIntent = useAction(customersActions.setupIntent);
  const intent = useSelector(intentSelector);
  const paymentMethods = useSelector(paymentMethodsSelector);

  useEffect(() => {
    setupIntent({
      customer_id: currentCustomerId,
      brand_id: currentBrand.id,
    });
  }, [currentCustomerId, currentBrand, setupIntent]);

  useEffect(() => {
    if (currentCustomerId) fetchPaymentMethods(currentCustomerId);
  }, [fetchPaymentMethods, currentCustomerId]);

  useEffect(() => {
    if (setCardOptions) {
      setCardOptions(
        paymentMethods.filter(method => method?.brand_id === currentBrand?.id)
      );
    }
  }, [setCardOptions, paymentMethods, currentBrand]);

  const onSuccessSubmit = () => {
    setupIntent({
      customer_id: currentCustomerId,
      brand_id: currentBrand.id,
    });
    if (currentCustomerId) fetchPaymentMethods(currentCustomerId);
    onSuccess?.();
  };

  const onRemovePaymentMethod = methodId => {
    removePaymentMethod({ id: methodId, customerId: currentCustomerId });
  };

  // const handleSubmit = async e => {
  //   e.preventDefault();

  //   if (!stripe || !elements) {
  //     // Stripe.js has not yet loaded.
  //     // Make sure to disable form submission until Stripe.js has loaded.
  //     return;
  //   }

  //   setIsLoading(true);

  //   const { error } = await stripe.confirmPayment({
  //     elements,
  //     confirmParams: {
  //       return_url: 'http://localhost:3000',
  //     },
  //   });

  //   // This point will only be reached if there is an immediate error when
  //   // confirming the payment. Otherwise, your customer will be redirected to
  //   // your `return_url`. For some payment methods like iDEAL, your customer will
  //   // be redirected to an intermediate site first to authorize the payment, then
  //   // redirected to the `return_url`.
  //   if (error.type === 'card_error' || error.type === 'validation_error') {
  //     setMessage(error.message);
  //   } else {
  //     setMessage('An unexpected error occured.');
  //   }

  //   setIsLoading(false);
  // };

  return (
    <>
      <Elements stripe={stripePromise}>
        <>
          {!isFormHidden && (
            <Form
              setCurrentCard={setCurrentCard}
              intent={intent}
              cardOptions={cardOptions}
              setCardOptions={setCardOptions}
              brand={currentBrand}
              currentCard={currentCard}
              currentCustomerId={currentCustomerId}
              withCardChoice={withCardChoice}
              onSuccessSubmit={onSuccessSubmit}
            />
          )}
          {withPaymentMethodsTable && !isEmpty(paymentMethods) && (
            <PaymentMethodsTable
              paymentMethods={paymentMethods.filter(
                method => method?.brand_id === currentBrand.id
              )}
              onRemovePaymentMethod={onRemovePaymentMethod}
              actionNode={actionNode}
            />
          )}
        </>
      </Elements>
    </>
  );
};

Payment.propTypes = {
  stripeToken: PropTypes.string,
  stripeAccount: PropTypes.string,
  currentCustomerId: PropTypes.number,
  currentBrand: PropTypes.object,
  setCurrentCard: PropTypes.func,
  cardOptions: PropTypes.array,
  currentCard: PropTypes.object,
  setCardOptions: PropTypes.func,
  withCardChoice: PropTypes.bool,
  isFormHidden: PropTypes.bool,
  withPaymentMethodsTable: PropTypes.bool,
  onSuccess: PropTypes.func,
  actionNode: PropTypes.node,
};

export default Payment;
