import React from 'react';
import { Badge, Card, CardBody, Col, Row, Table } from 'reactstrap';
import PropTypes from 'prop-types';
import PaymentDropdown from '../PaymentDropdown';

const PaymentTypesTable = ({
  paymentTypes,
  onMakeDefault,
  onDelete,
  isActionVisible,
  children,
}) => {
  return (
    <Row className="mt-2">
      <Col md={12}>
        <Card>
          <CardBody>
            <h4 className="card-title mt-1 mb-3">Payment Types</h4>
            <Table bordered>
              <thead>
                <tr>
                  <th data-priority="1">Type</th>
                  <th data-priority="1">Brand</th>
                  {isActionVisible && <th data-priority="1">Action</th>}
                </tr>
              </thead>
              <tbody>
                {paymentTypes.map(paymentType => (
                  <tr key={paymentType.id}>
                    <td>
                      {paymentType?.type ?? '-'}
                      {paymentType?.is_default && (
                        <Badge className="ml-1 align-middle" color="info">
                          Default
                        </Badge>
                      )}
                    </td>
                    <td>{paymentType?.brand_title ?? '-'}</td>
                    {isActionVisible && (
                      <td>
                        <PaymentDropdown
                          onMakeDefault={() => onMakeDefault?.(paymentType)}
                          onDelete={() => onDelete?.(paymentType)}
                        />
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
            {children}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

PaymentTypesTable.propTypes = {
  paymentTypes: PropTypes.array.isRequired,
  onDelete: PropTypes.func,
  onMakeDefault: PropTypes.func,
  isActionVisible: PropTypes.bool,
  children: PropTypes.node,
};
export default PaymentTypesTable;
