import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import formatPrice from 'utils/formatPrice';
import {
  ORDER_TYPES_ITEMS,
  HUMAN_ORDER_STATUSES,
  SHIPPING_STATUSES,
} from 'constants';
import { SHIPMENT_STATUSES } from 'constants/shipment';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';

import CardItem from 'components/CardItem';
import { prepareAddress } from 'utils/prepareAddress';
import { formatDate } from 'utils/formatDate';

import styles from './Header.scss';

const statusesForShipment = [
  'FULFILLED',
  'WAITING_FOR_APPROVAL',
  'PAYMENT_ERROR',
  'CUSTOMER_APPROVED',
  'MANAGER_APPROVED',
];

const finalShipmentStatuses = [
  SHIPMENT_STATUSES.DELIVERED,
  SHIPMENT_STATUSES.RETURNED,
];

const ordersWithoutCustomer = [
  'CommercialOrder',
  'EcommerceOrder',
  'ContainerWorldOrder',
];

const KeyValueText = ({ key, value }) => {
  return (
    <div className="d-flex justify-content-between align-items-center">
      <span>{key}</span>
      <span>{value}</span>
    </div>
  );
};

const Header = ({
  order,
  onReportDownload,
  onLabelDownload,
  reportDisabled,
  onShipmentEditClick,
  onShipmentSelectClick,
  orderShipment,
  onUpdateShipping,
  updatePending,
}) => {
  const getHostUserName = host => {
    const withName = host.first_name || host.last_name;
    return withName
      ? `${withName && `${host.first_name || ''} ${host.last_name || ''}`} (${
          host.email
        })`
      : host.email;
  };

  const isSubscriptionOrder = order?.resourcetype === 'SubscriptionOrder';

  let discountValue = '-';
  if (order?.discount_percentage || order?.discount_amount) {
    discountValue = order?.discount_percentage
      ? `${order?.discount_percentage}%`
      : `${formatPrice(order?.discount_amount, order?.total_price_currency)}`;
  }

  return (
    <>
      <Row>
        <Col lg="3">
          <CardItem title="Invoice Number" value={order?.invoice_number} />
        </Col>
      </Row>
      <Row>
        <Col lg="3">
          <CardItem
            title="Transaction Total"
            value={`${formatPrice(
              order?.total_price,
              order?.total_price_currency
            )}`}
          />
        </Col>
        {order?.resourcetype !== 'RetailOrder' && (
          <Col lg="3">
            <CardItem
              title="Tip Total"
              value={
                order?.is_tips_refunded
                  ? `${formatPrice(
                      order?.tips_amount,
                      order?.total_price_currency
                    )} (Refunded)`
                  : `${formatPrice(
                      order?.tips_amount,
                      order?.total_price_currency
                    )}`
              }
            />
          </Col>
        )}
        {order?.applied_fees &&
          Object.entries(order.applied_fees)?.map(([key, value]) => (
            <Col lg="3">
              <CardItem
                title={`${key} Total`}
                value={`${formatPrice(value, order?.total_price_currency)}`}
              />
            </Col>
          ))}
        <Col lg="3">
          <CardItem
            title="Tax Total"
            value={`${formatPrice(order?.fee, order?.total_price_currency)}`}
          />
        </Col>
        <Col lg="3">
          <CardItem
            title="Discount Total"
            value={`${formatPrice(
              order?.total_order_discount,
              order?.total_price_currency
            )}`}
          />
        </Col>
        <Col lg="3">
          <CardItem title="Order Discount" value={discountValue} />
        </Col>

        <Col lg="3">
          <CardItem
            title="Discount"
            value={
              isEmpty(order?.discount_code) ? (
                '-'
              ) : (
                <Link to={`/discount-codes/${order?.discount_code?.id}/show`}>
                  {order?.discount_code?.title} {order?.discount_code?.coupon}
                </Link>
              )
            }
          />
        </Col>
        {order?.comment && (
          <Col lg={3}>
            <CardItem title="Comment" value={order.comment} />
          </Col>
        )}

        {order?.expected_payment_type && (
          <Col lg="3">
            <CardItem
              title="Expected Payment Type"
              value={order?.expected_payment_type}
            />
          </Col>
        )}
        {order?.status?.match('Paid') && (
          <Col lg="3">
            <CardItem title="Payment Type" value={order?.status} />
          </Col>
        )}
        <Col lg="3">
          <CardItem title="Brand" value={order?.brand?.title} />
        </Col>
        <Col lg="3">
          <CardItem title="Store" value={order?.warehouse?.title} />
        </Col>

        {order.host_user && (
          <Col lg="3">
            <CardItem
              title="Wine Associate"
              value={getHostUserName(order.host_user)}
            />
          </Col>
        )}
        <Col lg="3">
          <CardItem
            title="Order Type"
            value={ORDER_TYPES_ITEMS[order?.resourcetype]}
          />
        </Col>
        <Col lg="3">
          <CardItem
            title="Order Status"
            value={HUMAN_ORDER_STATUSES[order?.status]}
          />
        </Col>
        {order?.resourcetype === 'CommercialOrder' && (
          <Col lg="3">
            <CardItem
              title="Comment"
              value={
                order?.status_change_comments?.length
                  ? order.status_change_comments[0]
                  : '-'
              }
            />
          </Col>
        )}
        {!ordersWithoutCustomer.includes(order.resourcetype) && (
          <Col lg="3">
            {isEmpty(order?.customer) ? (
              <CardItem
                title="Customer"
                value={
                  order.customer_name
                    ? `${order.customer_name} (Unregistered)`
                    : '-'
                }
              />
            ) : (
              <CardItem
                title="Customer"
                value={
                  <Link to={`/wineclub-customers/${order?.customer?.id}`}>
                    <span>
                      {order?.customer?.first_name} {order?.customer?.last_name}
                      <br />
                      {order?.customer?.email}
                    </span>
                  </Link>
                }
              />
            )}
          </Col>
        )}
        {!!order?.created_at && (
          <Col lg="3">
            <CardItem
              title="Created at"
              value={formatDate(order?.created_at)}
            />
          </Col>
        )}
        {!!order?.paid_at && (
          <Col lg="3">
            <CardItem title="Paid at" value={formatDate(order?.paid_at)} />
          </Col>
        )}
      </Row>
      {order?.resourcetype === 'EcommerceOrder' && (
        <Row className="mt-3">
          <Col className="mb-2" md={12}>
            <h5>Customer Info</h5>
          </Col>
          <Col lg="3">
            <CardItem
              title="Customer"
              value={
                <>
                  <p>{`${order?.customer_first_name} ${order?.customer_last_name}`}</p>
                  <p>{order?.customer_email}</p>
                </>
              }
            />
          </Col>
          <Col lg="3">
            <CardItem
              title="Customer phone"
              value={order?.customer_phone || order?.phone}
            />
          </Col>
        </Row>
      )}
      {order?.resourcetype === 'CommercialOrder' && (
        <Row className="mt-3">
          <Col className="mb-2" md={12}>
            <h5 className="">Commercial Customer</h5>
          </Col>
          <Col md={3}>
            <CardItem title="Title" value={order?.organization?.title} />
          </Col>
          <Col md={3}>
            <CardItem
              title="Contact Person"
              value={
                <div>
                  <p className="m-0">
                    {order?.organization?.contact_person?.first_name}{' '}
                    {order?.organization?.contact_person?.last_name}
                  </p>
                  <p className="m-0">
                    {order?.organization?.contact_person?.email}
                  </p>
                </div>
              }
            />
          </Col>
          <Col md={3}>
            <CardItem
              title="Contact Person Phone"
              value={order?.organization?.contact_person?.phone}
            />
          </Col>
          <Col md={3}>
            <CardItem
              title="License Number"
              value={order?.organization?.license_number}
            />
          </Col>
          <Col md={3}>
            <CardItem
              title="Company Type"
              value={order?.organization?.company_type}
            />
          </Col>
          <Col md={6}>
            <CardItem
              title="Address"
              value={prepareAddress(order?.organization?.address)}
            />
          </Col>
        </Row>
      )}
      {order?.resourcetype === 'ContainerWorldOrder' && (
        <Row className="mt-3">
          <Col className="mb-2" md={12}>
            <h5>ContainerWorld Info</h5>
          </Col>
          <Col lg="3">
            <CardItem title="Order ID" value={order.container_world_order_id} />
          </Col>
          <Col lg="3">
            <CardItem title="Customer Type" value={order?.customer_type} />
          </Col>
          <Col lg="3">
            <CardItem title="Customer ID" value={order?.customer_number} />
          </Col>
        </Row>
      )}
      {Boolean(order?.shipping_info) && (
        <Row className="mt-3">
          <Col className="mb-2" md={12}>
            <h5>Shipping Info</h5>
          </Col>
          <Col className="mb-4" md={12}>
            {!!orderShipment?.id && orderShipment?.type !== 'Easypost' && (
              <Button
                className="mr-2"
                color="primary"
                onClick={onShipmentEditClick}
              >
                Edit Shipment
              </Button>
            )}
            {isSubscriptionOrder &&
              statusesForShipment.includes(order?.status) && (
                <Button
                  color="success"
                  className="waves-effect waves-light text-nowrap mr-2"
                  onClick={onShipmentSelectClick}
                >
                  Select Shipment
                </Button>
              )}
            {orderShipment?.type === 'Easypost' &&
              !!orderShipment?.tracking_number &&
              !finalShipmentStatuses.includes(orderShipment?.status) && (
                <Button
                  style={{ backgroundColor: '#ff7e03', borderColor: '#ff7e03' }}
                  className="waves-effect waves-light text-nowrap mr-2"
                  onClick={onUpdateShipping}
                  disabled={updatePending}
                >
                  Update Shipment Status
                </Button>
              )}
          </Col>
          {orderShipment?.type === 'Manual' && (
            <>
              <Col lg="3">
                <CardItem
                  title="Shipping company"
                  value={orderShipment?.brand_shipping_company_title}
                />
              </Col>
            </>
          )}
          {!orderShipment && (
            <Col lg="3">
              <CardItem title="Shipping" value="Pickup" />
            </Col>
          )}
          <Col lg="3">
            <CardItem
              title="Shipping price"
              value={
                orderShipment
                  ? `${formatPrice(
                      Number(orderShipment?.price),
                      order?.total_price_currency
                    )} ${orderShipment?.free_shipping ? '(Free shipping)' : ''}`
                  : '0.00 CAD'
              }
            />
          </Col>
          <Col lg="3">
            <CardItem
              title="Shipping address"
              value={prepareAddress(order?.shipping_info)}
            />
          </Col>
          <Col lg="3">
            <CardItem
              title="Shipping email"
              value={order?.shipping_info?.email}
            />
          </Col>
          <Col lg="3">
            <CardItem
              title="Shipping phone"
              value={order?.shipping_info?.phone}
            />
          </Col>
          {!orderShipment?.parcels?.length ? (
            <>
              <Col lg="3">
                <CardItem
                  title="Tracking Number"
                  value={
                    <div className="mt-4">
                      {orderShipment?.tracking_number ?? '-'}
                    </div>
                  }
                />
              </Col>
              <Col lg="3">
                <CardItem
                  title="Status"
                  value={
                    <div className="mt-4">
                      {orderShipment?.status
                        ? SHIPPING_STATUSES[orderShipment?.status]
                        : '-'}
                    </div>
                  }
                />
              </Col>
              <Col lg="3">
                <CardItem
                  title="Label"
                  value={
                    <div className="mt-4">
                      {orderShipment?.label_url ? (
                        <button
                          className={styles.downloadButton}
                          onClick={onLabelDownload}
                        >
                          Download shipping label
                        </button>
                      ) : (
                        '-'
                      )}
                    </div>
                  }
                />
              </Col>
              <Col lg="3">
                <CardItem
                  title="Public Url"
                  value={
                    <div className="mt-4">
                      {orderShipment?.public_url ? (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={orderShipment?.public_url}
                        >
                          {orderShipment?.public_url}
                        </a>
                      ) : (
                        '-'
                      )}
                    </div>
                  }
                />
              </Col>
            </>
          ) : (
            <>
              <Col lg="3">
                <CardItem
                  title="Shipping status"
                  value={
                    <div className="mt-4">
                      {SHIPPING_STATUSES[orderShipment.status] ?? '-'}
                    </div>
                  }
                />
              </Col>
              {orderShipment.parcels.map((parcel, index) => (
                <>
                  <Col md="12" className="mb-2">
                    <strong>Parcel #{index + 1}</strong>
                  </Col>
                  <Col lg="3">
                    <CardItem
                      title="Tracking Number"
                      value={
                        <div className="mt-4">
                          {parcel.tracking_number ?? '-'}
                        </div>
                      }
                    />
                  </Col>
                  <Col lg="3">
                    <CardItem
                      title="Status"
                      value={
                        <div className="mt-4">
                          {parcel.status
                            ? SHIPPING_STATUSES[parcel.status]
                            : '-'}
                        </div>
                      }
                    />
                  </Col>
                  <Col lg="3">
                    <CardItem
                      title="Label Url"
                      value={
                        <div className="mt-4">
                          {parcel.label_url ? (
                            <button
                              className={styles.downloadButton}
                              onClick={() => onLabelDownload(parcel.id)}
                            >
                              Download shipping label
                            </button>
                          ) : (
                            '-'
                          )}
                        </div>
                      }
                    />
                  </Col>
                  <Col lg="3">
                    <CardItem
                      title="Public Url"
                      value={
                        <div className="mt-4">
                          {parcel.public_url ? (
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={parcel.public_url}
                            >
                              {parcel.public_url}
                            </a>
                          ) : (
                            '-'
                          )}
                        </div>
                      }
                    />
                  </Col>
                </>
              ))}
            </>
          )}
        </Row>
      )}
      {order?.obligation && order?.resourcetype === 'SubscriptionOrder' && (
        <Row className="mt-3">
          <Col className="mb-2" md={12}>
            <h5>Subscription Info</h5>
          </Col>
          <Col md={3}>
            <Link to={`/obligation/${order?.obligation?.id}`}>
              <CardItem
                title="Subscription name"
                value={order?.obligation?.tier?.title}
              />
            </Link>
          </Col>
          <Col md={3}>
            <CardItem
              title="Subscription period"
              value={order?.obligation?.tier?.period_in_months}
            />
          </Col>
          <Col md={3}>
            <CardItem
              title="Subscription capacity"
              value={order?.obligation?.tier?.capacity}
            />
          </Col>
          {order?.obligation?.is_gift && (
            <Col md={3}>
              <CardItem
                title="Gift Note"
                value={order?.obligation?.gift_note}
              />
            </Col>
          )}
        </Row>
      )}
      {order?.resourcetype === 'CommercialOrder' && (
        <Row className="mb-4">
          <Col md={3}>
            <Button
              disabled={reportDisabled}
              onClick={onReportDownload}
              color="primary"
            >
              Download Invoice
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

CardItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
};

KeyValueText.propTypes = {
  key: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

Header.propTypes = {
  order: PropTypes.object,
  orderShipment: PropTypes.object,
  onReportDownload: PropTypes.func,
  onLabelDownload: PropTypes.func,
  reportDisabled: PropTypes.bool,
  onShipmentEditClick: PropTypes.func,
  onShipmentSelectClick: PropTypes.func,
  onUpdateShipping: PropTypes.func,
  updatePending: PropTypes.bool,
};

export default Header;
