import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody } from 'reactstrap';
import PageWrapper from 'components/PageWrapper';
import Breadcrumbs from 'components/Breadcrumbs';
import Filter from './Filter';
import DataTable from './DataTable';
import PaginationTable from 'components/PaginationTable';

import useQuery from 'hooks/useQuery';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { actions as commissionsActions } from 'models/commissions/slice';
import {
  commissionsSelector,
  paginationSelector,
  isPendingSelector,
} from 'models/commissions/selectors';
import {
  hasOwnerOrManagerRoleSelector,
  hasBookkeeperRoleSelector,
} from 'models/user/selectors';

const CommissionsSummary = ({ title }) => {
  const query = useQuery();
  const fetchCommissions = useAction(commissionsActions.fetchCommissions);

  const commissions = useSelector(commissionsSelector);
  const pagination = useSelector(paginationSelector);
  const isPending = useSelector(isPendingSelector);
  const isOwnerOrManagerRole = useSelector(hasOwnerOrManagerRoleSelector);
  const hasBookkeperRole = useSelector(hasBookkeeperRoleSelector);

  const [filters, setFilters] = useState({});
  const [currentPage, setCurrentPage] = useState(
    query.get('page') || pagination?.current_page
  );
  const [sortField, setSortField] = useState(
    query.get('sort_field') || 'created_at'
  );
  const [sortDirection, setSortDirection] = useState(
    query.get('sort_direction') || 'desc'
  );

  useEffect(() => {
    fetchCommissions({
      ...filters,
      sort_field: sortField,
      sort_direction: sortDirection,
      page: currentPage,
    });
  }, [filters, currentPage, sortField, sortDirection]);

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Filter
                onFiltersChange={setFilters}
                isExpanded={isOwnerOrManagerRole || hasBookkeperRole}
              />
              <PaginationTable
                pagination={pagination}
                setCurrentPage={setCurrentPage}
              >
                <DataTable
                  sortField={sortField}
                  setSortField={setSortField}
                  sortDirection={sortDirection}
                  setSortDirection={setSortDirection}
                  isPending={isPending}
                  data={commissions}
                />
              </PaginationTable>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageWrapper>
  );
};

CommissionsSummary.propTypes = {
  title: PropTypes.string,
};

export default CommissionsSummary;
