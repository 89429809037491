import React from 'react';
import PropTypes from 'prop-types';
import useSelector from 'hooks/useSelector';
import {
  isAuthSelector,
  isOnlyHostSelector,
  isOnlyBookkeeperSelector,
  rolesSelector,
  settingsSelector,
} from 'models/user/selectors';
import { Redirect, Route } from 'react-router-dom';
import { hasPermissions, hasSettings } from 'utils/hasPermissions';
import { PERMISSION_TYPES } from 'constants/permissions';

const ProtectedRoute = ({
  path,
  permissions,
  workspace_settings,
  ...props
}) => {
  const isAuthenticated = useSelector(isAuthSelector);
  const roles = useSelector(rolesSelector);
  const onlyHost = useSelector(isOnlyHostSelector);
  const onlyBookkeeper = useSelector(isOnlyBookkeeperSelector);
  const settings = useSelector(settingsSelector);

  const isAuthRoute = [
    '/login',
    '/register',
    '/user-confirmation/:token',
  ].includes(path);

  const isRecoverRoute = [
    '/recover-password',
    '/reset-password/:token',
  ].includes(path);

  if (path === '/' && roles.length > 0) {
    let redirectUrl = '';
    if (onlyHost) {
      redirectUrl = '/inventory-count/';
    } else if (onlyBookkeeper) {
      redirectUrl = '/reports/export';
    } else {
      redirectUrl = '/products/';
    }

    if (roles.length === 1 && roles.includes(PERMISSION_TYPES.SALES_REP)) {
      redirectUrl = '/commercial-orders/create';
    }
    return <Redirect to={redirectUrl} from="/" />;
  }

  if (isAuthenticated && (isAuthRoute || isRecoverRoute)) {
    return <Redirect from={path} to="/" {...props} />;
  }

  if (!isAuthenticated && !isAuthRoute && !isRecoverRoute) {
    return <Redirect from={path} to="/login" {...props} />;
  }

  if (
    !isAuthRoute &&
    workspace_settings?.length > 0 &&
    !hasSettings(workspace_settings, settings)
  ) {
    return <Redirect from={path} to="/not-found" {...props} />;
  }

  if (
    !isAuthRoute &&
    roles.length > 0 &&
    !hasPermissions(permissions, roles) &&
    isAuthenticated
  ) {
    return <Redirect from={path} to="/not-found" {...props} />;
  }

  return <Route path={path} {...props} />;
};

ProtectedRoute.propTypes = {
  path: PropTypes.string.isRequired,
  permissions: PropTypes.array,
  workspace_settings: PropTypes.array,
};

export default ProtectedRoute;
