import { takeLatest, all, select } from 'redux-saga/effects';
import api from 'api';
import { actions } from './slice';
import { currentWorkspaceSelector } from 'models/workspaces/selectors';
import { generateURL } from 'utils/generateURL';

export function* fetchBrandsSaga(action) {
  const { url } = yield generateURL({
    payload: action.payload,
    url: '/brands/',
    setQuerty: action.payload.set_querty,
  });

  yield api({
    action,
    method: 'get',
    url,
  });
}
export function* fetchBrandSaga(action) {
  yield api({
    action,
    method: 'get',
    url: `/brands/${action.payload}`,
  });
}

export function* createBrandSaga(action) {
  const currentWorkspace = yield select(currentWorkspaceSelector);
  const { payload } = action;
  payload.workspace_id = currentWorkspace;

  yield api({
    action,
    method: 'post',
    url: `/brands/`,
    data: payload,
    successMessage: 'Successfully created new brand!',
  });
}

export function* updateBrandSaga(action) {
  const formData = new FormData();
  const currentWorkspace = yield select(currentWorkspaceSelector);
  const { payload } = action;

  Object.keys(payload).forEach(key => {
    formData.append(key, payload[key] === null ? '' : payload[key]);
  });
  formData.append('workspace_id', currentWorkspace);

  yield api({
    action,
    method: 'patch',
    url: `/brands/${payload.id}`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
    successMessage: 'Successfully updated brand!',
    successNavigateTo: payload?.redirectUrl,
  });
}

export function* deleteBrandSaga(action) {
  yield api({
    action,
    method: 'DELETE',
    url: `/brands/${action.payload}`,
    successMessage: 'Successfully deleted brand!',
  });
}

export default function*() {
  yield all([
    takeLatest(actions.fetchBrands, fetchBrandsSaga),
    takeLatest(actions.fetchBrand, fetchBrandSaga),
    takeLatest(actions.createBrand, createBrandSaga),
    takeLatest(actions.deleteBrand, deleteBrandSaga),
    takeLatest(actions.updateBrand, updateBrandSaga),
  ]);
}
