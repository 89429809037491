import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  FormGroup,
  Label,
  Button,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import { projects } from 'constants/projects';
import TextField from '../Fields/TextField';
import NumberField from '../Fields/NumberField';
import SelectField from '../SelectField/SelectField';
import BrandsPaginatedField from '../PaginatedFields/BrandsPaginatedField';

import { formatDate } from 'utils/formatDate';
import { getEndDate, getStartDate } from 'utils/date';
import DateRangePicker from 'components/DateRangePicker';

import WarehousesPaginatedField from 'components/PaginatedFields/WarehousesPaginatedField';
import { useIncludedExcludedCategoriesOptions } from 'hooks/useIncludedExcludedCategoriesOptions';
import { useSelector } from 'react-redux';
import {
  foodCategoriesSelector,
  merchandiseCategoriesSelector,
  wineCategoriesSelector,
} from 'models/products/selectors';
import useAction from 'hooks/useAction';
import { actions as productsActions } from 'models/products/slice';
import ProductsPaginatedField from 'components/PaginatedFields/ProductsPaginatedField';
import TextareaField from 'components/Fields/TextareaField/TextareaField';
import { DISCOUNT_TYPES } from 'constants/discounts';

const getDate = date => {
  if (date) {
    return formatDate(new Date(date), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
  }
  return undefined;
};

const DiscountForm = ({ model, onSubmit, disabled }) => {
  const [project, setProject] = useState(projects[0]);
  const [brandTouched, setBrandTouched] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedWarehouses, setSelectedWarehouses] = useState(
    model?.warehouses.length > 0
      ? model?.warehouses
      : [
          {
            label: 'All warehouses',
            value: '',
          },
        ]
  );

  const getInitialDiscountType = () => {
    if (model && model.discount_method) {
      const type = model.discount_method ? model.discount_method : 'PERCENTAGE';
      const matchedType = DISCOUNT_TYPES.find(d => d.value === type);
      return matchedType || DISCOUNT_TYPES[0];
    }
    return DISCOUNT_TYPES[0];
  };
  const [discountType, setDiscountType] = useState(getInitialDiscountType);
  const [selectedIncludedSkus, setSelectedIncludedSkus] = useState(
    model?.included_skus ?? []
  );
  const [selectedExcludedSkus, setSelectedExcludedSkus] = useState(
    model?.excluded_skus ?? []
  );

  const [dateRange, setDateRange] = useState({
    startDate: Date.parse(model?.promotion_start_date),
    endDate: Date.parse(model?.promotion_end_date),
  });
  const fetchCategories = useAction(productsActions.fetchProductsCategories);
  const foodCategories = useSelector(foodCategoriesSelector);
  const merchandiseCategories = useSelector(merchandiseCategoriesSelector);
  const wineCategories = useSelector(wineCategoriesSelector);
  useEffect(() => {
    if (!foodCategories || !merchandiseCategories || !wineCategories) {
      fetchCategories();
    }
  }, []);

  const availableCategories = useMemo(() => {
    const allCategories = {
      ...(foodCategories || {}),
      ...(wineCategories || {}),
      ...(merchandiseCategories || {}),
    };
    if (allCategories) {
      return Object.values(allCategories)?.filter(
        category =>
          category.brand_id === selectedBrand?.value ||
          category.brand_id === null
      );
    }
    return [];
  }, [wineCategories, foodCategories, merchandiseCategories, selectedBrand]);

  const {
    selectedIncludedCategories,
    selectedExcludedCategories,
    onIncludedCategoriesChange,
    onExcludedCategoriesChange,
  } = useIncludedExcludedCategoriesOptions({
    defaultValues: {
      includedCategories: model?.included_categories ?? [],
      excludedCategories: model?.excluded_categories ?? [],
    },
  });

  useEffect(() => {
    if (model) {
      setSelectedBrand({
        value: model.brand.id,
        label: model.brand.title,
      });
      setProject(
        projects?.find(
          _project => _project?.value === model?.redeemable_location
        ) || projects[0]
      );
      setDateRange({
        startDate: Date.parse(model?.promotion_start_date),
        endDate: Date.parse(model?.promotion_end_date),
      });
    }
  }, [model]);

  const handleDateChange = value => {
    setDateRange({
      startDate: getStartDate(value.startDate),
      endDate: getEndDate(value.endDate),
    });
  };

  const handleSubmit = useCallback(
    (_, values) => {
      setBrandTouched(true);
      if (!selectedBrand) return;
      onSubmit({
        ...values,
        brand_id: selectedBrand?.value,
        redeemable_location: project?.value,
        discount_method: discountType.value,
        promotion_start_date: getDate(dateRange?.startDate),
        promotion_end_date: getDate(dateRange?.endDate),
        warehouses:
          selectedWarehouses.length === 1 && selectedWarehouses[0].value === ''
            ? []
            : selectedWarehouses,
        included_categories: selectedIncludedCategories,
        excluded_categories: selectedExcludedCategories,
        included_skus: selectedIncludedSkus,
        excluded_skus: selectedExcludedSkus,
      });
    },
    [
      onSubmit,
      selectedBrand,
      project,
      dateRange,
      selectedWarehouses,
      selectedIncludedCategories,
      selectedExcludedCategories,
      selectedIncludedSkus,
      selectedExcludedSkus,
      discountType,
    ]
  );
  const handleWarehousesChange = warehouses => {
    if (!warehouses || warehouses.length === 0) {
      setSelectedWarehouses([
        {
          label: 'All warehouses',
          value: '',
        },
      ]);
      return;
    }

    if (warehouses.length > 0 && warehouses[0].value === '') {
      warehouses.shift();
    }

    setSelectedWarehouses(warehouses);
  };

  const handleInvalidSubmit = () => {
    setBrandTouched(true);
  };

  return (
    <AvForm
      model={model || { use_once: true }}
      className="needs-validation"
      onValidSubmit={handleSubmit}
      onInvalidSubmit={handleInvalidSubmit}
    >
      <Row>
        <Col md="4">
          <TextField
            label="Title"
            name="title"
            placeholder="Title"
            isRequired
          />
        </Col>
        <Col md="4">
          <FormGroup>
            <Label
              className={brandTouched && !selectedBrand && 'text-danger'}
              required
              htmlFor="brand_id"
            >
              Brand
            </Label>
            <BrandsPaginatedField
              value={selectedBrand}
              onChange={setSelectedBrand}
              isMulti={false}
              onBlur={() => setBrandTouched(true)}
              error={brandTouched && !selectedBrand && 'This field is invalid'}
              isDisabled={model?.brand.id}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label htmlFor="warehouse">Warehouses</Label>
            <WarehousesPaginatedField
              value={selectedWarehouses}
              parameters={{ brand_id: selectedBrand?.value }}
              isMulti
              isInitialLabel
              onChange={option => handleWarehousesChange(option)}
            />
          </FormGroup>
        </Col>
        <Col lg="4">
          <FormGroup>
            <TextareaField
              name="description"
              label="Description"
              value={model?.description ?? ''}
              rows={2}
              maxLength={20}
              isResizeDisabled
            />
          </FormGroup>
        </Col>

        {selectedBrand?.value && (
          <>
            <Col lg={12}>
              <Nav tabs className="nav-tabs-simple mb-4">
                <NavItem>
                  <NavLink className="font-weight-bold p-3 active">
                    Settings
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col lg="4">
              <SelectField
                className="mb-2"
                name="discount_type"
                defaultValue={
                  model?.discount_type ? model.discount_type : DISCOUNT_TYPES[0]
                }
                onChange={option => {
                  setDiscountType(option);
                }}
                value={discountType}
                label="Discount Type"
                options={DISCOUNT_TYPES}
                getOptionValue={option => option.value}
                getOptionLabel={option => option.label}
                isRequired
              />
            </Col>
            <Col lg="4">
              {discountType?.value === 'PERCENTAGE' ? (
                <NumberField
                  name="percentage_amount"
                  label="Discount Rate"
                  placeholder="Discount"
                  min="1"
                  max="100"
                  errorMessage="Value must be between 1 and 100"
                  isRequired
                />
              ) : (
                <NumberField
                  name="money_amount"
                  label="Discount Amount"
                  placeholder="Discount"
                  min="1"
                  errorMessage="Value must be greater than 0"
                  isRequired
                />
              )}
            </Col>
            <Col lg="4">
              <NumberField
                name="minimum_spend_amount"
                label="Minimum Spend Amount"
                placeholder="Minimum Spend Amount"
                min="0"
              />
            </Col>
            <Col lg="4">
              <FormGroup>
                <SelectField
                  name="categories"
                  placeholder="Select categories..."
                  options={availableCategories}
                  label="Included Categories"
                  onChange={onIncludedCategoriesChange}
                  multiple
                  value={selectedIncludedCategories}
                  isDisabled={
                    !selectedBrand || selectedExcludedCategories.length > 0
                  }
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <SelectField
                  name="excluded_categories"
                  placeholder="Select categories..."
                  options={availableCategories}
                  label="Excluded Categories"
                  onChange={onExcludedCategoriesChange}
                  multiple
                  value={selectedExcludedCategories}
                  isDisabled={
                    !selectedBrand || selectedIncludedCategories.length > 0
                  }
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <Label htmlFor="date">Time limit</Label>
              <DateRangePicker
                placement="bottom-start"
                startDate={Date.parse(model?.promotion_start_date)}
                endDate={Date.parse(model?.promotion_end_date)}
                onDateChange={handleDateChange}
              />
            </Col>
            <Col lg="4">
              <FormGroup>
                <Label htmlFor="warehouse">Included SKUs</Label>
                <ProductsPaginatedField
                  id="wine"
                  placeholder="Select products..."
                  value={selectedIncludedSkus}
                  onChange={setSelectedIncludedSkus}
                  options={{ brand_id: selectedBrand?.value }}
                  isDisabled={!selectedBrand || selectedExcludedSkus.length > 0}
                  isMulti
                  withSKU
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <Label htmlFor="warehouse">Excluded SKUs</Label>
                <ProductsPaginatedField
                  id="wine"
                  placeholder="Select products..."
                  value={selectedExcludedSkus}
                  onChange={setSelectedExcludedSkus}
                  options={{ brand_id: selectedBrand?.value }}
                  isDisabled={!selectedBrand || selectedIncludedSkus.length > 0}
                  isMulti
                  withSKU
                />
              </FormGroup>
            </Col>
          </>
        )}
      </Row>
      <Button color="primary" type="submit" disabled={disabled}>
        {model ? 'Save' : 'Create'}
      </Button>
    </AvForm>
  );
};

DiscountForm.propTypes = {
  model: PropTypes.object,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
};

export default DiscountForm;
