import { createSelector } from 'reselect';

export const rootSelector = state => state.brands;

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const brandsSelector = createSelector(
  rootSelector,
  ({ brands }) => brands
);

export const brandsStepSelector = createSelector(
  rootSelector,
  ({ step }) => step
);

export const paginationSelector = createSelector(
  rootSelector,
  ({ pagination }) => pagination
);

export const brandSelector = createSelector(rootSelector, ({ brand }) => brand);
