/* eslint-disable prefer-destructuring */
import { createSlice } from 'redux-starter-kit';
import actionTypes from 'utils/actionTypes';
import { tabs } from 'constants/organizations';

const organizations = createSlice({
  name: 'organizations',
  initialState: {
    isPending: false,
    notesPending: false,
    activitiesPending: false,
    organization: {},
    organizations: [],
    createdOrganization: null,
    blockMainInformationTab: false,
    currentTab: tabs[0],
    pagination: {},
    fieldErrors: {},
  },
  reducers: {
    setCreatedOrganization: (state, { payload }) => {
      state.createdOrganization = payload;
    },
    fetchOrganizations: state => {
      state.isPending = true;
    },
    fetchOrganizationsSuccess(state, { payload }) {
      state.isPending = false;
      state.organizations = payload.data.results;
      state.pagination = payload.data.pagination;
    },
    fetchOrganizationsFailure: state => {
      state.isPending = false;
    },
    fetchOrganization: state => {
      state.isPending = true;
      state.blockMainInformationTab = false;
      state.organization = {};
      state.currentTab = tabs[0];
    },
    fetchOrganizationSuccess(state, { payload }) {
      state.isPending = false;
      state.organization = payload.data;
      state.createdOrganization = payload.data;
    },
    fetchOrganizationFailure: state => {
      state.isPending = false;
    },
    createOrganization: state => {
      state.isPending = true;
      state.fieldErrors = {};
    },
    createOrganizationSuccess(state, { payload }) {
      state.isPending = false;
      state.createdOrganization = payload.data;
      state.blockMainInformationTab = true;
      state.currentTab = tabs[1];
    },
    createOrganizationFailure: (state, { payload }) => {
      state.fieldErrors = payload.response;
      state.isPending = false;
    },
    addOrganizationNote: state => {
      state.notesPending = true;
    },
    addOrganizationNoteSuccess: state => {
      state.notesPending = false;
    },
    addOrganizationNoteFailure: state => {
      state.notesPending = false;
    },
    addOrganizationActivity: state => {
      state.activitiesPending = true;
    },
    addOrganizationActivitySuccess: state => {
      state.activitiesPending = false;
    },
    addOrganizationActivityFailure: state => {
      state.activitiesPending = false;
    },
    updateOrganization: state => {
      state.isPending = true;
    },
    updateOrganizationSuccess(state, { payload }) {
      state.isPending = false;
      state.createdOrganization = payload.data;
      state.organization = payload.data;
      state.currentTab = tabs[1];
    },
    updateOrganizationFailure: state => {
      state.isPending = false;
    },
    setCurrentTab(state, { payload }) {
      state.currentTab = payload;
    },
    resetBlockMainInformationTab: state => {
      state.blockMainInformationTab = false;
    },
    resetFieldErrors: state => {
      state.fieldErrors = {};
    },
    resetOrganization: state => {
      state.organization = {};
    },
  },
});

export const actions = actionTypes(organizations.actions);

export default organizations.reducer;
