import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Button } from 'reactstrap';
import { format } from 'date-fns';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { actions as customersActions } from 'models/customers/slice';
import { actions as organizationsActions } from 'models/organizations/slice';
import { customerSelector } from 'models/customers/selectors';
import { organizationSelector } from 'models/organizations/selectors';
import NotePopup from 'components/Popup/NotePopup';

const prepareValue = value => value ?? '-';

const NotesTable = ({ notes, isLead, isCustomer }) => {
  const [openModal, setOpenModal] = useState(false);
  const customer = useSelector(customerSelector);
  const addCustomerNote = useAction(customersActions.addCustomerNote);

  const organization = useSelector(organizationSelector);
  const addOrganizationNote = useAction(
    organizationsActions.addOrganizationNote
  );

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleSubmitNote = (event, data) => {
    if (isLead) {
      addOrganizationNote({ id: organization.id, ...data });
    }
    if (isCustomer) {
      addCustomerNote({ id: customer.id, ...data });
    }
    setOpenModal(false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <div className="table-rep-plugin">
      <div className="table-responsive mb-0" data-pattern="priority-columns">
        <Table id="tech-companies-1" bordered responsive>
          <thead>
            <tr>
              <th width="30" data-priority="1">
                ID
              </th>
              <th width="65%" data-priority="1">
                Text
              </th>
              <th data-priority="1">Created at</th>
            </tr>
          </thead>
          <tbody>
            {notes.map(note => (
              <tr key={`${note.id}`}>
                <td>{prepareValue(note?.id)}</td>
                <td>{prepareValue(note?.text)}</td>
                <td>
                  {format(new Date(note.created_at), 'yyyy-MM-dd, h:mm aaa')}
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan={3}>
                <Button
                  color="success"
                  type="button"
                  onClick={handleOpenModal}
                  className="waves-effect waves-light text-nowrap d-flex align-items-center"
                >
                  <i className="ri-sticky-note-line mr-2" /> Add note
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
        {openModal && (
          <NotePopup
            onClose={handleCloseModal}
            handleSubmit={handleSubmitNote}
            header="Add Note"
            placeholder="Note"
            name="note"
          />
        )}
      </div>
    </div>
  );
};

NotesTable.propTypes = {
  notes: PropTypes.array.isRequired,
  isLead: PropTypes.bool,
  isCustomer: PropTypes.bool,
};

export default NotesTable;
