import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import useAction from 'hooks/useAction';
import { Row, Col, Card, CardBody } from 'reactstrap';
import useSelector from 'hooks/useSelector';
import PageWrapper from 'components/PageWrapper';
import { actions as organizationsActions } from 'models/organizations/slice';
import { actions as customersActions } from 'models/customers/slice';
import { isOnlySingleRole } from 'utils/isOnlySingleRole';
import { PERMISSION_TYPES } from 'constants/permissions';

import s from './OrganizationsShow.scss';
import {
  organizationSelector,
  isPendingSelector,
} from 'models/organizations/selectors';
import {
  paymentMethodsSelector,
  paymentTypesSelector,
} from 'models/customers/selectors';
import {
  rolesSelector,
  hasOwnerOrManagerRoleSelector,
} from 'models/user/selectors';
import Preloader from 'components/Preloader';
import Breadcrumbs from 'components/Breadcrumbs';
import isEmpty from 'lodash/isEmpty';
import PaymentMethodsTable from 'components/PaymentMethodsTable';
import PaymentTypesTable from 'components/PaymentTypesTable';
import { getNormalText } from 'utils/getNormalText';

const CardItem = ({ title, value }) => (
  <Card>
    <CardBody className={s.card}>
      <h4 className="card-title">{title}</h4>
      <span className="text-dark">{value}</span>
    </CardBody>
  </Card>
);

const OrganizationsShow = () => {
  const { id } = useParams();
  const fetchOrganization = useAction(organizationsActions.fetchOrganization);
  const fetchPaymentMethods = useAction(customersActions.fetchPaymentMethods);
  const fetchPaymentTypes = useAction(customersActions.fetchPaymentTypes);
  const removePaymentMethod = useAction(customersActions.removePaymentMethod);
  const organization = useSelector(organizationSelector);
  const paymentMethods = useSelector(paymentMethodsSelector);
  const paymentTypes = useSelector(paymentTypesSelector);
  const pending = useSelector(isPendingSelector);
  const roles = useSelector(rolesSelector);

  const isSalesRep = isOnlySingleRole(roles, PERMISSION_TYPES.SALES_REP);
  const isOwnerOrManagerRole = useSelector(hasOwnerOrManagerRoleSelector);

  const isEditLinkVisible = isSalesRep || isOwnerOrManagerRole;

  useEffect(() => {
    fetchOrganization(id);
  }, [fetchOrganization, id]);

  useEffect(() => {
    if (organization?.contact_person?.id) {
      fetchPaymentMethods(organization?.contact_person?.id);
      fetchPaymentTypes(organization?.contact_person?.id);
    }
  }, [organization, fetchPaymentMethods, fetchPaymentTypes]);

  const onRemovePaymentMethod = methodId => {
    removePaymentMethod({
      id: methodId,
      customerId: organization?.contact_person?.id,
    });
  };

  if (pending) return <Preloader />;
  return (
    <PageWrapper>
      {isEditLinkVisible ? (
        <Breadcrumbs
          link={`/commercial-customers/${organization?.id}/edit`}
          title={`Edit ${organization?.title} #${organization?.id}`}
          breadcrumbItems={[
            {
              title: 'Back to commercial customers',
              link: '/commercial-customers/',
              withSearch: true,
            },
          ]}
        />
      ) : null}
      {!isEmpty(organization) && (
        <>
          <Row>
            <Col lg="3">
              <CardItem title="Title" value={organization.title} />
            </Col>
            <Col lg="3">
              <CardItem
                title="License Number"
                value={organization.license_number}
              />
            </Col>
            <Col lg="3">
              <CardItem
                title="Company Type"
                value={organization.company_type}
              />
            </Col>
            <Col lg="3">
              <CardItem
                title="Price Type"
                value={getNormalText(organization.price_type)}
              />
            </Col>
            {organization?.default_warehouse && (
              <Col lg="3">
                <CardItem
                  title="Default Warehouse"
                  value={organization.default_warehouse.title}
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col lg="6">
              <CardItem
                title="Shipping Address"
                value={Object.values(organization?.address).join(' ')}
              />
            </Col>
            <Col lg="6">
              <CardItem
                title="Contact Person"
                value={`
              ${organization.contact_person.first_name}
              ${organization.contact_person.last_name}
              ${organization.contact_person.email}
              ${organization.contact_person.phone || ''}
              `}
              />
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col lg="12">
          {paymentMethods?.length === 0 && paymentTypes?.length === 0 ? (
            <h5>The list of payment methods is empty</h5>
          ) : (
            <>
              <PaymentTypesTable paymentTypes={paymentTypes} />
              <PaymentMethodsTable
                paymentMethods={paymentMethods}
                onRemovePaymentMethod={onRemovePaymentMethod}
              />
            </>
          )}
        </Col>
      </Row>
    </PageWrapper>
  );
};

CardItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default OrganizationsShow;
