import React from 'react';
import Login from 'pages/Auth/Login';
import Logout from 'pages/Auth/Logout';
import RecoverPassword from 'pages/Auth/RecoverPassword';
import ResetPassword from 'pages/Auth/ResetPassword';
import UserConfirmation from 'pages/Auth/UserConfirmation';
import Register from 'pages/Auth/Register';
import AwardEdit from 'pages/AwardEdit';
import EditBrand from 'pages/EditBrand';
import EditTable from 'pages/EditTable';
import InviteHost from 'pages/InviteHost';
import EditDevice from 'pages/EditDevice';
import OrdersShow from 'pages/OrdersShow';
import AwardCreate from 'pages/AwardCreate';
import ShowProduct from 'pages/ShowProduct';
import CreateBrand from 'pages/CreateBrand';
import CreateTable from 'pages/CreateTable';
import EditProduct from 'pages/EditProduct';
import ShowTransfer from 'pages/ShowTransfer';
import CreateDevice from 'pages/CreateDevice';
import TiersSummary from 'pages/TiersSummary';
import UsersSummary from 'pages/UsersSummary';
import CreateUser from 'pages/CreateUser';
import EditUser from 'pages/EditUser';
import HostsSummary from 'pages/HostsSummary';
import TerminalShow from 'pages/TerminalShow';
import TerminalEdit from 'pages/TerminalEdit';
import CreateProduct from 'pages/CreateProduct';
import AwardsSummary from 'pages/AwardsSummary';
import EditWarehouse from 'pages/EditWarehouse';
import OrdersSummary from 'pages/OrdersSummary';
import WarehouseShow from 'pages/WarehouseShow';
import BrandsSummary from 'pages/BrandsSummary';
import TablesSummary from 'pages/TablesSummary';
import AcquirerCreate from 'pages/AcquirerCreate';
import ReportsSalesSummary from 'pages/ReportsSalesSummary';
import ReportsTasterTrackingSummary from 'pages/ReportsTasterTrackingSummary';
import TerminalCreate from 'pages/TerminalCreate';
import DevicesSummary from 'pages/DevicesSummary';
import CreateTransfer from 'pages/CreateTransfer';
import ProductSummary from 'pages/ProductSummary';
import EditTastingRoom from 'pages/EditTastingRoom';
import WineclubSummary from 'pages/WineclubSummary';
import CreateWarehouse from 'pages/CreateWarehouse';
import TransfersSummary from 'pages/TransfersSummary';
import TerminalsSummary from 'pages/TerminalsSummary';
import AcquirersSummary from 'pages/AcquirersSummary';
import SupportSummary from 'pages/SupportSummary';
import CreateTastingRoom from 'pages/CreateTastingRoom';
import WarehousesSummary from 'pages/WarehousesSummary';
import EditProductsPreset from 'pages/EditProductsPreset';
import TastingRoomsSummary from 'pages/TastingRoomsSummary';
import CreateProductsPreset from 'pages/CreateProductsPreset';
import SubscriptionOrderEdit from 'pages/SubscriptionOrderEdit';
import ProductsPresetsSummary from 'pages/ProductsPresetsSummary';
import CustomersSummary from './pages/CustomersSummary';
import CustomersShow from './pages/CustomersShow';
import DuplicateTable from './pages/DuplicateTable';
import DuplicateProduct from './pages/DuplicateProduct';
import FoodCategoriesSummary from './pages/FoodCategoriesSummary';
import EditFoodCategory from './pages/EditFoodCategory';
import CreateFoodCategory from './pages/CreateFoodCategory';
import CreateFoodAllergy from './pages/CreateFoodAllergy';
import EditFoodAllergy from './pages/EditFoodAllergy';
import FoodAllergiesSummary from './pages/FoodAllergiesSummary';
import CreateOrder from './pages/CreateOrder';
import CreateCustomer from './pages/CreateCustomer';
import EditCustomer from './pages/EditCustomer';
import MerchCategoriesSummary from './pages/MerchCategoriesSummary';
import CreateMerchCategory from './pages/CreateMerchCategory';
import MailingLists from './pages/MailingLists';
import EditOrder from './pages/EditOrder';
import OrganizationsSummary from './pages/OrganizationsSummary';
import CreateOrganization from './pages/CreateOrganization';
import EditOrganization from './pages/EditOrganization';
import OrganizationsShow from './pages/OrganizationsShow';
import CommercialOrdersSummary from './pages/CommercialOrdersSummary';
import CreateCommercialOrder from './pages/CreateCommercialOrder';
import EditCommercialOrder from './pages/EditCommercialOrder';
import LeadsSummary from './pages/LeadsSummary';
import CreateLead from './pages/CreateLead';
import EditLead from './pages/EditLead';
import LeadsShow from './pages/LeadsShow';
import EditMerchCategory from './pages/EditMerchCategory';
import WineCategoriesSummary from './pages/WineCategoriesSummary';
import CreateWineCategory from './pages/CreateWineCategory';
import EditWineCategory from './pages/EditWineCategory';
import ReportsBatchSummary from './pages/ReportsBatchSummary';
import InventoryCountSummary from './pages/InventoryCountSummary';
import ContainerWorldExceptionSummary from './pages/ContainerWorldExceptionSummary';
import InventoryCountShow from './pages/InventoryCountShow';
import CreateInventoryCount from './pages/CreateInventoryCount';
import RefundOrder from './pages/RefundOrder';
import CreateEmailTemplate from './pages/CreateEmailTemplate';
import EditEmailTemplate from './pages/EditEmailTemplate';
import EmailTemplateSummary from './pages/EmailTemplateSummary';
import {
  PERMISSION_TYPES,
  WORKSPACE_SETTING_TYPES,
} from './constants/permissions';
import AbstractCustomerShow from './pages/AbstractCustomerShow';
import CreateCustomEmail from './pages/CreateCustomEmail';
import EditCustomEmail from './pages/EditCustomEmail';
import FootTraffic from './pages/FootTraffic';
import ReportsAssociatesSummary from './pages/ReportsAssociatesSummary';
import DiscountsSummary from 'pages/DiscountsSummary';
import CreateDiscountCode from 'pages/CreateDiscountCode';
import EditDiscountCode from 'pages/EditDiscountCode';
import DiscountCodeShow from 'pages/DiscountCodeShow/DiscountCodeShow';
import ImportCustomers from './pages/ImportCustomers/ImportCustomers';
import ReportsExport from './pages/ReportsExport';
import ReportsHistory from './pages/ReportsHistory';
import ExciseLicensesSummary from './pages/ExciseLicensesSummary';
import {
  ExciseLicenseEdit,
  ExciseLicenseShow,
  ExciseLicenseCreate,
} from './pages/ExciseLicense';
import {
  ExciseReturnEdit,
  ExciseReturnShow,
  ExciseReturnCreate,
} from './pages/ExciseReturn';
import LowInventorySummary from './pages/LowInventorySummary';
import Dashboard from './pages/Dashboard';
import CommissionsSummary from './pages/CommissionsSummary';
import CommissionsShow from './pages/CommissionsShow';
import TimeEntrySummary from './pages/TimeEntrySummary/TimeEntrySummary';
import TimeEntryDetails from './pages/TimeEntryDetails/TimeEntryDetails';
import OrderImportSummary from './pages/OrderImportSummary';
import ReportsInventoryTransfersSummary from './pages/ReportsInventoryTransfersSummary';
import ReportsInventorySummary from './pages/ReportsInventorySummary';

export default [
  {
    path: '/dashboard',
    exact: true,
    component: Dashboard,
    title: 'Dashboard',
    layout: 'main',
  },
  {
    path: '/users/create',
    exact: true,
    component: CreateUser,
    title: 'Create User',
    layout: 'main',
  },
  {
    path: '/users/:params?',
    exact: true,
    component: UsersSummary,
    title: 'Users',
    layout: 'main',
  },
  {
    path: '/users/:id/edit',
    exact: true,
    component: EditUser,
    title: 'Edit User',
    layout: 'main',
  },
  {
    path: '/acquirers/create',
    exact: true,
    component: AcquirerCreate,
    title: 'Acquirer Create',
    layout: 'main',
  },
  {
    path: '/acquirers/:params?',
    exact: true,
    component: AcquirersSummary,
    title: 'Acquirers',
    layout: 'main',
  },
  {
    path: '/support',
    exact: true,
    component: SupportSummary,
    title: 'Support',
    layout: 'main',
  },
  {
    path: '/hosts/:params?',
    exact: true,
    component: HostsSummary,
    title: 'Hosts',
    layout: 'main',
  },
  {
    path: '/host/invite',
    exact: true,
    component: InviteHost,
    title: 'Invite Host',
    layout: 'main',
  },
  {
    path: '/transfers/create/:productId?',
    exact: true,
    component: CreateTransfer,
    title: 'Transfer',
    layout: 'main',
  },
  {
    path: '/transfers/:params?',
    exact: true,
    component: TransfersSummary,
    title: 'Product Movement',
    layout: 'main',
  },
  {
    path: '/transfers/:transferID/show',
    exact: true,
    component: ShowTransfer,
    title: 'Product Movement',
    layout: 'main',
  },
  {
    path: '/devices/create',
    exact: true,
    component: CreateDevice,
    title: 'Device',
    layout: 'main',
  },
  {
    path: '/devices/:params?',
    exact: true,
    component: DevicesSummary,
    title: 'Devices',
    layout: 'main',
  },
  {
    path: '/devices/:deviceID/edit',
    exact: true,
    component: EditDevice,
    title: 'Device',
    layout: 'main',
  },
  {
    path: '/tables/create',
    exact: true,
    component: CreateTable,
    title: 'Table',
    layout: 'main',
  },
  {
    path: '/tables/:params?',
    exact: true,
    component: TablesSummary,
    title: 'Tables',
    layout: 'main',
  },
  {
    path: '/tables/:tableID/edit',
    exact: true,
    component: EditTable,
    title: 'Table',
    layout: 'main',
  },
  {
    path: '/tables/:tableID/duplicate',
    exact: true,
    component: DuplicateTable,
    title: 'Table',
    layout: 'main',
  },
  {
    path: '/tasting_rooms/create',
    exact: true,
    component: CreateTastingRoom,
    title: 'Room Create',
    layout: 'main',
  },
  {
    path: '/tasting_rooms/:params?',
    exact: true,
    component: TastingRoomsSummary,
    title: 'Rooms',
    layout: 'main',
  },
  {
    path: '/tasting_rooms/:tastingRoomID/edit',
    exact: true,
    component: EditTastingRoom,
    title: 'Room Edit',
    layout: 'main',
  },
  {
    path: '/warehouses/create',
    exact: true,
    component: CreateWarehouse,
    title: 'Warehouse Create',
    layout: 'main',
  },
  {
    path: '/warehouses/:params?',
    exact: true,
    component: WarehousesSummary,
    title: 'Warehouses',
    layout: 'main',
  },
  {
    path: '/warehouses/:warehouseID/edit',
    exact: true,
    component: EditWarehouse,
    title: 'Warehouse Edit',
    layout: 'main',
  },
  {
    path: '/warehouses/:warehouseID/show',
    exact: true,
    component: WarehouseShow,
    title: 'Warehouse',
    layout: 'main',
  },
  {
    path: '/discount-codes/create',
    exact: true,
    component: props => <CreateDiscountCode {...props} isDiscountCode />,
    title: 'Create Coupon Code',
    layout: 'main',
  },
  {
    path: '/discounts/create',
    exact: true,
    component: CreateDiscountCode,
    title: 'Create Discount',
    layout: 'main',
  },

  {
    path: '/discount-codes/:params?',
    exact: true,
    component: DiscountsSummary,
    title: 'Discounts',
    layout: 'main',
  },
  {
    path: '/discount-codes/:codeID/edit',
    exact: true,
    component: EditDiscountCode,
    title: 'Coupon Code Edit',
    layout: 'main',
  },
  {
    path: '/discount-codes/:codeID/show',
    exact: true,
    component: DiscountCodeShow,
    title: 'Coupon Code',
    layout: 'main',
  },
  {
    path: '/brands/create',
    exact: true,
    component: CreateBrand,
    title: 'Brand Create',
    layout: 'main',
  },
  {
    path: '/brands/:params?',
    exact: true,
    component: BrandsSummary,
    title: 'Brands',
    layout: 'main',
  },
  {
    path: '/brands/:brandID/edit/:selectedTab?',
    exact: true,
    component: EditBrand,
    title: 'Brand Edit',
    layout: 'main',
  },
  {
    path: '/email/create',
    exact: true,
    component: CreateCustomEmail,
    title: 'Create Email',
    layout: 'main',
  },
  {
    path: '/email/:id/edit',
    exact: true,
    component: EditCustomEmail,
    title: 'Edit Email',
    layout: 'main',
  },
  {
    path: '/email-template/create',
    exact: true,
    component: CreateEmailTemplate,
    title: 'Email Template',
    layout: 'main',
  },
  {
    path: '/email-template/:wineClubId/:templateId/edit',
    exact: true,
    component: EditEmailTemplate,
    title: 'Email Template',
    layout: 'main',
  },
  {
    path: '/email-templates/:params?',
    exact: true,
    component: EmailTemplateSummary,
    title: 'Email Templates',
    layout: 'main',
  },
  {
    path: '/products/:params?',
    exact: true,
    component: ProductSummary,
    title: 'Products',
    layout: 'main',
  },
  {
    path: '/products/:productID/edit',
    exact: true,
    component: EditProduct,
    title: 'Product Edit',
    layout: 'main',
  },
  {
    path: '/products/:productID/duplicate',
    exact: true,
    component: DuplicateProduct,
    title: 'Product Duplicate',
    layout: 'main',
  },
  {
    path: '/products/:productID/show',
    exact: true,
    component: ShowProduct,
    title: 'Product',
    layout: 'main',
  },
  {
    path: '/product/create',
    exact: true,
    component: CreateProduct,
    title: 'Product Create',
    layout: 'main',
  },
  {
    path: '/food-categories',
    exact: true,
    component: FoodCategoriesSummary,
    title: 'Food Categories',
    layout: 'main',
  },
  {
    path: '/food-categories/create',
    exact: true,
    component: CreateFoodCategory,
    title: 'Create Food Category',
    layout: 'main',
  },
  {
    path: '/food-categories/:id/edit',
    exact: true,
    component: EditFoodCategory,
    title: 'Edit Food Category',
    layout: 'main',
  },
  {
    path: '/wine-categories',
    exact: true,
    component: WineCategoriesSummary,
    title: 'Wine Categories',
    layout: 'main',
  },
  {
    path: '/wine-categories/create',
    exact: true,
    component: CreateWineCategory,
    title: 'Create Wine Category',
    layout: 'main',
  },
  {
    path: '/wine-categories/:id/edit',
    exact: true,
    component: EditWineCategory,
    title: 'Edit Wine Category',
    layout: 'main',
  },

  {
    path: '/food-allergies',
    exact: true,
    component: FoodAllergiesSummary,
    title: 'Food Allergies',
    layout: 'main',
  },
  {
    path: '/food-allergies/create',
    exact: true,
    component: CreateFoodAllergy,
    title: 'Create Food Allergy',
    layout: 'main',
  },
  {
    path: '/food-allergies/:id/edit',
    exact: true,
    component: EditFoodAllergy,
    title: 'Edit Food Allergy',
    layout: 'main',
  },
  {
    path: '/merchandise_categories/create',
    exact: true,
    component: CreateMerchCategory,
    title: 'Create Merchandise categories',
    layout: 'main',
  },
  {
    path: '/merchandise_categories/:params?',
    exact: true,
    component: MerchCategoriesSummary,
    title: 'Merchandise categories',
    layout: 'main',
  },
  {
    path: '/merchandise_categories/:id/edit',
    exact: true,
    component: EditMerchCategory,
    title: 'Create Merchandise categories',
    layout: 'main',
  },
  {
    path: '/import-orders',
    exact: true,
    component: OrderImportSummary,
    title: 'Import Orders',
    layout: 'main',
    permissions: [PERMISSION_TYPES.MANAGER, PERMISSION_TYPES.OWNER],
    workspace_settings: [WORKSPACE_SETTING_TYPES.ENABLE_COMMERCE_SEVEN],
  },
  {
    path: '/orders/create',
    exact: true,
    component: CreateOrder,
    title: 'Create Order',
    layout: 'main',
  },
  {
    path: '/orders/:orderID',
    exact: true,
    component: OrdersShow,
    title: 'Order',
    layout: 'main',
    permissions: [
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.SALES_REP,
      PERMISSION_TYPES.OWNER,
    ],
  },
  {
    path: '/orders/:params?',
    exact: true,
    component: OrdersSummary,
    title: 'Orders',
    layout: 'main',
  },
  {
    path: '/orders/:orderID/edit',
    exact: true,
    component: EditOrder,
    title: 'Edit Order',
    layout: 'main',
  },
  {
    path: '/commercial-orders/create',
    exact: true,
    component: CreateCommercialOrder,
    title: 'Create commercial order',
    layout: 'main',
    permissions: [
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.SALES_REP,
      PERMISSION_TYPES.OWNER,
    ],
  },
  {
    path: '/commercial-orders/:orderID',
    exact: true,
    component: OrdersShow,
    title: 'Commercial Order',
    layout: 'main',
    permissions: [
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.SALES_REP,
      PERMISSION_TYPES.OWNER,
    ],
  },
  {
    path: '/commercial-orders/:orderID/edit',
    exact: true,
    component: EditCommercialOrder,
    title: 'Edit commercial order',
    layout: 'main',
    permissions: [
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.SALES_REP,
      PERMISSION_TYPES.OWNER,
    ],
  },
  {
    path: '/commercial-orders/:params?',
    exact: true,
    component: CommercialOrdersSummary,
    title: 'Commercial Orders',
    layout: 'main',
    permissions: [
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.SALES_REP,
      PERMISSION_TYPES.OWNER,
    ],
  },
  {
    path: '/orders/subscription/:orderID/edit',
    exact: true,
    component: SubscriptionOrderEdit,
    title: 'Edit',
    layout: 'main',
  },
  {
    path: '/commercial-customers/create',
    exact: true,
    component: CreateOrganization,
    title: 'Create Commercial Customer',
    layout: 'main',
    permissions: [
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.SALES_REP,
      PERMISSION_TYPES.OWNER,
    ],
  },
  {
    path: '/commercial-customers/:id',
    exact: true,
    component: OrganizationsShow,
    title: 'Commercial Customer',
    layout: 'main',
    permissions: [
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.SALES_REP,
      PERMISSION_TYPES.OWNER,
    ],
  },
  {
    path: '/commercial-customers/:params?',
    exact: true,
    component: OrganizationsSummary,
    title: 'Commercial Customers',
    layout: 'main',
    permissions: [
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.SALES_REP,
      PERMISSION_TYPES.OWNER,
    ],
  },

  {
    path: '/commercial-customers/:id/edit',
    exact: true,
    component: EditOrganization,
    title: 'Edit Commercial Customer',
    layout: 'main',
    permissions: [
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.SALES_REP,
      PERMISSION_TYPES.OWNER,
    ],
  },

  {
    path: '/leads/create',
    exact: true,
    component: CreateLead,
    title: 'Create Lead',
    layout: 'main',
    permissions: [
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.SALES_REP,
      PERMISSION_TYPES.OWNER,
    ],
  },

  {
    path: '/leads/:id',
    exact: true,
    component: LeadsShow,
    title: 'Lead',
    layout: 'main',
    permissions: [
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.SALES_REP,
      PERMISSION_TYPES.OWNER,
    ],
  },

  {
    path: '/leads/:params?',
    exact: true,
    component: LeadsSummary,
    title: 'Leads',
    layout: 'main',
    permissions: [
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.SALES_REP,
      PERMISSION_TYPES.OWNER,
    ],
  },

  {
    path: '/lead/:id/edit',
    exact: true,
    component: EditLead,
    title: 'Edit Lead',
    layout: 'main',
    permissions: [
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.SALES_REP,
      PERMISSION_TYPES.OWNER,
    ],
  },

  {
    path: '/reports/commissions',
    exact: true,
    component: CommissionsSummary,
    title: 'Commissions',
    layout: 'main',
    permissions: [
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.SALES_REP,
      PERMISSION_TYPES.OWNER,
      PERMISSION_TYPES.BOOKKEEPER,
    ],
  },
  {
    path: '/reports/commissions/:id',
    exact: true,
    component: CommissionsShow,
    title: 'Commissions',
    layout: 'main',
    permissions: [
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.SALES_REP,
      PERMISSION_TYPES.OWNER,
      PERMISSION_TYPES.BOOKKEEPER,
    ],
  },
  {
    path: '/import-customers/',
    exact: true,
    component: ImportCustomers,
    title: 'Import Customers',
    layout: 'main',
  },
  {
    path: '/wineclubs/:params?',
    exact: true,
    component: WineclubSummary,
    title: 'Wine Clubs',
    layout: 'main',
  },
  {
    path: '/mailing-lists',
    exact: true,
    component: MailingLists,
    title: 'Mailing Lists',
    layout: 'main',
  },
  {
    path: '/tiers/:params?',
    exact: true,
    component: TiersSummary,
    title: 'Tiers',
    layout: 'main',
  },
  {
    path: '/products-presets/:params?',
    exact: true,
    component: ProductsPresetsSummary,
    title: 'Tasting Menus',
    layout: 'main',
  },
  {
    path: '/products-presets/:productsPresetID/edit',
    exact: true,
    component: EditProductsPreset,
    title: 'Tasting Menu Edit',
    layout: 'main',
  },
  {
    path: '/products-preset/create',
    exact: true,
    component: CreateProductsPreset,
    title: 'Tasting Menu Create',
    layout: 'main',
  },
  {
    path: '/awards/:params?',
    exact: true,
    component: AwardsSummary,
    title: 'Awards',
    layout: 'main',
  },
  {
    path: '/award/create',
    exact: true,
    component: AwardCreate,
    title: 'Award Create',
    layout: 'main',
  },
  {
    path: '/awards/:awardID/edit',
    exact: true,
    component: AwardEdit,
    title: 'Award Edit',
    layout: 'main',
  },
  {
    path: '/terminals/create',
    exact: true,
    component: TerminalCreate,
    title: 'Create terminal',
    layout: 'main',
  },
  {
    path: '/terminals/:params?',
    exact: true,
    component: TerminalsSummary,
    title: 'Terminals',
    layout: 'main',
  },
  {
    path: '/terminals/:terminalID/show',
    exact: true,
    component: TerminalShow,
    title: 'Terminal',
    layout: 'main',
  },
  {
    path: '/terminals/:terminalID/edit',
    exact: true,
    component: TerminalEdit,
    title: 'Terminal',
    layout: 'main',
  },
  {
    path: '/wineclub-customers',
    exact: true,
    component: CustomersSummary,
    title: 'DTC',
    layout: 'main',
  },
  {
    path: '/wineclub-customers/create',
    exact: true,
    component: CreateCustomer,
    title: 'Customer',
    layout: 'main',
  },
  {
    path: '/wineclub-customers/:id',
    exact: true,
    component: CustomersShow,
    title: 'Customer',
    layout: 'main',
  },
  {
    path: '/wineclub-customers/abstract/:email',
    exact: true,
    component: AbstractCustomerShow,
    title: 'Customer',
    layout: 'main',
  },
  {
    path: '/wineclub-customers/:id/edit',
    exact: true,
    component: EditCustomer,
    title: 'Customer',
    layout: 'main',
  },
  {
    path: '/reports/sales',
    exact: true,
    component: ReportsSalesSummary,
    title: 'Sales',
    layout: 'main',
    permissions: [
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.SALES_REP,
      PERMISSION_TYPES.OWNER,
      PERMISSION_TYPES.BOOKKEEPER,
    ],
  },
  {
    path: '/reports/product-movements',
    exact: true,
    component: ReportsInventoryTransfersSummary,
    title: 'Product Movements',
    layout: 'main',
    workspace_settings: [
      WORKSPACE_SETTING_TYPES.ENABLE_PRODUCT_MOVEMENT_REPORT,
    ],
  },
  {
    path: '/reports/taster-tracking',
    exact: true,
    component: ReportsTasterTrackingSummary,
    title: 'Taster Tracking',
    layout: 'main',
    permissions: [
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.OWNER,
      PERMISSION_TYPES.BOOKKEEPER,
    ],
  },
  {
    path: '/reports/sales-by-associates',
    exact: true,
    component: ReportsAssociatesSummary,
    title: 'Sales by Associates',
    layout: 'main',
    permissions: [
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.OWNER,
      PERMISSION_TYPES.BOOKKEEPER,
    ],
  },
  {
    path: '/reports/export',
    exact: true,
    component: ReportsExport,
    title: 'QuickBooks',
    layout: 'main',
    permissions: [PERMISSION_TYPES.BOOKKEEPER],
  },
  {
    path: '/reports/export-history',
    exact: true,
    component: ReportsHistory,
    title: 'Export history',
    layout: 'main',
    permissions: [PERMISSION_TYPES.BOOKKEEPER],
  },
  {
    path: '/reports/excise',
    exact: true,
    component: ExciseLicensesSummary,
    title: 'Excise Licenses',
    layout: 'main',
    permissions: [
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.OWNER,
      PERMISSION_TYPES.BOOKKEEPER,
    ],
  },
  {
    path: '/reports/excise/license/create',
    exact: true,
    component: ExciseLicenseCreate,
    title: 'Create Excise License',
    layout: 'main',
    permissions: [
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.OWNER,
      PERMISSION_TYPES.BOOKKEEPER,
    ],
  },
  {
    path: '/reports/excise/license/:id',
    exact: true,
    component: ExciseLicenseShow,
    title: 'Excise License',
    layout: 'main',
    permissions: [
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.OWNER,
      PERMISSION_TYPES.BOOKKEEPER,
    ],
  },
  {
    path: '/reports/excise/license/:id/edit',
    exact: true,
    component: ExciseLicenseEdit,
    title: 'Edit Excise License',
    layout: 'main',
    permissions: [
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.OWNER,
      PERMISSION_TYPES.BOOKKEEPER,
    ],
  },
  {
    path: '/reports/excise/license/:excise_license_id/return/create',
    exact: true,
    component: ExciseReturnCreate,
    title: 'Create Excise Return',
    layout: 'main',
    permissions: [
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.OWNER,
      PERMISSION_TYPES.BOOKKEEPER,
    ],
  },
  {
    path: '/reports/excise/license/:excise_license_id/return/:id/edit',
    exact: true,
    component: ExciseReturnEdit,
    title: 'Edit Excise Return',
    layout: 'main',
    permissions: [
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.OWNER,
      PERMISSION_TYPES.BOOKKEEPER,
    ],
  },
  {
    path:
      '/reports/excise/license/:excise_license_id/return/:excise_license_type/:id',
    exact: true,
    component: ExciseReturnShow,
    title: 'Excise Return',
    layout: 'main',
    permissions: [
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.OWNER,
      PERMISSION_TYPES.BOOKKEEPER,
    ],
  },
  {
    path: '/reports/inventories',
    exact: true,
    component: ReportsInventorySummary,
    title: 'Inventory',
    layout: 'main',
    permissions: [
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.OWNER,
      PERMISSION_TYPES.BOOKKEEPER,
    ],
  },
  {
    path: '/inventory-count',
    exact: true,
    component: InventoryCountSummary,
    title: 'Inventory Counts',
    layout: 'main',
    permissions: [
      PERMISSION_TYPES.HOST,
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.OWNER,
      PERMISSION_TYPES.BOOKKEEPER,
    ],
  },
  {
    path: '/inventory-count/create',
    exact: true,
    component: CreateInventoryCount,
    title: 'Count Inventory',
    layout: 'main',
    permissions: [
      PERMISSION_TYPES.HOST,
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.OWNER,
    ],
  },
  {
    path: '/inventory-count/:id',
    exact: true,
    component: InventoryCountShow,
    title: 'Inventory Count',
    layout: 'main',
    permissions: [
      PERMISSION_TYPES.HOST,
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.OWNER,
      PERMISSION_TYPES.BOOKKEEPER,
    ],
  },
  {
    path: '/low-inventory',
    exact: true,
    component: LowInventorySummary,
    title: 'Low Inventory',
    layout: 'main',
    permissions: [PERMISSION_TYPES.MANAGER, PERMISSION_TYPES.OWNER],
  },
  {
    path: '/cw-exceptions',
    exact: true,
    component: ContainerWorldExceptionSummary,
    title: 'ContainerWorld Exceptions',
    layout: 'main',
    permissions: [PERMISSION_TYPES.MANAGER, PERMISSION_TYPES.OWNER],
  },
  {
    path: '/reports/batch',
    exact: true,
    component: ReportsBatchSummary,
    title: 'Batch',
    layout: 'main',
    permissions: [
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.OWNER,
      PERMISSION_TYPES.BOOKKEEPER,
    ],
  },
  {
    path: '/foot-traffic',
    exact: true,
    component: FootTraffic,
    layout: 'main',
    title: 'Foot Traffic',
  },
  {
    path: '/refund/:id',
    exact: true,
    component: RefundOrder,
    title: 'Refund',
    layout: 'main',
  },
  {
    path: '/login',
    exact: true,
    component: Login,
    title: 'Login',
    layout: 'auth',
  },
  {
    path: '/logout',
    exact: true,
    component: Logout,
    title: 'Logout',
    layout: 'auth',
  },
  {
    path: '/recover-password',
    exact: true,
    component: RecoverPassword,
    title: 'Recover password',
    layout: 'auth',
  },
  {
    path: '/reset-password/:token',
    exact: true,
    component: ResetPassword,
    title: 'Reset password',
    layout: 'auth',
  },
  {
    path: '/user-confirmation/:token',
    exact: true,
    component: UserConfirmation,
    title: 'Register host',
    layout: 'auth',
  },
  {
    path: '/register',
    exact: true,
    component: Register,
    title: 'Register',
    layout: 'auth',
  },
  {
    path: '/timesheets/:params?',
    exact: true,
    component: TimeEntrySummary,
    title: 'Timesheets',
    layout: 'main',
    permissions: [
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.OWNER,
      PERMISSION_TYPES.BOOKKEEPER,
    ],
  },
  {
    path: '/timesheet-details/:params?',
    exact: true,
    component: TimeEntryDetails,
    title: 'Timesheet Details',
    layout: 'main',
    permissions: [
      PERMISSION_TYPES.MANAGER,
      PERMISSION_TYPES.OWNER,
      PERMISSION_TYPES.BOOKKEEPER,
    ],
  },
];
