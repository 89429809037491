/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useAction from 'hooks/useAction';
import useSelector from 'hooks/useSelector';
import { AvForm } from 'availity-reactstrap-validation';
import OrderItemsTable from '../OrderItemsTable';
import WarehousesPaginatedField from 'components/PaginatedFields/WarehousesPaginatedField';
import { actions as warehousesActions } from 'models/warehouses/slice';
import { actions as ordersActions } from 'models/orders/slice';
import { actions as customersActions } from 'models/customers/slice';
import { actions as organizationsActions } from 'models/organizations/slice';
import { actions as brandsActions } from 'models/brands/slice';
import Shipments from '../Shipments';
import OrderInfoBlock from 'components/OrderInfoBlock';

import { currentWarehouseSelector } from 'models/warehouses/selectors';

import { organizationSelector } from 'models/organizations/selectors';
import {
  isPaymentPendingSelector,
  paymentTypesSelector,
} from 'models/customers/selectors';
import { createdShipmentSelector } from 'models/orderShipmentRates/selectors';
import { actions as orderShipmentsActions } from 'models/orderShipmentRates/slice';
import {
  rolesSelector,
  hasOwnerOrManagerRoleSelector,
} from 'models/user/selectors';
import { COMMERCIAL_ORDERS_EXCISE_REASON_OPTIONS } from 'constants/transfers';

import { useLocation, Link, Redirect } from 'react-router-dom';

import {
  Row,
  Col,
  FormGroup,
  Label,
  Button,
  TabContent,
  TabPane,
  Spinner,
  Card,
  CardBody,
} from 'reactstrap';

import formatPrice from 'utils/formatPrice';
import { AsyncPaginate } from 'react-select-async-paginate';
import useApiRequest from 'hooks/useApiRequest';
import {
  activeTabSelector,
  createdOrderSelector,
  isPendingActionSelector,
} from 'models/orders/selectors';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import capitalize from 'lodash/capitalize';
import OrderInfo from './OrderInfo';
import LocationSearchInput from '../LocationSearchInput/LocationSearchInput';
import { normalize } from 'utils/normalizeById';
import CardItem from 'components/CardItem';
import Tabs from 'components/Tabs';
import { getRedactedValues } from 'utils/getRedactedValues';
import EmailField from '../Fields/EmailField';
import PhoneField from '../Fields/PhoneField';
import { getFormTabs, isAnyPaymentMethodAvailable } from './utils';
import { isOnlySingleRole } from '../../utils/isOnlySingleRole';
import PaymentAlert from './PaymentAlert';
import SelectField from '../SelectField/SelectField';

const getWarehouseOption = data =>
  data
    ? {
        value: data?.warehouse?.id,
        label: `${data?.warehouse?.title} (${data?.brand?.title})`,
      }
    : null;

const CommercialOrderForm = ({ order, isCreateForm }) => {
  const [orderItems, setOrderItems] = useState(
    normalize(
      order?.order_items.map(item => ({
        ...item,
        number: item.number ?? 0 % item.product_bottles_in_case,
        numberCases: Math.floor(
          item.number ?? 0 / item.product_bottles_in_case
        ),
      })) ?? []
    )
  );
  const location = useLocation();

  const request = useApiRequest();

  const [currentCustomer, setCurrentCustomer] = useState(
    order?.organization ?? ''
  );

  const [currentCard] = useState('');
  const [paymentType] = useState(
    order
      ? {
          apiValue: order?.expected_payment_type,
          userValue: capitalize(order?.expected_payment_type),
        }
      : {}
  );
  const [payLater] = useState(false);

  const [selectedWarehouse, setSelectedWarehouse] = useState(
    getWarehouseOption(order)
  );
  const [isDefaultWarehouse, setDefaultWarehouse] = useState(false);

  const [email, setEmail] = useState(
    order?.shipping_info?.email ||
      order?.customer?.email ||
      currentCustomerInfo?.email ||
      ''
  );
  const [phone, setPhone] = useState(
    order?.shipping_info?.phone ||
      order?.customer?.phone ||
      currentCustomerInfo?.phone ||
      ''
  );
  const [discount, setDiscount] = useState(order?.discount_percentage ?? '');
  const [detailsError, setDetailsError] = useState('');
  const [exciseReason, setExciseReason] = useState('');

  const currentWarehouse = useSelector(currentWarehouseSelector);

  const activeTab = useSelector(activeTabSelector);
  const fetchWarehouse = useAction(warehousesActions.fetchWarehouse);
  const fetchOrganization = useAction(organizationsActions.fetchOrganization);
  const resetOrganization = useAction(organizationsActions.resetOrganization);
  const fetchBrand = useAction(brandsActions.fetchBrand);
  const createOrder = useAction(ordersActions.createCommercialOrder);
  const updateOrder = useAction(ordersActions.updateCommercialOrder);
  const capturePayment = useAction(ordersActions.captureCommercialPayment);
  const setActiveTab = useAction(ordersActions.setCreateOrderTab);
  const patchOrder = useAction(ordersActions.patchCommercialOrder);
  const fetchPaymentTypes = useAction(customersActions.fetchPaymentTypes);
  const confirmCommercialOrder = useAction(
    ordersActions.confirmCommercialOrder
  );
  const createdOrder = useSelector(createdOrderSelector);
  const clearCreatedOrder = useAction(ordersActions.clearCreatedOrder);
  const currentCustomerInfo = useSelector(organizationSelector);
  const createdShipment = useSelector(createdShipmentSelector);
  const setNewShipment = useAction(orderShipmentsActions.setNewShipment);
  const orderActionIsPending = useSelector(isPendingActionSelector);
  const roles = useSelector(rolesSelector);
  const isOwnerOrManagerRole = useSelector(hasOwnerOrManagerRoleSelector);
  const customerPaymentTypes = useSelector(paymentTypesSelector);
  const customerPaymentPending = useSelector(isPaymentPendingSelector);

  const isSalesRep = isOnlySingleRole(roles, 'sales_rep');

  const isPaymentAvailable = isAnyPaymentMethodAvailable(
    selectedWarehouse?.brand_id,
    customerPaymentTypes
  );

  const isPaymentAlertVisible =
    selectedWarehouse &&
    !customerPaymentPending &&
    !isEmpty(currentCustomerInfo) &&
    !isPaymentAvailable &&
    customerPaymentTypes;

  const {
    country: customerCountry = '',
    state: customerState = '',
    city: customerCity = '',
    line1: customerLine1 = '',
    line2: customerLine2 = '',
    postal_code: customerPostalCode = '',
  } = order?.shipping_info || {};

  const [country, setCountry] = useState(customerCountry);
  const [state, setState] = useState(customerState);
  const [postalCode, setPostalCode] = useState(customerPostalCode);
  const [city, setCity] = useState(customerCity);
  const [line1, setLine1] = useState(customerLine1);
  const [line2, setLine2] = useState(customerLine2);

  const setCustomerInfo = useCallback(data => {
    setEmail(data?.email ?? '');
    setPhone(data?.phone ?? '');
    setCountry(data?.country);
    setState(data?.state);
    setCity(data?.city);
    setLine1(data?.line1);
    setLine2(data?.line2 ?? '');
    setPostalCode(data?.postal_code);
  }, []);

  const isOrderInfo = !isFilledFields;
  const isPaymentInfo = isOrderInfo || !createOrder;
  const isTotal = isPaymentInfo || !isPaymentMethodsCorrect;
  const isWarehouseContainerWorld =
    selectedWarehouse?.container_world_warehouse_id != null;

  const formTabs = getFormTabs(
    handleCreateOrder,
    isOrderInfo,
    isTotal,
    isSalesRep,
    isWarehouseContainerWorld
  );

  const tabs = Object.values(formTabs);
  const orderShipmentPrice = order?.shipping_info?.shipments?.[0]?.price;

  useEffect(() => {
    const customer = location.state?.customer;
    if (customer) {
      handleCustomerChange(customer);
    }
  }, [location]);

  useEffect(() => {
    isCreateForm && clearCreatedOrder();
  }, [isCreateForm]);

  const resetCustomer = useAction(customersActions.resetCustomer);
  useEffect(() => {
    resetCustomer();
  }, [resetCustomer]);

  useEffect(() => {
    resetOrganization();
  }, [resetOrganization]);

  useEffect(() => {
    setNewShipment(null);
  }, [setNewShipment]);

  useEffect(() => {
    if (createdOrder?.order_items.length) {
      const priceType = createdOrder?.organization?.price_type ?? 'price';
      setOrderItems(
        keyBy(
          createdOrder?.order_items.map(item => ({
            ...item,
            ...Object.values(orderItems).find(
              i => i.product_id === item?.product?.id
            ),
            id: item?.id,
            product_price: item?.product[priceType],
            product_id: item?.product?.id,
            product_title: item?.product?.title,
            number:
              (item.number ?? 0) %
              (item.product_bottles_in_case ||
                item.product.product_bottles_in_case),
            numberCases: Math.floor(
              (item.number ?? 0) /
                (item.product_bottles_in_case ||
                  item.product.product_bottles_in_case)
            ),
          })),
          'id'
        )
      );
    }
  }, [createdOrder]);

  useEffect(() => {
    if (selectedWarehouse?.value) {
      fetchWarehouse(selectedWarehouse.value);
    }
  }, [selectedWarehouse, fetchWarehouse]);

  useEffect(() => {
    if (!isEmpty(currentCustomerInfo)) {
      if (currentCustomerInfo?.default_warehouse) {
        const warehouseId = currentCustomerInfo.default_warehouse.id;
        const warehouseTitle = currentCustomerInfo.default_warehouse.title;
        const warehouseBrandId = currentCustomerInfo.default_warehouse.brand_id;
        setSelectedWarehouse({
          label: warehouseTitle,
          value: warehouseId,
          brand_id: warehouseBrandId,
        });
        setDefaultWarehouse(true);
      } else {
        setDefaultWarehouse(false);
      }
      setCustomerInfo({
        ...currentCustomerInfo?.contact_person,
        ...currentCustomerInfo?.address,
      });
      const customerId = currentCustomerInfo?.contact_person?.id;
      if (customerId) {
        fetchPaymentTypes(customerId);
      }
    }
  }, [currentCustomerInfo]);

  useEffect(() => {
    if (currentWarehouse?.brand_id) {
      fetchBrand(currentWarehouse?.brand_id);
    }
  }, [currentWarehouse, fetchBrand]);

  useEffect(() => {
    if (order?.organization) {
      handleCustomerChange(order.organization);
    }
  }, [order]);

  const onDetailsNextClickHandler = () => {
    if (!selectedWarehouse) {
      setDetailsError('Please select Warehouse');
      return;
    }
    if (!currentCustomerInfo || isEmpty(currentCustomerInfo)) {
      setDetailsError('Please select Customer');
      return;
    }
    if (!isFilledFields) {
      setDetailsError(
        'Please make sure all required fields are entered, double check the address for missing details, and add at least one product to the order.'
      );
      return;
    }
    setDetailsError('');
    createdOrder && setActiveTab(2);
    handleCreateOrder();
  };

  const fetchOrganizations = ({ page = 1, search }) => {
    return request({
      url: `/organizations/?page=${page}&content_contains=${search}`,
    });
  };

  const loadOrganizationOptions = async (search, loadedOptions, { page }) => {
    try {
      const res = await fetchOrganizations({
        page,
        search,
      });
      return {
        options: res?.results,
        hasMore: res?.pagination?.next_page,
        additional: {
          page: page + 1,
        },
      };
    } catch (err) {
      return {
        options: [],
        hasMore: true,
        additional: { page },
      };
    }
  };

  const handleCustomerChange = e => {
    setCurrentCustomer(e);
    fetchOrganization(e.id);
  };

  const handleWarehouseChange = warehouse => {
    setSelectedWarehouse(warehouse);
  };

  const handleEmailChange = event => {
    setEmail(event.target.value.toLowerCase());
  };

  const handleDiscountChange = e => {
    const { value } = e.target;
    if (value > 100) {
      setDiscount(100);
    } else {
      setDiscount(value);
    }
  };

  const isFilledFields = React.useMemo(
    () =>
      currentCustomer &&
      selectedWarehouse?.value &&
      postalCode &&
      city &&
      state &&
      email &&
      country &&
      line1 &&
      phone &&
      Object.values(orderItems).some(
        el => el?.product_id && (el?.number > 0 || el?.numberCases > 0)
      ) &&
      selectedWarehouse?.value &&
      selectedWarehouse.is_used_as_excise_warehouse
        ? Boolean(exciseReason)
        : true,
    [
      orderItems,
      email,
      selectedWarehouse,
      phone,
      currentCustomer,
      city,
      postalCode,
      country,
      state,
      line1,
      exciseReason,
    ]
  );

  const isPaymentMethodsCorrect = React.useMemo(
    () =>
      (paymentType.apiValue === 'CARD' && !payLater && currentCard) ||
      (paymentType.apiValue !== 'CARD' && !payLater && paymentType.apiValue) ||
      (paymentType.apiValue && payLater),
    [currentCard, payLater, paymentType]
  );

  const handleCreateOrder = () => {
    const requestObject = {
      customer_id: currentCustomer?.contact_person?.id,
      organization_id: currentCustomer?.id,
      comment: '',
      city,
      country,
      line1,
      line2,
      phone,
      email,
      postal_code: postalCode,
      state,
      products: Object.values(orderItems).reduce((acc, value) => {
        const productInBottles = value?.product_bottles_in_case || 0;

        const productNumber =
          value.numberCases * productInBottles + value.number;
        if (
          value.product_id &&
          (value.number || value.numberCases) &&
          productInBottles
        ) {
          if (value?.resourcetype) {
            acc.push({
              id: value.id,
              product_id: value.product_id,
              number: productNumber,
            });
          } else {
            acc.push({
              product_id: value.product_id,
              number: productNumber,
            });
          }
        }
        return acc;
      }, []),
    };
    if (discount) {
      requestObject.discount_percentage = Number(discount);
    }
    if (!createdOrder) {
      requestObject.warehouse_id = selectedWarehouse?.value;
    }
    if (isEmpty(createdOrder)) {
      createOrder({
        ...requestObject,
      });
    } else {
      updateOrder({
        id: createdOrder?.id,
        ...getRedactedValues(order, requestObject),
      });
    }
  };

  const handlePay = () => {
    const orderId = createdOrder?.id;
    let payload = {
      id: orderId,
      expected_payment_type: paymentType.apiValue,
    };
    if (createdShipment) {
      payload = {
        ...payload,
        shipment_rate_ext_id: createdShipment?.id,
        shipment_ext_id: createdShipment?.shipment_id,
        shipping_company_id: createdShipment?.brand_shipping_company_id,
        price: createdShipment?.price,
      };
    }
    patchOrder(payload);
    capturePayment({ id: orderId, excise_reason: exciseReason });
  };

  const handleApprove = () => {
    patchOrder({
      id: createdOrder?.id,
      expected_payment_type: paymentType.apiValue,
    });
    confirmCommercialOrder({
      id: createdOrder?.id,
      email: createdOrder?.shipping_info?.email,
    });
  };

  useEffect(() => {
    return () => {
      setActiveTab(1);
      clearCreatedOrder();
    };
  }, [setActiveTab, clearCreatedOrder]);

  if (order && order?.status !== 'FULFILLED') {
    return <Redirect to="/commercial-orders/" />;
  }

  return (
    <AvForm>
      <Tabs
        tabs={tabs}
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        handleCreateOrder={handleCreateOrder}
      />
      <TabContent
        activeTab={activeTab}
        className="twitter-bs-wizard-tab-content"
      >
        <TabPane tabId={formTabs.details.id}>
          <Row>
            <Col md="4">
              <FormGroup>
                <Label required htmlFor="organization_id">
                  Commercial Customers and Leads
                </Label>
                <AsyncPaginate
                  name="organization_id"
                  isSearchable
                  debounceTimeout={1000}
                  defaultValue={currentCustomer}
                  value={currentCustomer}
                  loadOptions={loadOrganizationOptions}
                  onChange={handleCustomerChange}
                  additional={{
                    page: 1,
                  }}
                  getOptionValue={option => option.id}
                  getOptionLabel={option =>
                    option.order_display_title || option.title
                  }
                  menuPlacement="bottom"
                  maxMenuHeight={190}
                />
              </FormGroup>
            </Col>
            <Col md="4">
              <FormGroup>
                <Label required htmlFor="warehouse_id">
                  Warehouse
                </Label>
                <WarehousesPaginatedField
                  value={selectedWarehouse}
                  isMulti={false}
                  onChange={handleWarehouseChange}
                  isDisabled={
                    !isCreateForm || (isDefaultWarehouse && isSalesRep)
                  }
                />
              </FormGroup>
              {Boolean(
                selectedWarehouse &&
                  selectedWarehouse?.is_used_as_excise_warehouse
              ) && (
                <SelectField
                  className="mb-2"
                  name="excise_reason"
                  onChange={option => setExciseReason(option.value)}
                  label="Excise Reason*"
                  options={COMMERCIAL_ORDERS_EXCISE_REASON_OPTIONS}
                  getOptionValue={option => option.value}
                  getOptionLabel={option => option.label}
                />
              )}
            </Col>
            {isOwnerOrManagerRole && (
              <Col className="mt-4" md={4}>
                <Link to="/commercial-customers/create">
                  <Button color="success">
                    <i className="mdi mdi-plus font-size-18 mr-1 align-middle" />
                    New Customer
                  </Button>
                </Link>
              </Col>
            )}
          </Row>
          {isPaymentAlertVisible && (
            <Row>
              <Col md={12}>
                <PaymentAlert
                  to={`/commercial-customers/${currentCustomerInfo.id}/edit`}
                />
              </Col>
            </Row>
          )}
          {currentCustomer && selectedWarehouse && isPaymentAvailable && (
            <>
              <Row>
                <Col md="4">
                  <EmailField
                    isRequired
                    label="Email"
                    name="email"
                    errorMessage="Enter Email"
                    onChange={handleEmailChange}
                    value={email}
                  />
                </Col>
                <Col md="8">
                  <LocationSearchInput
                    model={
                      currentCustomerInfo?.address || order?.shipping_info || {}
                    }
                    title="Address*"
                    country={country}
                    state={state}
                    postalCode={postalCode}
                    city={city}
                    line1={line1}
                    line2={line2}
                    setCountry={setCountry}
                    setState={setState}
                    setPostalCode={setPostalCode}
                    setCity={setCity}
                    setLine1={setLine1}
                    setLine2={setLine2}
                  />
                </Col>
                <Col md="4">
                  <PhoneField
                    isRequired
                    name="phone"
                    placeholder="Phone"
                    label="Phone"
                    errorMessage="Enter Phone"
                    value={phone}
                    onChange={setPhone}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <OrderItemsTable
                    warehouseId={currentWarehouse.id}
                    orderItems={orderItems}
                    onOrderItemsChange={setOrderItems}
                    priceType={
                      currentCustomerInfo?.price_type ??
                      order?.organization?.price_type
                    }
                    isCases
                  />
                </Col>
              </Row>
              <Row>
                {createdOrder && (
                  <>
                    {Object.keys(createdOrder.applied_fees).map(fee => (
                      <Col lg="3">
                        <CardItem
                          key={fee}
                          title={fee}
                          value={`$${formatPrice(
                            createdOrder?.applied_fees[fee] ?? 0
                          )}`}
                        />
                      </Col>
                    ))}
                  </>
                )}
                {!!orderShipmentPrice && (
                  <Col md={3}>
                    <CardItem
                      title="Shipment price"
                      value={`$ ${orderShipmentPrice}`}
                    />
                  </Col>
                )}
              </Row>
            </>
          )}
          {isFilledFields && (
            <>
              {createdOrder && (
                <>
                  <Row>
                    {isOwnerOrManagerRole && (
                      <Col md="4">
                        <FormGroup>
                          <Label htmlFor="discount">Discount Percentage</Label>
                          <input
                            name="discount"
                            placeholder="Discount"
                            type="text"
                            className="form-control"
                            id="discount"
                            value={discount}
                            onFocus={e =>
                              e.target.addEventListener(
                                'wheel',
                                function(evt) {
                                  evt.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                            onChange={handleDiscountChange}
                          />
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col xs="12" s="8" md="6" l="4">
                      <Card>
                        <CardBody>
                          <OrderInfoBlock
                            title="Product Subtotal"
                            value={`${formatPrice(
                              parseFloat(
                                createdOrder?.order_items?.reduce(
                                  (sum, oi) =>
                                    sum + oi.product_price * oi.number,
                                  0
                                )
                              ) || 0
                            )} ${createdOrder?.total_price_currency}`}
                          />

                          {createdOrder?.total_order_discount > 0 && (
                            <OrderInfoBlock
                              title="Discounts"
                              value={`-${formatPrice(
                                parseFloat(
                                  createdOrder?.total_order_discount
                                ) || 0
                              )} ${createdOrder?.total_price_currency}`}
                            />
                          )}

                          {createdOrder?.applied_fees && (
                            <>
                              {Object.keys(createdOrder?.applied_fees).map(
                                fee => (
                                  <OrderInfoBlock
                                    title={fee}
                                    value={`${formatPrice(
                                      createdOrder?.applied_fees[fee] ?? 0
                                    )} ${createdOrder?.total_price_currency}`}
                                  />
                                )
                              )}
                            </>
                          )}

                          {createdShipment && (
                            <OrderInfoBlock
                              title="Shipping"
                              value={`${
                                createdShipment.free_shipping
                                  ? '0.00'
                                  : createdShipment?.price.toFixed(2)
                              } ${createdOrder?.total_price_currency}`}
                            />
                          )}
                          <hr />

                          <OrderInfoBlock
                            title="Total"
                            value={`${formatPrice(createdOrder?.total_price) ||
                              0} CAD`}
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
          <div>
            <small className="text-danger my-2 d-block">{detailsError}</small>
            {createdOrder && (
              <Button
                color="primary"
                onClick={() => {
                  handleCreateOrder();
                }}
                disabled={orderActionIsPending}
                className="mt-2 mr-2"
              >
                {orderActionIsPending && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="mr-1"
                  />
                )}
                Update
              </Button>
            )}
            <Button
              color="primary"
              onClick={onDetailsNextClickHandler}
              className="mt-2"
            >
              {createdOrder ? 'Next Step' : 'Create Order'}
            </Button>
          </div>
        </TabPane>
        {'shipping' in formTabs && (
          <TabPane tabId={formTabs.shipping.id}>
            {activeTab === 2 && (
              <Shipments
                orderType="Commercial"
                order={createdOrder}
                buttonTitle="Next"
                nextAction={() => {
                  updateOrder({
                    id: createdOrder?.id,
                  });
                  setActiveTab(3);
                }}
              />
            )}
          </TabPane>
        )}
        <TabPane tabId={formTabs.summary.id}>
          {createdOrder && (
            <OrderInfo
              organization={currentCustomerInfo}
              order={createdOrder}
              createdShipment={createdShipment}
              handleCreateOrder={handleCreateOrder}
              currentCard={currentCard}
              payLater={payLater}
              onPay={handlePay}
              onApprove={handleApprove}
            />
          )}
        </TabPane>
      </TabContent>
    </AvForm>
  );
};

CommercialOrderForm.propTypes = {
  order: PropTypes.object,
  isCreateForm: PropTypes.object,
};

export default CommercialOrderForm;
