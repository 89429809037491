import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';

import s from './DataTable.scss';

const DataTable = ({
  isPending,
  productsPresets,
  setProductsPresetID,
  setIsOpenConfirmationModal,
}) => {
  const handleDelete = (e, id) => {
    e.preventDefault();
    setIsOpenConfirmationModal(true);
    setProductsPresetID(id);
  };

  return (
    <>
      <thead>
        <tr>
          <th data-priority="1">ID</th>
          <th data-priority="1">SKU</th>
          <th data-priority="1">Title</th>
          <th data-priority="1">Price</th>
          <th data-priority="1">Brand</th>
          <th data-priority="1">Warehouse</th>
          <th data-priority="3">Action</th>
        </tr>
      </thead>
      <tbody className={cx({ [s.preloader]: isPending })}>
        {productsPresets?.map(item => (
          <tr key={item.id}>
            <td>
              <Link
                to={`/products-presets/${item.id}/edit`}
                className="text-dark font-weight-bold"
              >
                {item.id}
              </Link>
            </td>
            <td>
              <Link
                to={`/products-presets/${item.id}/edit`}
                className="text-dark font-weight-bold"
              >
                {item?.sku ?? '-'}
              </Link>
            </td>
            <td>
              <Link
                to={`/products-presets/${item.id}/edit`}
                className="text-dark font-weight-bold"
              >
                {item.title}
              </Link>{' '}
            </td>
            <td>
              <Link
                to={`/products-presets/${item.id}/edit`}
                className="text-dark font-weight-bold"
              >
                {item.price} {item.price_currency}
              </Link>
            </td>
            <td>
              <Link
                to={`/products-presets/${item.id}/edit`}
                className="text-dark font-weight-bold"
              >
                {item.brand_title}
              </Link>
            </td>
            <td>
              <Link
                to={`/products-presets/${item.id}/edit`}
                className="text-dark font-weight-bold"
              >
                {item.warehouse_title}{' '}
              </Link>
            </td>
            <td>
              <>
                <Link
                  to={`/products-presets/${item.id}/edit`}
                  className="mr-3 text-primary"
                  id={`edit${item.id}`}
                >
                  <i className="mdi mdi-pencil font-size-18" />
                </Link>
                <UncontrolledTooltip placement="top" target={`edit${item.id}`}>
                  Edit
                </UncontrolledTooltip>
                <Link
                  to="#"
                  onClick={e => handleDelete(e, item.id)}
                  className="text-danger"
                  id={`delete${item.id}`}
                >
                  <i className="mdi mdi-trash-can font-size-18" />
                </Link>
                <UncontrolledTooltip
                  placement="top"
                  target={`delete${item.id}`}
                >
                  Delete
                </UncontrolledTooltip>
              </>
            </td>
          </tr>
        ))}
      </tbody>
    </>
  );
};

DataTable.propTypes = {
  isPending: PropTypes.bool,
  productsPresets: PropTypes.array,
  setProductsPresetID: PropTypes.func,
  setIsOpenConfirmationModal: PropTypes.func,
};

export default DataTable;
