import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { AvForm } from 'availity-reactstrap-validation';
import PageWrapper from 'components/PageWrapper';
import styles from '../authentication.scss';
import EmailField from 'components/Fields/EmailField';
import TextField from 'components/Fields/TextField';
import PasswordField from 'components/Fields/PasswordField';

const Register = ({ title }) => {
  const handleSubmit = () => console.log('Submit');
  const [email, setEmail] = useState('admin@themesdesign.in');

  const handleEmailChange = event => {
    setEmail(event.target.value.toLowerCase());
  };
  const username = 'admin@themesdesign.in';
  const password = '123456';

  return (
    <PageWrapper title={title}>
      <div className={styles.root}>
        <div className="text-center">
          <h4 className="font-size-18 mt-4">Register account</h4>
        </div>

        <div className="p-2 mt-5">
          <AvForm onValidSubmit={handleSubmit} className="form-horizontal">
            <EmailField
              name="email"
              label="Email"
              value={email}
              isRequired
              placeholder="Enter email"
              className="auth-form-group-custom mb-4"
              icon={<i className="ri-mail-line auti-custom-input-icon" />}
              onChange={handleEmailChange}
            />
            <TextField
              name="username"
              label="Username"
              icon={<i className="ri-user-2-line auti-custom-input-icon" />}
              className="auth-form-group-custom mb-4"
              placeholder="Enter username"
              value={username}
            />
            <PasswordField
              className="auth-form-group-custom mb-4"
              name="password"
              icon={<i className="ri-lock-2-line auti-custom-input-icon" />}
              value={password}
              label="Password"
              placeholder="Enter password"
            />

            <div className="text-center">
              <Button
                color="primary"
                className="w-md waves-effect waves-light"
                type="submit"
              >
                Register
              </Button>
            </div>

            <div className="mt-4 text-center">
              <p className="mb-0">
                By registering you agree to the Nazox{' '}
                <Link to="#" className="text-primary">
                  Terms of Use
                </Link>
              </p>
            </div>
          </AvForm>
        </div>

        <div className="mt-5 text-center">
          <p>
            Already have an account?{' '}
            <Link to="/login" className="font-weight-medium text-primary">
              Login
            </Link>
          </p>
        </div>
      </div>
    </PageWrapper>
  );
};

Register.propTypes = {
  title: PropTypes.string,
};

export default Register;
