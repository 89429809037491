import { createSelector } from 'reselect';

export const rootSelector = state => state.commissions;

export const isPendingSelector = createSelector(
  rootSelector,
  ({ isPending }) => isPending
);

export const commissionsSelector = createSelector(
  rootSelector,
  ({ commissions }) => commissions
);

export const disputedCommissionsCountSelector = createSelector(
  rootSelector,
  ({ disputedCommissionsCount }) => disputedCommissionsCount
);

export const currentCommissionSelector = createSelector(
  rootSelector,
  ({ currentCommission }) => currentCommission
);

export const paginationSelector = createSelector(
  rootSelector,
  ({ pagination }) => pagination
);
