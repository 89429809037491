import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './ConfirmationPopup.scss';
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PopupBase from '../PopupBase';

const ConfirmationPopup = ({
  onSaveClick,
  submitButtonTitle,
  cancelButtonTitle,
  submitButtonType,
  submitButtonColor,
  submitButtonDisabled,
  title,
  children,
  active,
  setActive,
  showCloseButton,
  onCancel,
}) => {
  const toggleModal = useCallback(() => {
    setActive(!active);
    if (onCancel) {
      onCancel();
    }
  }, [setActive, active, onCancel]);

  const handleSaveClick = useCallback(() => {
    if (onSaveClick) {
      onSaveClick();
    }
    toggleModal();
  }, [onSaveClick, toggleModal]);

  return (
    <PopupBase className={styles.popup} onClose={toggleModal}>
      <ModalHeader toggle={toggleModal}>{title}</ModalHeader>
      <ModalBody>{children || <h5>This action cannot be undone</h5>}</ModalBody>
      <ModalFooter>
        {showCloseButton && (
          <Button
            type="button"
            onClick={toggleModal}
            color="light"
            className="waves-effect"
          >
            {cancelButtonTitle}
          </Button>
        )}
        {onSaveClick ? (
          <Button
            className="waves-effect waves-light"
            type={submitButtonType}
            color={submitButtonColor}
            onClick={handleSaveClick}
            disabled={submitButtonDisabled}
          >
            {submitButtonTitle}
          </Button>
        ) : (
          <Button
            className="waves-effect waves-light"
            type={submitButtonType}
            color={submitButtonColor}
            disabled={submitButtonDisabled}
          >
            {submitButtonTitle}
          </Button>
        )}
      </ModalFooter>
    </PopupBase>
  );
};

ConfirmationPopup.propTypes = {
  onSaveClick: PropTypes.func,
  children: PropTypes.any,
  title: PropTypes.string,
  submitButtonTitle: PropTypes.string,
  cancelButtonTitle: PropTypes.string,
  submitButtonColor: PropTypes.string,
  submitButtonType: PropTypes.string,
  submitButtonDisabled: PropTypes.bool,
  active: PropTypes.bool,
  setActive: PropTypes.func,
  showCloseButton: PropTypes.bool,
  onCancel: PropTypes.func,
};

ConfirmationPopup.defaultProps = {
  title: 'Are you sure you want to delete this item?',
  submitButtonTitle: 'Delete',
  cancelButtonTitle: 'Close',
  submitButtonColor: 'danger',
  submitButtonType: 'button',
  submitButtonDisabled: false,
  showCloseButton: true,
};

export default memo(ConfirmationPopup);
