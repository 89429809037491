import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip, Button } from 'reactstrap';

import styles from './DataTable.scss';
import GrantManagerCodePopup from 'components/Popup/GrantManagerCodePopup';

const isUserActive = user => {
  return user?.is_active;
};

const DataTable = ({
  isPending,
  users,
  grantManagerPrivileges,
  onArchiveClick,
  onRestoreClick,
  onConfirmationEmailClick,
  currentUserId,
}) => {
  const [modal, setModal] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [pin, setPin] = useState('');
  const [error, setError] = useState(null);

  const toggle = () => {
    setPin('');
    setCurrentId(null);
    setError(null);
    setModal(!modal);
  };

  const handlePrivileges = () => {
    if (pin.split('').some(symbol => symbol === ' ') || pin.length < 4) {
      setError('Please enter code');
      return;
    }

    grantManagerPrivileges({ id: Number(currentId), manager_code: pin });
    toggle();
  };

  const formatRoleName = role => {
    return role
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  return (
    <>
      <thead>
        <tr>
          <th data-priority="1">ID</th>

          <th data-priority="1">Email</th>
          <th data-priority="1">Name</th>
          <th data-priority="1">Roles</th>
          <th data-priority="3">Action</th>
        </tr>
      </thead>
      <tbody className={isPending && styles.preloader}>
        {users?.map(item => (
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.email}</td>
            <td>
              {item.first_name}&nbsp;{item.last_name}
            </td>
            <td className={styles.capitalize}>
              {item.roles.map(formatRoleName).join(', ')}
            </td>
            <td>
              <>
                <Link
                  to={`/users/${item.id}/edit`}
                  className="mr-3 text-primary"
                  id={`edit${item.id}`}
                >
                  <i className="mdi mdi-pencil font-size-18" />
                </Link>
                <UncontrolledTooltip placement="top" target={`edit${item.id}`}>
                  Edit
                </UncontrolledTooltip>
              </>
              {isUserActive(item) && item.id !== currentUserId ? (
                <Button
                  onClick={() => onArchiveClick?.(item)}
                  color="primary"
                  className="mr-3"
                >
                  Archive
                </Button>
              ) : (
                !isUserActive(item) && (
                  <Button
                    onClick={() => onRestoreClick?.(item)}
                    color="success"
                    className="mr-3"
                  >
                    Restore
                  </Button>
                )
              )}

              {!item?.confirmed_at && !!item?.confirmation_token && (
                <Button
                  onClick={() =>
                    onConfirmationEmailClick?.(item.confirmation_token)
                  }
                  className="my-1"
                  color="primary"
                >
                  Send Confirmation Email
                </Button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
      {modal && (
        <GrantManagerCodePopup
          onClose={toggle}
          handlePrivileges={handlePrivileges}
          error={error}
          setPin={setPin}
        />
      )}
    </>
  );
};

DataTable.propTypes = {
  isPending: PropTypes.bool,
  users: PropTypes.array,
  grantManagerPrivileges: PropTypes.func,
  onArchiveClick: PropTypes.func,
  onRestoreClick: PropTypes.func,
  onConfirmationEmailClick: PropTypes.func,
  currentUserId: PropTypes.number,
};

export default DataTable;
