import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from 'reactstrap';

import Filters from 'components/Filters';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';
import PaginationTable from 'components/PaginationTable';

import DataTable from './DataTable';

import useQuery from 'hooks/useQuery';
import { getProductsQuery } from 'utils/getProductsQuery';

import useAction from 'hooks/useAction';
import { actions as ordersActions } from 'models/orders/slice';
import useSelector from 'hooks/useSelector';
import { format } from 'date-fns';
import {
  orderSelector,
  ordersSelector,
  isReportPendingSelector,
  isPendingSelector as ordersIsPendingSelector,
  pendingFulfillmentCommercialOrdersSelector,
  paginationSelector,
  pendingFulfillmentCommercialOrdersPaginationSelector,
} from 'models/orders/selectors';
import { rolesSelector } from 'models/user/selectors';
import { isPendingSelector as brandsIsPendingSelector } from 'models/brands/selectors';

import { ORDER_PAYMENT_OPTIONS, UNPAID_STATUSES } from 'constants';
import SelectPaymentMethodPopup from 'components/Popup/SelectPaymentMethodPopup';
import SelectShippingPopup from 'components/Popup/SelectShippingPopup';
import { COMMERCIAL_ORDER_DISPLAY_STATUS } from './constants';
import { PERMISSION_TYPES } from 'constants/permissions';
import CommentPopup from 'components/Popup/CommentPopup';
import { useDataPagination } from 'hooks/useDataPagination';
import styles from './CommercialOrdersSummary.scss';

const ORDER_TYPES = [
  { apiValue: 'CommercialOrder', userValue: 'Commercial Order' },
  { apiValue: 'ContainerWorldOrder', userValue: 'ContainerWorld Order' },
];

const CommercialOrdersSummary = ({ title }) => {
  const fetchCommercialOrders = useAction(ordersActions.fetchCommercialOrders);
  const fetchPendingFulfillmentCommercialOrders = useAction(
    ordersActions.fetchPendingFulfillmentCommercialOrders
  );
  const changeOrderStatus = useAction(ordersActions.changeOrderStatus);

  const brandsIsPending = useSelector(brandsIsPendingSelector);
  const handleFetchOrderShipmentLabel = useAction(
    ordersActions.fetchOrderShipmentLabel
  );
  const handleFetchOrderShipmentPublicUrl = useAction(
    ordersActions.fetchOrderShipmentPublicUrl
  );
  const orders = useSelector(ordersSelector);
  const pendingFulfillmentOrders = useSelector(
    pendingFulfillmentCommercialOrdersSelector
  );
  const fetchReport = useAction(ordersActions.fetchCommercialReport);
  const printPackingSlip = useAction(ordersActions.printFile);
  const fetchOrder = useAction(ordersActions.fetchOrder);
  const ordersIsPending = useSelector(ordersIsPendingSelector);
  const isReportPending = useSelector(isReportPendingSelector);
  const pagination = useSelector(paginationSelector);
  const pendingFulfillmentPagination = useSelector(
    pendingFulfillmentCommercialOrdersPaginationSelector
  );
  const roles = useSelector(rolesSelector);

  const query = useQuery();

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [filters, setFilters] = useState({});

  const shipOrderDetails = useSelector(orderSelector);

  const {
    currentPage: fulfillmentPage,
    ...fulfillmentPagination
  } = useDataPagination(pendingFulfillmentPagination);
  const {
    currentPage,
    sortField,
    sortDirection,
    ...completedPagination
  } = useDataPagination(pagination, {
    currentPage: query.get('page'),
    sortField: query.get('sort_field'),
    sortDirection: query.get('sort_direction'),
  });
  const [dateRange, setDateRange] = useState({});

  const [modal, setModal] = useState(false);
  const [fulfilledOrderId, setFulfilledOrderId] = useState(null);
  const [showShipOrderModal, setShowShipOrderModal] = useState(false);
  const [isChargeMode, setIsChargeMode] = useState(false);

  const isPending = ordersIsPending || brandsIsPending;
  const isSalesRep =
    Array.isArray(roles) &&
    roles.length === 1 &&
    roles.includes(PERMISSION_TYPES.SALES_REP);

  useEffect(() => {
    let result = {};
    Array.from(query.entries()).forEach(params => {
      result = { ...result, [params[0]]: Number(params[1]) || params[1] };
    });
    if (result.product_ids && result.product_labels) {
      result.products = getProductsQuery(
        String(result.product_ids),
        String(result.product_labels)
      );
    }
    setFilters({
      ...filters,
      ...result,
      excluded_statuses: [...UNPAID_STATUSES, 'PENDING_FULFILLMENT'],
    });
    setIsFirstLoad(false);
  }, []);

  useEffect(() => {
    if (!isFirstLoad) {
      fetchCommercialOrders({
        ...filters,
        page: currentPage,
        sort_field: sortField,
        sort_direction: sortDirection,
        date_range_start: dateRange.startDate,
        date_range_end: dateRange.endDate,
        resourcetypes: filters.resourcetypes
          ? [filters.resourcetypes]
          : ['CommercialOrder', 'ContainerWorldOrder'],
        setQuery: true,
        order_contains: filters.order_contains,
      });
    }
  }, [
    fetchCommercialOrders,
    currentPage,
    filters,
    sortDirection,
    sortField,
    dateRange,
    isSalesRep,
  ]);

  useEffect(() => {
    completedPagination.setCurrentPage(1);
    fulfillmentPagination.setCurrentPage(1);
  }, [filters, sortDirection, sortField, dateRange]);

  useEffect(() => {
    fetchPendingFulfillmentCommercialOrders({
      page: fulfillmentPage,
      ...filters,
      excluded_statuses: [],
      resourcetypes: filters.resourcetypes
        ? [filters.resourcetypes]
        : ['CommercialOrder', 'ContainerWorldOrder'],
    });
  }, [fulfillmentPage, filters]);

  useEffect(() => {
    setFulfilledOrderId(null);
  }, [pendingFulfillmentOrders]);

  const onFulfillOrderHandler = (_, { comment }) => {
    if (fulfilledOrderId) {
      changeOrderStatus({
        id: fulfilledOrderId,
        status: 'SHIPPED',
        comment,
        orderType: 'CommercialOrder',
      });
      setFulfilledOrderId(null);
    }
  };

  const handleDownload = id => {
    fetchReport({ id });
  };

  const handlePrintPackageSlip = id => {
    printPackingSlip({ orders_ids: [id], packing_slip: true });
  };

  // TODO: move functions to utils

  const getStartDate = date => {
    date.setHours(0, 0, 0, 0);
    return date;
  };

  const getEndDate = date => {
    date.setHours(23, 59, 59, 999);
    return date;
  };

  const handleDateChange = value => {
    setDateRange({
      startDate: format(
        getStartDate(value.startDate),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
      ),
      endDate: format(
        getEndDate(value.endDate),
        "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
      ),
    });
  };

  const openPaymentModal = orderId => {
    fetchOrder(orderId);
    setModal(true);
  };

  const openShipModal = orderId => {
    fetchOrder(orderId);
    setShowShipOrderModal(true);
  };

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      {fulfilledOrderId != null && (
        <CommentPopup
          title="Fulfill Order"
          onSubmit={onFulfillOrderHandler}
          onClose={() => setFulfilledOrderId(null)}
        />
      )}
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody className="pt-0">
              <Row className="d-flex justify-content-between align-items-center mb-4 mt-4">
                <Col lg="12">
                  <Filters
                    filters={filters}
                    setFilters={setFilters}
                    hasBrandsFilter
                    hasTextSearch
                    hasDateRangeFilter
                    hasPerPageFilter
                    setCurrentPage={completedPagination.setCurrentPage}
                    setDateRange={handleDateChange}
                    disabled={isPending}
                    hasFilterByStatus
                    statusFields={COMMERCIAL_ORDER_DISPLAY_STATUS}
                    hasFilterByOrderType
                    typeFields={ORDER_TYPES}
                    hasProductsFilter
                    hasCreateItem
                    createItemLink="/commercial-orders/create"
                    hasOrderContains
                    hasFilterByPaid
                    placeholder="Search... (By invoice number, commercial customer email/title/ID, CW order ID)"
                  />
                </Col>
              </Row>
              <div className="mb-3">
                <h4 className="mb-2">Pending fulfillment</h4>
                {pendingFulfillmentOrders.length ? (
                  <PaginationTable
                    {...fulfillmentPagination}
                    pagination={pendingFulfillmentPagination}
                  >
                    <DataTable
                      isPending={isPending}
                      orders={pendingFulfillmentOrders}
                      onFulfill={setFulfilledOrderId}
                      openShipModal={openShipModal}
                    />
                  </PaginationTable>
                ) : (
                  <div className={styles.infoText}>
                    All Commercial Orders Fulfilled
                  </div>
                )}
              </div>
              <div>
                <h4 className="mb-2">Completed</h4>
                <PaginationTable
                  {...completedPagination}
                  pagination={pagination}
                >
                  <DataTable
                    {...completedPagination}
                    sortField={sortField}
                    sortDirection={sortDirection}
                    isPending={isPending}
                    openPaymentModal={openPaymentModal}
                    handleFetchOrderShipmentLabel={
                      handleFetchOrderShipmentLabel
                    }
                    handleFetchOrderShipmentPublicUrl={
                      handleFetchOrderShipmentPublicUrl
                    }
                    orders={orders}
                    onDownload={handleDownload}
                    onPrintPackageSlip={handlePrintPackageSlip}
                    downloadDisabled={isReportPending}
                    orderType={filters?.resourcetypes}
                    isSortable
                  />
                  {modal && (
                    <SelectPaymentMethodPopup
                      isOpen={modal}
                      setIsOpen={setModal}
                      options={ORDER_PAYMENT_OPTIONS}
                    />
                  )}

                  {showShipOrderModal && (
                    <SelectShippingPopup
                      isOpen={showShipOrderModal}
                      setIsOpen={setShowShipOrderModal}
                      withFooter={false}
                      withTotal={false}
                      setIsChargeMode={setIsChargeMode}
                      isChargeMode={isChargeMode}
                      isOnlyShipping
                      order={shipOrderDetails}
                    />
                  )}
                </PaginationTable>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageWrapper>
  );
};

CommercialOrdersSummary.propTypes = {
  title: PropTypes.string,
};

export default CommercialOrdersSummary;
