import React, { useEffect } from 'react';
import './App.scss';
import PropTypes from 'prop-types';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import AppRouter from 'components/AppRouter';
import RotateScreen from 'components/RotateScreen';
import 'styles/theme.scss';
import useAction from 'hooks/useAction';
import { actions as userActions } from 'models/user/slice';
import { actions as commissionsActions } from 'models/commissions/slice';
import useSelector from 'hooks/useSelector';
import {
  emailSelector,
  tokenSelector,
  isOnlyHostSelector,
  isOnlyBookkeeperSelector,
} from 'models/user/selectors';
import AppUpdate from './AppUpdate';

const App = ({ routes }) => {
  const getCurrentUser = useAction(userActions.getCurrentUser);
  const fetchDisputedCommissions = useAction(
    commissionsActions.fetchDisputedCommissions
  );
  const token = useSelector(tokenSelector);
  const email = useSelector(emailSelector);
  const isHost = useSelector(isOnlyHostSelector);
  const isBookkeeper = useSelector(isOnlyBookkeeperSelector);

  useEffect(() => {
    if (!token) return;
    if (!isHost && !isBookkeeper) fetchDisputedCommissions();
    if (!email) getCurrentUser();
  }, [token, email, isHost]);

  return (
    <>
      <AppUpdate />
      <RotateScreen />
      <ReactNotification />
      <AppRouter routes={routes} />
    </>
  );
};

App.propTypes = {
  routes: PropTypes.array.isRequired,
};

export default App;
