import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { memo, useCallback } from 'react';

import SortArrows from 'components/SortArrows';
import { EMAIL_TEMPLATES } from 'constants/emailTemplates';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { Button, CustomInput, UncontrolledTooltip } from 'reactstrap';
import handleSort from 'utils/handleSort';
import ActiveSubscriptions from './ActiveSubscriptions';
import styles from './DataTable.scss';

const LinkedCell = ({ value, link }) => (
  <td>
    <Link className="text-dark font-weight-bold" to={link}>
      {value}
    </Link>
  </td>
);

const getCustomerLink = customer => {
  return `/wineclub-customers/${
    customer.id !== 0 ? customer.id : `abstract/${customer.email}`
  }`;
};

const getEmailTemplateName = template => {
  const vanityTemplate = template.replace('v1/', '');
  return EMAIL_TEMPLATES[vanityTemplate] || template;
};

const isCustomerNotArchived = customer =>
  customer?.wine_club_members?.filter(({ is_active }) => is_active).length !==
  0;

const DataTable = ({
  isPending,
  customers,
  sortField,
  sortDirection,
  setSortField,
  setSortDirection,
  isSelectable,
  isSelectedAll,
  selectedCustomers,
  recoverPasswordSent,
  onChange,
  onArchiveClick,
  onRestoreClick,
  onSelectAll,
  onRecoverPasswordClick,
}) => {
  const onSelectHandler = useCallback(
    ({ id }) => {
      if (selectedCustomers.indexOf(id) > -1) {
        onChange(selectedCustomers.filter(item => item !== id));
        return;
      }
      onChange([...selectedCustomers, id]);
    },
    [selectedCustomers]
  );

  const handleClickSelectedAll = useCallback(event => {
    onSelectAll(event.target.checked);
  });

  const isSelected = item => {
    return selectedCustomers.indexOf(item.id) > -1;
  };

  const isPasswordSent = id => {
    return recoverPasswordSent.indexOf(id) > -1;
  };

  return (
    <>
      <thead>
        <tr>
          {isSelectable && (
            <th>
              <div
                className={cx(
                  styles.checkbox,
                  'custom-control custom-checkbox'
                )}
              >
                <CustomInput
                  type="checkbox"
                  id="confirm"
                  name="confirm"
                  checked={isSelectedAll}
                  onClick={handleClickSelectedAll}
                />
              </div>
            </th>
          )}
          <th data-priority="1">ID</th>
          <th
            className="cursor-pointer text-nowrap"
            onClick={() =>
              handleSort(
                'first_name',
                setSortField,
                setSortDirection,
                sortDirection
              )
            }
            data-priority="3"
          >
            First Name
            <SortArrows
              isActive={sortField === 'first_name'}
              sortDirection={sortDirection}
            />
          </th>
          <th
            className="cursor-pointer text-nowrap"
            onClick={() =>
              handleSort(
                'last_name',
                setSortField,
                setSortDirection,
                sortDirection
              )
            }
            data-priority="3"
          >
            Last Name
            <SortArrows
              isActive={sortField === 'last_name'}
              sortDirection={sortDirection}
            />
          </th>
          <th
            className="cursor-pointer text-nowrap"
            onClick={() =>
              handleSort('email', setSortField, setSortDirection, sortDirection)
            }
            data-priority="3"
          >
            Email
            <SortArrows
              isActive={sortField === 'email'}
              sortDirection={sortDirection}
            />
          </th>
          <th data-priority="1">Active subscriptions</th>
          <th data-priority="2">Customer status</th>
          <th data-priority="3">Action</th>
        </tr>
      </thead>
      <tbody className={cx(isPending && styles.preloader)}>
        {customers?.map(item => (
          <tr key={item.id}>
            {isSelectable && (
              <td>
                <div
                  className={cx(
                    styles.checkbox,
                    !isCustomerNotArchived(item) && styles.disabled,
                    'custom-control custom-checkbox'
                  )}
                >
                  <CustomInput
                    type="checkbox"
                    onChange={event => onSelectHandler(item, event)}
                    checked={isSelected(item) && isCustomerNotArchived(item)}
                    id={item.id}
                    name={item.id}
                    disabled={!isCustomerNotArchived(item)}
                  />
                </div>
              </td>
            )}
            <LinkedCell value={item.id} link={getCustomerLink(item)} />
            <LinkedCell value={item.first_name} link={getCustomerLink(item)} />
            <LinkedCell value={item.last_name} link={getCustomerLink(item)} />
            <LinkedCell value={item.email} link={getCustomerLink(item)} />
            <td className={styles.tiers}>
              <ActiveSubscriptions wineclubs={item.wine_club_members} />
            </td>
            <td className={styles.lastEmail}>
              {item?.last_email?.sent_at
                ? format(new Date(item.last_email.sent_at), 'yyyy-MM-dd')
                : '-'}
              <br />
              {item?.last_email?.template
                ? getEmailTemplateName(item.last_email.template)
                : '-'}
              <br />
              {item?.last_email?.open && 'Opened'}
              <br />
              {!!item?.last_email?.clicks &&
                `${item.last_email.clicks.length} clicks`}
            </td>
            <td style={{ width: 330 }}>
              {item.id !== 0 && (
                <>
                  <Link
                    to={`/wineclub-customers/${item.id}/edit`}
                    className="mr-3 text-primary"
                    id={`edit${item.id}`}
                  >
                    <i className="mdi mdi-pencil font-size-18" />
                  </Link>
                  <UncontrolledTooltip
                    placement="top"
                    target={`edit${item.id}`}
                  >
                    Edit
                  </UncontrolledTooltip>
                </>
              )}
              {item.id !== 0 && (
                <>
                  {isCustomerNotArchived(item) ? (
                    <Button
                      type="button"
                      t
                      onClick={() => onArchiveClick(item.id)}
                      color="primary"
                    >
                      Archive
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      onClick={() => onRestoreClick(item.id)}
                      color="success"
                    >
                      Restore
                    </Button>
                  )}
                  {isSelected(item) && isCustomerNotArchived(item) && (
                    <Button
                      style={{
                        backgroundColor: '#ff7e03',
                        borderColor: '#ff7e03',
                      }}
                      className={styles.sendPassword}
                      onClick={() => onRecoverPasswordClick(item.id)}
                      disabled={isPasswordSent(item.id)}
                    >
                      Send Password Reset
                    </Button>
                  )}
                </>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </>
  );
};

LinkedCell.propTypes = {
  value: PropTypes.any.isRequired,
  link: PropTypes.string.isRequired,
};

DataTable.propTypes = {
  isPending: PropTypes.bool,
  sortField: PropTypes.string,
  customers: PropTypes.array,
  sortDirection: PropTypes.string,
  setSortField: PropTypes.func,
  setSortDirection: PropTypes.func,
  selectedCustomers: PropTypes.array.isRequired,
  recoverPasswordSent: PropTypes.array,
  isSelectable: PropTypes.bool,
  onChange: PropTypes.func,
  onArchiveClick: PropTypes.func,
  onRestoreClick: PropTypes.func,
  onSelectAll: PropTypes.func,
  onRecoverPasswordClick: PropTypes.func,
  isSelectedAll: PropTypes.bool,
};

export default memo(DataTable);
