import React, { memo } from 'react';
import PropTypes from 'prop-types';
import styles from './RecoverPasswordPopup.scss';
import { Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import PopupBase from '../PopupBase';

const RecoverPasswordPopup = ({ onClose, loading }) => {
  return (
    <PopupBase className={styles.popup} onClose={onClose}>
      {loading ? (
        <Spinner className={styles.spinner} variant="primary" />
      ) : (
        <>
          <h3 className={styles.text}>
            Password recovery link is sent to your email
          </h3>
          <Link to="/login" className="font-weight-medium text-primary">
            Back to Login page
          </Link>
        </>
      )}
    </PopupBase>
  );
};

RecoverPasswordPopup.propTypes = {
  onClose: PropTypes.func,
  loading: PropTypes.bool,
};

export default memo(RecoverPasswordPopup);
