import React, { useEffect, useState, useCallback } from 'react';

import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col, Table } from 'reactstrap';

import styles from './TransfersSummary.scss';
import PaginationTable from 'components/PaginationTable';
import Breadcrumbs from 'components/Breadcrumbs';
import PageWrapper from 'components/PageWrapper';

import useQuery from 'hooks/useQuery';
import useAction from 'hooks/useAction';
import { actions as transfersActions } from 'models/transfers/slice';
import { actions as reportsActions } from 'models/reports/slice';
import useSelector from 'hooks/useSelector';
import {
  inProgressTransfersSelector,
  completedTransfersSelector,
  isPendingSelector as transfersIsPendingSelector,
  paginationSelector,
} from 'models/transfers/selectors';
import { isPendingSelector as downloadIsPendingSelector } from 'models/reports/selectors';

import DataTable from './DataTable';
import ConfirmationPopup from 'components/Popup/ConfirmationPopup';
import Filter from './Filter';

const TransfersSummary = ({ title }) => {
  const fetchInProgressTransfers = useAction(
    transfersActions.fetchInProgressTransfers
  );
  const fetchCompletedTransfers = useAction(
    transfersActions.fetchCompletedTransfers
  );
  const deleteTransfer = useAction(transfersActions.deleteTransfer);
  const fetchXlsxTransfers = useAction(reportsActions.fetchXlsxTransfers);
  const inProgressTransfers = useSelector(inProgressTransfersSelector);
  const completedTransfers = useSelector(completedTransfersSelector);
  const transfersIsPending = useSelector(transfersIsPendingSelector);
  const downloadIsPending = useSelector(downloadIsPendingSelector);
  const pagination = useSelector(paginationSelector);

  const query = useQuery();

  const [currentPage, setCurrentPage] = useState(
    query.get('page') || pagination?.current_page
  );
  const [transferForDelete, setTransferForDelete] = useState(null);
  const [sortFieldCompleted, setSortFieldCompleted] = useState(
    query.get('sort_field') || 'created_at'
  );
  const [sortDirectionCompleted, setSortDirectionCompleted] = useState(
    query.get('sort_direction') || 'desc'
  );
  const [sortFieldInProgress, setSortFieldInProgress] = useState(
    query.get('sort_field') || 'created_at'
  );
  const [sortDirectionInProgress, setSortDirectionInProgress] = useState(
    query.get('sort_direction') || 'desc'
  );
  const [confirmModal, setConfirmModal] = useState(false);

  const [filter, setFilter] = useState({});

  useEffect(() => {
    fetchCompletedTransfers({
      page: currentPage,
      sort_field: sortFieldCompleted,
      sort_direction: sortDirectionCompleted,
      statuses: 'COMPLETED',
      ...filter,
    });
  }, [
    fetchCompletedTransfers,
    currentPage,
    sortFieldCompleted,
    sortDirectionCompleted,
    filter,
  ]);

  useEffect(() => {
    fetchInProgressTransfers({
      sort_field: sortFieldInProgress,
      sort_direction: sortDirectionInProgress,
      statuses: 'DISPUTED,PENDING_RECEIPT',
      ...filter,
      per_page: 999,
    });
  }, [
    fetchInProgressTransfers,
    currentPage,
    filter,
    sortFieldInProgress,
    sortDirectionInProgress,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filter]);

  const handleDeleteTransfer = () => {
    deleteTransfer(transferForDelete);
  };

  const onExport = useCallback(() => {
    fetchXlsxTransfers(filter);
  }, [fetchXlsxTransfers, filter]);

  return (
    <PageWrapper title={title}>
      <Breadcrumbs title={title} />
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Filter
                filter={filter}
                setFilter={setFilter}
                onExport={onExport}
                isPending={downloadIsPending || transfersIsPending}
              />
              {inProgressTransfers.length > 0 && (
                <>
                  <div className={styles.subHeading}>In Progress</div>
                  <Table bordered responsive>
                    <DataTable
                      transfers={inProgressTransfers}
                      isPending={transfersIsPending}
                      sortField={sortFieldInProgress}
                      setSortField={setSortFieldInProgress}
                      sortDirection={sortDirectionInProgress}
                      setSortDirection={setSortDirectionInProgress}
                    />
                  </Table>
                </>
              )}
              {(filter?.type === 'WarehouseToWarehouseTransfer' ||
                filter?.type === '') && (
                <div className={styles.subHeading}>Completed</div>
              )}
              <PaginationTable
                setCurrentPage={setCurrentPage}
                pagination={pagination}
              >
                <DataTable
                  isPending={transfersIsPending}
                  setTransferForDelete={setTransferForDelete}
                  setIsOpenConfirmationModal={setConfirmModal}
                  transfers={completedTransfers}
                  sortField={sortFieldCompleted}
                  setSortField={setSortFieldCompleted}
                  sortDirection={sortDirectionCompleted}
                  setSortDirection={setSortDirectionCompleted}
                />
                {confirmModal && (
                  <ConfirmationPopup
                    active={confirmModal}
                    setActive={setConfirmModal}
                    onSaveClick={handleDeleteTransfer}
                  />
                )}
              </PaginationTable>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageWrapper>
  );
};

TransfersSummary.propTypes = {
  title: PropTypes.string,
};

export default TransfersSummary;
